import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../Contexts/userContext';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";
import { request } from '../../Services/networkService';
import LoadingButton from '../../Components/LoadingButton';

export default function Friends({friends, hideInvite}){
  const history = useHistory();
  const {t} = useTranslation('common');
  const { user, getAvatar } = useUserContext();
  const { handleError } = usePopupContext();
  const [fr, setFr] = useState(friends || []);

  useEffect(() => {
    if (!friends) {
      user._id && request('user.groups')
      .then(data => {
        setFr(data.filter(o => o.GroupType === 'Register' && o.RelatedTo && o.RelatedTo._id !== user._id).map(o => ({
          text: o.Name,
          image: getAvatar(o.RelatedTo.ProfileImage || null),
          link: '/usertimeline/' + o.RelatedTo._id
        })));
      })
      .catch(handleError)    
    } else {
      setFr(friends);
    }
  }, [user._id, friends]);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-users" /> 
          {t('groups.friends')}
          {
            !hideInvite && <LoadingButton title={t('groups.inviteFriends')} link="/groups/invite-friends" className="pull-right" style={{marginTop: "-10px"}} />
          }
        </h5>



            <div className="photos-container small avatars">
              {
                (!fr || !fr.length) &&
                <center>
                  <div className="photos-empty"> {t('groups.noFriendsDisplay')} </div>
                </center>
              }

              {
                fr && fr.map((p,i) => {
                  return (
                    <div className="photos-holder" key={i}>
                      <div
                        className="photos-item"
                        style={{backgroundImage: `url("${p.image}")`}}
                        onClick={()=> p.link && history.push({pathname: p.link})}
                        title={p.text}
                      />
                      <div>{p.text}</div>
                    </div>
                  )
                })
              }
            </div>

            <p>&nbsp;</p>

      </div>
    </div>
  );
}
