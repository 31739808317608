import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Welcome from '../../Components/Welcome';
import { request } from '../../Services/networkService';
import { useHistory, Redirect } from 'react-router-dom';
import { usePopupContext } from '../../Contexts/popupContext';
import { useUserContext } from '../../Contexts/userContext';

export default function Verify() {
  const history = useHistory();
  const { handleError } = usePopupContext();
  const { user } = useUserContext();
  const params = new URLSearchParams(useLocation().search);
  const randomToken = params.get('id');

  useEffect(() => {
    if (randomToken) {
      request('user.verify', {id: randomToken})
      .then(() => {
        history.push({pathname: '/timeline'});
      })
      .catch(handleError);
    }
  }, [randomToken]);

  return(
    user.token ?
    <Redirect to={'/timeline'} />  :
    <Welcome>
      <div className="log-reg-area sign">
        <h2 className="log-title">{randomToken ? 'Verifying ...' : 'Missing token!'}</h2>
      </div>
    </Welcome>
  );
}
