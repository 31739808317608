import React, { useEffect } from 'react';
import Layout from '../../Components/Layout';
import { useParams } from 'react-router-dom';
import { useRequest } from '../../Services/networkService';

import PostedAds from './posted';
import HiddenAds from './hidden';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Advertise() {
  const {data, handleRequest} = useRequest();
  const { id } = useParams();
  const refresh = () => id && handleRequest('page.get', {id: id});

  useEffect(() => {
    id && refresh();
  }, [id])

  const menuConfig = [
    {
      title: <Translate>Posted Ads</Translate>,
      contents: PostedAds,
      iconCls: 'ti-agenda',
      path: '',
      params: {pageId: id, userPage: data}
    },
    {
      title: <Translate>Hidden ads</Translate>,
      contents: HiddenAds,
      iconCls: 'ti-eye',
      path: 'hiddenAds',
      params: {pageId: id, userPage: data}
    }
  ];

  return(
    <Layout userPage={data} config={menuConfig} title={<Translate>Advertise</Translate>} />
  );
};
