import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useTranslation } from "react-i18next";
import Welcome from '../../Components/Welcome';
import TextField from '../../Components/Form/TextField';
import { required } from '../../Util/form';
import { passwordReset } from '../../Services/userService';
import { useHistory } from 'react-router-dom';
import { usePopupContext } from '../../Contexts/popupContext';
import LoadingButton from '../../Components/LoadingButton';

export default function ForgotPassword() {
  const history = useHistory();
  const {t} = useTranslation('common');
  const { popupMessageOpen, handleError } = usePopupContext();

  const onSubmit = data => {
    return passwordReset(data)
      .then(() => popupMessageOpen(t('passwordforgot.success'), t('passwordforgot.mail-sent')))
      .then(() => history.push({pathname: '/'}))
      .catch(handleError);
  };

  return(
    <Welcome>
      <div className="log-reg-area sign forgot">
        <h2 className="log-title">{t('passwordforgot.forgot-pass')}</h2>
        <p>
          <b>
            {t('passwordforgot.not-member')} <Link to="/Register" >{'  '} <span>{t('passwordforgot.join')}</span></Link><br />
            {t('passwordforgot.free')}
          </b>
        </p>

        <Form
          onSubmit={onSubmit}
          initialValues={{remember: true}}
          render={({ handleSubmit, submitError, submitErrors, submitting}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field component={TextField}
                       name="Email"
                       label={t('passwordforgot.enter-email')}
                       type="email"
                       validate={required}
                       required
                />
                <div className="submit-btns text-center">
                  <LoadingButton loading={submitting} />
                  <LoadingButton title={t('login.LOGIN')} link="/" className="btn-ghost" />
                </div>
              </form>
          )}}
        />
      </div>
    </Welcome>
  );
}