import React, { useEffect, useState } from 'react';
import { usePopupContext } from '../../Contexts/popupContext';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import SkeletonFeature from './skeletonFeature';
import { request } from '../../Services/networkService';
import { Helmet } from 'react-helmet';


const FeatureContent = ({ featureURL }) => {
    const [data, setData] = useState();
    const { handleError } = usePopupContext();


    const modules = {
        toolbar: false
    }

    const listFeature = () => {
        request('public.listFeatureURL', {url: featureURL})
        .then(res => setData(res))
        .catch((err) => handleError(err))
    }

    useEffect(() => {
        if(featureURL) listFeature();
    }, [featureURL])

    return (
        <>
        {
            typeof(data) !== 'undefined' && Object.keys(data).length ?
                <div className='content-container'>
                    <Helmet>
                        <meta name="keywords" content={data.meta.keywords} />
                        <meta name="description" content={data.meta.description} />
                        <title>{data.meta && data.meta.title ? data.meta.title : data.title}</title>
                    </Helmet>
                    <ReactQuill 
                    theme="snow"
                    value={data.text}
                    readOnly
                    modules={modules}
                    />
                </div>
            
            :
            <SkeletonFeature />
        }
        </>
    );
}

export default FeatureContent;
