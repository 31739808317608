import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import { useUserContext } from '../../Contexts/userContext';
import { userIsAdmin } from '../../Util/common';

const PrivateRoute = ({ component: Component, admin, ...rest }) => {
  const { user } = useUserContext();
  const isLoggedIn = !!user.token;

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn && (admin ? userIsAdmin(user) : true) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
};

export default PrivateRoute