import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";

export default function PageInfo({data}) {
  const {t} = useTranslation('common');

  return(
    data && <Fragment>
      {data.Services && <li>
        <b>{t('pageinfo.services')} </b><br/>
        {data.Services.split('\n').map((s, i) => <Fragment key={i}><span>{s}</span><br/></Fragment>)}
      </li>}
      {data.Address && <li>
        <b>{t('pageinfo.address')} </b><br/>
        <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(data.Address)}`} target="new">{data.Address}</a> <br/>
        <iframe frameBorder="0" marginHeight="0" marginWidth="0" src={`https://maps.google.com/maps?hl=en&q=${encodeURI(data.Address)}&ie=UTF8&z=15&output=embed`} title={data.Address}></iframe>
      </li>}
      {data.OpenFrom && <li>
        <b>{t('pageinfo.work-hours')} </b><br/>
        {data.OpenFrom} - {data.OpenTo}
      </li>}
      {data.Phone && <li>
        <b>{t('pageinfo.phone')} </b><br/>
        {data.Phone} <br/>
      </li>}
      {data.Email && <li>
        <b>{t('pageinfo.email')} </b><br/>
        <a href={`mailto:${data.Email}`} target="new">{data.Email}</a> <br/>
      </li>}
      {data.Website && <li>
        <b>{t('pageinfo.site')} </b><br/>
        <a href={data.Website} target="new">{data.Website}</a> <br/>
      </li>}
      {data.Linkedin && <li>
        <b>{t('pageinfo.linkedin')} </b><br/>
        <a href={data.Linkedin} target="new">{data.Linkedin}</a> <br/>
      </li>}
      {data.Facebook && <li>
        <b>{t('pageinfo.fb')} </b><br/>
        <a href={data.Facebook} target="new">{data.Facebook}</a> <br/>
      </li>}
      {data.Twitter && <li>
        <b>{t('pageinfo.twitter')} </b><br/>
        <a href={data.Twitter} target="new">{data.Twitter}</a> <br/>
      </li>}
      {data.Instagram && <li>
        <b>{t('pageinfo.ig')} </b><br/>
        <a href={data.Instagram} target="new">{data.Instagram}</a> <br/>
      </li>}
    </Fragment>
  );
}
