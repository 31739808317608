import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../Components/Layout';
import { useUserContext } from '../../Contexts/userContext';
import { request } from '../../Services/networkService';
import Posts from '../../Components/Posts';
import { useTranslation } from "react-i18next";
import { usePopupContext } from '../../Contexts/popupContext';
import UsersList from '../../Components/Widgets/usersList';

export default function UserTimeline() {
  const {t} = useTranslation('common');
  const { id } = useParams();
  const { targetUser, setTargetUser } = useUserContext();
  const [refreshPosts, setRefreshPosts] = useState();
  const [friends, setFriends] = useState([]);
  const { handleError } = usePopupContext();

  const refreshUser = () => id && request('user.get', {id: id}).then(setTargetUser);

  const targetGroup = targetUser && targetUser.OwnGroup;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(()=>{
    refreshUser();
  }, [id]);

  useEffect(() => {
    targetGroup && request('user.topFriends', {id: targetGroup})
      .then(setFriends)
      .catch(handleError)
  }, [targetGroup]);

  const pageParams = {
    setRefreshPosts: setRefreshPosts,
    refreshUser,
    groupId: targetUser && targetUser.OwnGroup
  };

  const menuConfig = [
    {
      title: t('userTimeline.allPosts'),
      contents: Posts,
      iconCls: 'fa fa-list-alt',
      path: '',
      params: pageParams
    }
  ];

  const extraPanelsConfig = friends ? [...(friends[0]?.length ? [{
    title: 'Top friends',
    contents: (<UsersList list={friends[0]} />)
  }]: []),
  ...(friends[1]?.length ? [{
    title: 'Top groups',
    contents: (<UsersList list={friends[1]} />)
  }]: [])] : null;

  return(
    <Layout asUser={targetUser} extraPanels={extraPanelsConfig} refreshPosts={refreshPosts} refresh={refreshUser} config={menuConfig} title={t('userTimeline.userTimelineSidebar')} />
  );
};
