import React, { useState, useEffect, useRef } from 'react';
import { usePopupContext } from '../../Contexts/popupContext';
import ReactTable from '../../Components/ReactTablePagination';
import { formatDate, userIsAdmin, getImageUrl, jsonToFormData } from '../../Util/common';
import NewPost from '../../Components/PostBox/newPost';
import { request } from '../../Services/networkService';
import { Field } from 'react-final-form';
import TextArea from '../../Components/Form/TextArea';
import TextField from '../../Components/Form/TextField';
import FileField from '../../Components/Form/FileField';
import CheckBox from '../../Components/Form/CheckBox';
import Image from '../../Components/SideBarRight/image';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../Contexts/userContext';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function PostedAds({pageId, userPage}){
  const tableRef = useRef();
  const { handleError, popupConfirmOpen, popupFormOpen } = usePopupContext();
  const [pages, setPages] = useState();
  const [selectedPage, setSelectedPage] = useState('');
  const history = useHistory();
  const { user } = useUserContext();

  const id = userPage && userPage._id;

  useEffect(() => {
    id && setSelectedPage(id);
  }, [id])

  useEffect(() => {
    request('page.list')
      .then(setPages)
      .catch(handleError)
  }, []);

  const columns = [{
    Header: "Id",
    accessor: '_id',
  },{
    Header: <Translate>Text</Translate>,
    accessor: 'text',
    Cell: ({value}) => value.length > 11 ? <div dangerouslySetInnerHTML={{__html: value}}></div> : <div>---</div>
  },{
    Header: <Translate>Posted As</Translate>,
    accessor: 'postedAs.Name'
  },{
    Header: <Translate>Type</Translate>,
    accessor: 'sale.adType',
    width: 80
  },{
    Header: <Translate>Posted On</Translate>,
    accessor: 'date',
    Cell: ({value}) => formatDate(value),
    width: 100
  },{
    Header: <Translate>Expires On</Translate>,
    accessor: 'sale.expiry',
    Cell: ({value}) => formatDate(value),
    width: 110
  },{
    Header: <Translate>Auto Renew</Translate>,
    accessor: 'sale.autoRenew',
    Cell: ({value}) => value ? 'Yes' : 'No',
    width: 100
  }];

  const changeStatus = post => {
    return request('group.post.update', {_id: post._id, disabled: post.disabled === true ? false : true})
      .catch(handleError)
  };

  const save = ({file, ...post}) => {
    let data;
    if(post.link) {
      data = jsonToFormData(post);
    } else {
      post.link = null;
      data = jsonToFormData(post);
    }
    file && data.append('file', file[0]);
    !post.text && data.append('text', '');
    return request('group.post.autoRenew', {_id: post.sale._id, autoRenew: post.sale.autoRenew})
      .then(() => userIsAdmin(user) && request('group.post.infinite', {_id: post.sale._id, infinite: post.sale.infinite, type: 'sale'}))
      .then(() => request('group.post.update', data))
      .catch(handleError);
  };

  const modules = {
    toolbar: [
      [{ 'font': [] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

      [{ 'align': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ],
  }

  const adFormPopup = (editMode, o) => {

    const ad = {
      ...o,
      uploadType: o && o.uploads && !!o.uploads.length && o.uploads[0].uploadType,
      url: o && o.uploads && !!o.uploads.length && getImageUrl(o.uploads[0].url)
    };

    return (
      <div className="form-row">

        <Field component={TextField}
          name="link"
          label={<Translate>Link</Translate>}
        />

        {
          ad.url && <div className='form-group image-preview'>    
            <label>Attachment</label>
            <Image ad={ad} />
          </div>
        }


          <Field component={FileField}
           name="file"
           type="file"
           label={<Translate>Select file</Translate>}
           accept="image/*,.pdf"
          />

  
        <Field component={CheckBox}
           name="sale.autoRenew"
           label={<Translate>Auto Renew</Translate>}
           type="checkbox"
        />
        &nbsp;
        {
          userIsAdmin(user) && 
          <Field component={CheckBox}
             name="sale.infinite"
             label={<Translate>Does not expire</Translate>}
             type="checkbox"
          />
        }
      </div>
    )
  };

  function countClicks(data) {
    return Object.keys(data).length;
  }

  const adStatsForm = (ad, row) => {
    let clicks = 0;

    switch (row.sale.adType) {
      case 'Feed':
        clicks = countClicks(ad.clicks.timeline)
        break;
      case 'Sidebar':
        clicks = countClicks(ad.clicks.sidebar)
        break;
      default:
        break;
    }

    return (
      <>
        {
          row.sale.adType === 'Feed' ?
          <>
            <div className='form-row'>
              <Translate>Your ad was shown</Translate>&nbsp;<b>{ad.times.timeline}</b>&nbsp;<Translate>times in the timeline.</Translate>
            </div>
          </>
          :
          <>
            <div className='form-row'>
              <Translate>Your ad was shown</Translate>&nbsp;<b>{ad.times.sidebar}</b>&nbsp;<Translate>times in the sidebar panel.</Translate>
            </div>
          </>
        }
        {
          clicks && clicks > 0 ?
          <div className='form-row'>
            <b>{clicks}</b>&nbsp;{clicks === 1 ? <Translate>user</Translate> : <Translate>users</Translate>} <Translate>clicked on your ad.</Translate>
          </div>
          :null
        }
      </>
    )
  }

  const openAdStats = (ad) => {
    request('ad.tracking.listById', {id: ad._id})
    .then(result => {
      popupFormOpen('Ad Statistics', adStatsForm(result, ad), {initialValues: result})
    })
  }

  const actions = [{
    iconcls: 'ti-bar-chart',
    onClick: (row, {viewIndex}) => openAdStats(row),
    title: "Ad Statistics"
  },{
    iconcls: row => row.disabled ? 'fa fa-square-o' : 'fa fa-check-square-o',
    onClick: (row, {viewIndex}) => changeStatus(row).then(res => tableRef.current.updateSingleRow(viewIndex, res)),
    title: "Enable/Disable Ad"
  },{
    iconcls: 'fa fa-pencil-square-o',
    onClick: (row, {viewIndex}) => popupFormOpen('Edit Ad', adFormPopup(true, row), {initialValues: row, modules})
      .then(save)
      .then(res => tableRef.current.updateSingleRow(viewIndex, res)),
    title: 'Edit Ad Text'
  },{
    iconcls: 'fa fa-trash-o',
    onClick: row => popupConfirmOpen('Confirm', 'Are you sure you want to delete this Ad?')
      .then(() => {
        request('group.post.delete', {id: row._id})
        .then(request('ad.tracking.delete', {id: row._id}))
        .finally(tableRef.current.refresh)
      }),
    title: 'Delete Ad'
  }];

  const hasPages = pages && pages.length ? true : false;

  const page = selectedPage && pages && pages.find(p => p._id === selectedPage);

  const getLink = post => `/userpage/${post.postedAs.pageId}`;

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-users" /><Translate> Post an Ad</Translate></h5>

        {
         hasPages && <div className="pull-left selectPageCont" style={{position: 'relative', zIndex: 1}}>
            <label><b><Translate>To post an ad first select a page</Translate></b></label><br/>
            <select
              onChange={event => setSelectedPage(event.target.value)}
              value={selectedPage}
              className='advertising selectPage'
            >
              <option>---</option> 
              {pages && pages.map(({Name, id}) => <option key={id} value={id}>{Name}</option>)}
            </select>
            {/* <br/>&nbsp;<br/> */}
         </div>         
        }

        { !hasPages && <div><b><Translate>To post an ad you need to create a business page first</Translate></b></div>}

        { page && <NewPost apiId={page.OwnGroup} page={page} defaultAd refresh={tableRef.current.refresh} /> }
        
        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'user.listAds'}
          disableFilters
          onError={handleError}
          defaultSorted={[{
            id: 'date',
            desc: true
          }]}
          onRowClick={data => history.push({pathname: getLink(data)})}
        />

      </div>
    </div>
  );
}
