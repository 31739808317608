import React from 'react';
import { Form, Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import { composeValidators, required, minLength, validatePassword, samePassword } from '../../Util/form';
import { useTranslation } from "react-i18next";
import { usePopupContext } from '../../Contexts/popupContext';
import PasswordStrength from '../../Components/PasswordStrength';
import { changePass } from '../../Services/userService';
import LoadingButton from '../../Components/LoadingButton';

export default function PersonalInfo(){
  const { handleError, popupMessageOpen } = usePopupContext();
  const {t} = useTranslation('common');

  const onSubmit = (data, form) => {
    return changePass(data)
      .then(r => popupMessageOpen(t('profile.success'), t('profile.successPassChange')))
      .then(() => setTimeout(() => form.reset(), 0))
      .catch(handleError);
  };

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-lock" /> {t('profile.editPass')}</h5>             
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, pristine, form, values}) => {
              return (
                <form onSubmit={e => handleSubmit(e, form)}>
                      <Field component={TextField}
                             name="CurrentPassword"
                             label={t('profile.currentPass')}
                             type="password"
                             validate={required}
                             required
                      />
                      <Field component={TextField}
                             name="NewPassword"
                             label={t('profile.newPass')}
                             type="password"
                             validate={composeValidators(required, minLength(8), validatePassword)}
                             children={<PasswordStrength password={values.NewPassword} />}
                             required
                      />
                      <Field component={TextField}
                             name="Confirm_Password"
                             label={t('fields.confirm')}
                             type="password"
                             validate={(value, values) => samePassword(value, values.NewPassword) }
                             required
                      />
                  <div className="submit-btns text-center">
                    <LoadingButton iconCls="fa fa-save" loading={submitting} disabled={pristine} title={t('profile.updateBtn')} />
                  </div>
                </form>
            )}}
          />
      </div>
    </div>
  );
}