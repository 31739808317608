import React from 'react';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../Contexts/userContext';
import { getName } from '../../Util/common';

export default function UsersList({list}) {
  const history = useHistory();
  const { user, getAvatar } = useUserContext();

  const getLink = usr => {
    if (usr.GroupType) {
      if (usr.GroupType === 'Page') {
        return `/userpage/${usr.RelatedTo}`;
      } else { //Honoree
        return `/honoreetimeline/${usr.RelatedTo}`;
      }
    } else { //User
      return usr._id === user._id ? '/timeline' : `/usertimeline/${usr._id}`;
    }
  };

  return(
    <div className="photos-container small avatars sidebar-photos">
      {
        list && !!list.length && list.map((p,i) => {
          const name = getName(p.user);
          const link = getLink(p.user);
          const image = getAvatar(p.user.ProfileImage || null);
          const text = `${name} - ${p.total} posts`;

          return (
            <div className="photos-holder" key={i}>
              <div 
                className="photos-item" 
                style={{backgroundImage: `url("${image}")`}}
                onClick={()=> link && history.push({pathname: link})} 
                title={text}
              />
            </div>
          )
        })
      }
    </div>
  );
}