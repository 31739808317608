import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../Contexts/userContext';
import OutsideClick from '../../Components/OutsideClick';
import Notifications from './notifications';
import Languages from './languages';
import Search from './search';
import Help from './help';
import { useTranslation } from "react-i18next";

import logo from '../../assets/images/logo.png';
import logoM from '../../assets/images/logo-m.png';
import Login from '../../Pages/Login';
import LoadingButon from '../LoadingButton';
import { request } from '../../Services/networkService';
import { Translator, Translate } from 'react-auto-translate';
import { CONFIG } from '../../Config';
import { findLanguage } from '../../Util/common';

export default function TopBar() {
  const { userLoggedIn, getAvatar, logout, user } = useUserContext();
  const { t } = useTranslation('common');
  const [menuOpen, setMenuOpen] = useState(false);
  const [features, setFeatures] = useState([]);
  const [lng, setLng] = useState('en');

  const listFeatures = () => {
    request('public.listFeatures')
      .then(result => setFeatures(result))
  }

  useEffect(() => {
    listFeatures();
  }, [])

  useEffect(() => {
    let language = user.Language;
    if(language === 'enus') setLng('en');
    else setLng(findLanguage(language))
  }, [user.Language])

  return (
    <Fragment>
      <Translator
        from='en'
        to={lng}
        googleApiKey={CONFIG.GOOGLE_TRANSLATE_KEY}
      >
        <div className="responsive-header">
          <div className="mh-head first Sticky">
            <span className="mh-btns-left">
              <i className="fa fa-align-justify" onClick={() => setMenuOpen(!menuOpen)} />
            </span>
            <span className="mh-text">
              <Link to="/timeline"><img src={logo} alt={t('topbar.mem')} title={t('topbar.mem-title')} width={375} height={30}/></Link>
            </span>
            <span className="mh-btns-right">
              <Link to="/timeline" className="fa fa-home" style={{ fontSize: "24px" }}></Link>
            </span>
          </div>
          <div className="mh-head second">
            {userLoggedIn() && <Search />}
          </div>
          <OutsideClick onOutsideClick={() => setMenuOpen(false)}>
            <nav id="menu" className="res-menu" style={{ display: menuOpen ? 'block' : 'none' }}>
              {
                userLoggedIn() &&
                <ul>
                  <li><Link to="/timeline"><Translate>Home</Translate></Link></li>
                  <li><Link to="/profile">{t('topbar.profile')}</Link></li>
                  <li><Link to="/honorees">{t('topbar.honoree-profiles')}</Link></li>
                  <li><Link to="/groups">{t('topbar.groups')}</Link></li>
                  <li><Link to="/pages">{t('topbar.b-page')}</Link></li>
                  <li><Link to="/purchases">{t('header.purch')}</Link></li>
                  <li><Link to="/" onClick={logout}>{t('topbar.logout')}</Link></li>
                </ul>
              }
              {
                !userLoggedIn() &&
                <div>
                  <ul>
                    <li><Link to="/">{t('logout.loginLink')}</Link></li>
                    <li><Link to="/register">{t('logout.registerLink')}</Link></li>
                    <li><Link to="/blog"><Translate>Blog</Translate></Link></li>
                  </ul>
                  {
                    features && Object.keys(features).length ?
                      <ul className='main-menu'>
                        <li className="tour-profile">
                          <div className="submit-btns text-center">
                            <span><Translate>FEATURES</Translate></span>
                          </div>
                          <ul>
                            {
                              features.map(feature =>
                                feature && feature.URL ? <li key={feature.id}><Link to={'/features/' + feature.URL} title={feature.title}><i className="ti-file" /> {feature.title}</Link></li>
                                  : null
                              )
                            }
                          </ul>
                        </li>
                      </ul>
                      : null
                  }
                </div>
              }
            </nav>
          </OutsideClick>
        </div>

        <div className="topbar stick">
          <div className="container">
            <div className="logo">
              <Link to="/timeline"><img src={logoM} alt={t('topbar.mem')} title={t('topbar.mem-title')} width={50} height={40}/></Link>
            </div>
            {userLoggedIn() &&
              <Fragment>
                <Search />
                <Link to="/timeline" className="fa fa-home"></Link>
              </Fragment>
            }
            {
              !userLoggedIn() &&
              <Fragment>
                <Link to="/" className="fa fa-home"></Link>
                <div className='top-area notLoggedIn'>
                  {
                    features && Object.keys(features).length ?
                      <ul className='main-menu'>
                        <li className="tour-profile">
                          <div className="submit-btns text-center">
                            <LoadingButon title={<Translate>Features</Translate>} />
                          </div>
                          <ul>
                            {
                              features.map(feature =>
                                feature && feature.URL ? <li key={feature.id}><Link to={'/features/' + feature.URL} title={feature.title}><i className="ti-file" /> {feature.title}</Link></li>
                                  : null
                              )
                            }
                          </ul>
                        </li>
                      </ul>
                      : null
                  }
                  <div className='submit-btns text-center'>
                    <LoadingButon title={<Translate>Blog</Translate>} link='/blog' />
                  </div>
                </div>
              </Fragment>
            }
            <div className="top-area">
              <ul className="main-menu">
                {
                  !userLoggedIn() &&
                  <Login header={true} />
                }
                {
                  userLoggedIn() &&
                  <Fragment>
                    <li><Help /></li>
                    <li><Notifications /></li>
                  </Fragment>
                }
                <li><Languages /></li>
                {
                  userLoggedIn() &&
                  <li className="tour-profile">
                    <Link to="/profile" title="Here you provide information about yourself" className="user-img">
                      <div className="usericon user-avatar-image" style={{ backgroundImage: `url("${getAvatar()}")` }} />
                    </Link>
                    <ul>
                      <li><Link to="/profile" title={t('topbar.profile')}><i className="ti-user" /> <Translate>Personal Profile</Translate></Link></li>
                      <li><Link to="/honorees" title={t('topbar.honoree-profiles')}><i className="ti-agenda" /> <Translate>Honoree Profiles</Translate></Link></li>
                      <li><Link to="/groups" title={t('topbar.groups')}><i className="ti-comment-alt" /> <Translate>Groups</Translate></Link></li>
                      <li><Link to="/pages" title={t('topbar.b-page')}><i className="ti-briefcase" /> <Translate>Business Pages</Translate></Link></li>
                      <li><Link to="/purchases" title={t('header.finances')}><i className="ti-wallet" /> <Translate>Finances</Translate></Link></li>
                      <li><Link to="/" title={t('topbar.log-out')} onClick={logout}><i className="ti-power-off" /> <Translate>Logout</Translate></Link></li>
                    </ul>
                  </li>
                }
              </ul>
            </div>

          </div>
        </div>
      </Translator>
    </Fragment>
  );
}