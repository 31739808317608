import React from 'react';
import { UserProvider } from './userContext';
import { PopupProvider } from './popupContext';
import { GalleryProvider } from './galleryContext';

export default function MasterProvider({ children }) {
  return (
    <UserProvider>
      <PopupProvider>
        <GalleryProvider>
          {children}
        </GalleryProvider>
      </PopupProvider>
    </UserProvider>
  );
}