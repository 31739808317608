import React from 'react';
import { Translate } from 'react-auto-translate/lib/commonjs';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export default function Fundraising() {

  return (
    // <div className="memobjects">
    //   <div className="mo-add fund-start"><Link target="_blank" rel="noopener noreferrer" to={{ pathname: 'https://www.gofundme.com/create/fundraiser/category' }}><Translate>Start a</Translate> GoFundMe</Link></div>
    // </div>
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-hand-open" /> <Translate>Fundraising Options</Translate></h5>
          <div className='promosCnt'>
            <div className='promo help'>
            </div>
            <div className='promo'>
                <p><b><i className="ti-help-alt" />&nbsp;<Translate>Looking to raise financial assistance for the honoree memorial services?</Translate></b>.</p>
                <br/>
                <p>There are a number of fundraising platforms that can be useful (like <b>GoFundMe.com</b> or others of your choice).</p>
                <br/>
                <p>MEMKEEPERS <b>is NOT</b> affiliated with any fundraising platform nor are we promoting their services. Your activity on such platforms is at your own discretion.</p>
            </div>               
          </div>
      </div>
    </div>
  );

};