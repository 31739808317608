import React, { useState, useContext, useEffect } from 'react';
import * as StorageUtil from '../Util/storage';
import { getImageUrl } from '../Util/common';
import { USER_KEY } from '../Util/constants';
import { useTranslation } from "react-i18next";
import { request } from '../Services/networkService';
 
const UserContext = React.createContext();

export const useUserContext = () => useContext(UserContext);

export function UserProvider({ children }) {
  const {i18n} = useTranslation('common');
  const getUser = () => JSON.parse(StorageUtil.getItem(USER_KEY) || '{}');
  const setUser = user => StorageUtil.setItem(USER_KEY, JSON.stringify(user));

  const [user, setUserToState] = useState(getUser());
  const [targetUser, setTargetUser] = useState();
  const [products, setProducts] = useState([]);
  const userToken = user && user.token;

  const logout = () => {
    setUser({});
    setUserToState({});
    setProducts([]);
    StorageUtil.removeItem('TwoFAReminderClicked')
  };

  useEffect(() => {
    userToken && request('admin.products.list').then(({data}) => setProducts(data));
  }, [userToken]);

  useEffect(() => {
    i18n && user.Language && i18n.changeLanguage(user.Language);
  }, [user.Language, i18n]);

  const userLoggedIn = () => !!user.token;
  const getNames = () => user && `${user.First_Name} ${user.Last_Name}`;
  const getAvatar = (img = user.ProfileImage, isPage=false) => img ? getImageUrl(img) : (isPage ? `${process.env.PUBLIC_URL}/images/logo_placeholder.png` : `${process.env.PUBLIC_URL}/images/user_placeholder.jpg`);

  const getObjectAvatar = usr => {
    const getEmptyImage = () => {
      switch(usr._type) {
        case 'user':
          return `${process.env.PUBLIC_URL}/images/user_placeholder.jpg`;
        case 'page':
          return `${process.env.PUBLIC_URL}/images/logo_placeholder.png`;
        default:
          // honoree
          return `${process.env.PUBLIC_URL}/images/${usr.HonoreeType === 'Pet' ? "pet_placeholder.png" : "user_placeholder.jpg"}`;
      }
    }
    return usr.ProfileImage ? getImageUrl(usr.ProfileImage) : getEmptyImage();
  };

  const changeLanguage = usr => {
    setUser(usr);
    setUserToState(usr);
    usr.Language && i18n.changeLanguage(usr.Language);
  }; 

  const updateUser = data => {
    const updatedUser = Object.assign(user, data);
    return user.token ? request('user.update', updatedUser)
      .then(r => {
        const usr = {...user, ...r};
        changeLanguage(usr);
        return usr;
      }) : Promise.resolve(changeLanguage(updatedUser))
  };

  return (
    <UserContext.Provider value={{
      user,
      setUser: user => {
        setUser(user);
        setUserToState(user);
      },
      products,
      updateUser,
      logout,
      getAvatar,
      getObjectAvatar,
      getNames,
      userLoggedIn,
      targetUser,
      setTargetUser
    }}>
      {children}
    </UserContext.Provider>
  );
}