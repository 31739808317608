import React, { useEffect } from 'react';
import Layout from '../../Components/Layout';

import PrivacyPolicy from './PrivacyPolicy';
import CookiePolicy from './CookiePolicy';
import TermsandConditions from './TermsAndConditions';
import Disclaimer from './Disclaimer';
import RefundPolicy from './RefundPolicy';

import { useTranslation } from "react-i18next";

export default function Requests() {
  const {t} = useTranslation('quicklinks');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const menuConfig = [
    {
      title: t('policies.privacy'),
      contents: PrivacyPolicy,
      iconCls: 'fa fa-users',
      path: '',
      params: {}
    },{
      title: t('policies.cookies'),
      contents: CookiePolicy,
      iconCls: 'fa fa-users',
      path: 'cookies',
      params: {}
    },{
      title: t('policies.terms'),
      contents: TermsandConditions,
      iconCls: 'fa fa-users',
      path: 'terms',
      params: {}
    },{
      title: t('policies.discl'),
      contents: Disclaimer,
      iconCls: 'fa fa-users',
      path: 'disclaimer',
      params: {}
    },{
      title: t('policies.refund'),
      contents: RefundPolicy,
      iconCls: 'fa fa-users',
      path: 'refund',
      params: {}
    }
  ];

  return(
    <Layout showHeader={false} config={menuConfig} title={t('policies.policies')} />
  );
};
