import React from 'react';

export const generateTextDropdown = (options, sendId=false) => ({ filter, onChange }) => (
  <select
    onChange={event => onChange(event.target.value)}
    value={filter ? filter.value : ""}
  >
    <option/> 
    {
      Array.isArray(options) ?
        options.map(({name, description, id}) => <option key={id} value={sendId ? id : (name || description)}>{name || description}</option>) :
        Object.keys(options).map(key => <option key={key} value={key}>{options[key]}</option>)
    }
  </select>
);

export const filterCellRenderer = (arr, prop) => (
  ({ value }) => {
    const obj = arr.find(o => o.id === value);
    return obj ? <span title={obj[prop]}>{obj[prop]}</span> : ''
  }
);