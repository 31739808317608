import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../Components/Layout';
import { useUserContext } from '../../Contexts/userContext';
import { useRequest } from '../../Services/networkService';
import Posts from '../../Components/Posts';
import PageInfo from '../../Components/Widgets/pageInfo';
import { useTranslation } from "react-i18next";

import Members from '../HonoreeProfile/Members';
import Calendar from '../../Components/Calendar';

export default function HonoreeTimeline() {
  const { id } = useParams();
  const { user } = useUserContext();
  const {data, handleRequest} = useRequest();
  const {t} = useTranslation('common');

  const refresh = () => id && handleRequest('page.get', {id: id});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    refresh();
  }, [id])

  const pageParams = {
    groupId: data && data.OwnGroup,
    page: data
  };

  const menuConfig = [
    {
      title: t('pagetimeline.all-posts'),
      contents: Posts,
      iconCls: 'fa fa-connectdevelop',
      path: '',
      params: pageParams
    },{
      title: t('pagetimeline.my-posts'),
      contents: Posts,
      iconCls: 'fa fa-list-alt',
      path: 'myposts',
      params: {...pageParams, byUser: user._id}
    },{
      title: t('honoree.members'),
      contents: Members,
      iconCls: 'ti-calendar',
      path: 'members',
      params: pageParams
    },{
      title: 'Calendar',
      contents: Calendar,
      iconCls: 'ti-calendar',
      path: 'calendar',
      params: pageParams
    }
  ];

  const extraPanelsConfig = [{
    title: t('pagetimeline.page-info'),
    contents: <PageInfo data={data} />
  }];

  return(
    <Layout refresh={refresh} userPage={data} config={menuConfig} title={t('pagetimeline.page-info')} extraPanels={extraPanelsConfig} />
  );
};
