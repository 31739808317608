import React, { useEffect, useState } from 'react';
import { usePopupContext } from '../../Contexts/popupContext';
import { request } from '../../Services/networkService';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Promotions(){
  const { handleError } = usePopupContext();
  const [promos, setPromos] = useState([]);

  const listPromotions = () => {
    request('public.promotions', {extraParam: {template: false}})
    .then(({data}) => setPromos(data.filter(item => item.disabled !== true)))
    .catch(err => handleError(err))
  }

  useEffect(() => {
    listPromotions();
  }, [])

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-ticket" /> <Translate>Active Promotions</Translate></h5>
        {
            Object.keys(promos).length && 
            <div className='promosCnt'>
                <div className='promo help'>
                <i className="ti-help-alt" /><p><b><Translate>If a promotion you were looking for is not listed now, it means that it's no longer active</Translate></b>.</p>
                </div>
                {
                    promos.map((promo, i) => 
                        <div className='promo'>
                            <p><b><Translate>Name</Translate></b>: {promo.name}</p>
                            <p><b><Translate>Description</Translate></b>: <Translate>{promo.message}</Translate></p>
                        </div>
                    )
                }
            </div>
        }   

      </div>
    </div>
  );
}
