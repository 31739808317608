import React, { Fragment, useEffect } from 'react';
import { useRequest } from '../../Services/networkService';
import { useUserContext } from '../../Contexts/userContext';
import { getImageUrl } from '../../Util/common';
import Ad from './ad';
import { usePopupContext } from '../../Contexts/popupContext';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function SideBarRight() {
  const { data, handleRequest } = useRequest();
  const { user } = useUserContext();
  const { popupFeedbackOpen } = usePopupContext();
  const history = useHistory();

  const ads = data && data.map(o => ({
    ...o,
    uploadType: o && o.uploads && !!o.uploads.length && o.uploads[0].uploadType,
    url: o && o.uploads && !!o.uploads.length && getImageUrl(o.uploads[0].url)
  }));

  const refreshAds = (checkHidden) => ((checkHidden && !document.hidden) || !checkHidden) && user && user.token && handleRequest('user.getAds');

  const openFeedback = () => {
    popupFeedbackOpen();
  }

  useEffect(() => {
    refreshAds();
    const handle = setInterval(() => refreshAds(true), 30000);
    return () => clearInterval(handle);
  }, []);

  const adCount = 3;
  const adArr = ads && ads.length ? Array.apply(null, Array(adCount)) : [];

  return (
    <Fragment>
      <div className='feedBackButton promos' onClick={() => history.push('/promotions')}><i className='ti-ticket'/> <Translate>PROMOS</Translate></div>
      <div className='feedBackButton' onClick={() => openFeedback()}><i className='ti-comment-alt'/> <Translate>Feedback Survey</Translate></div>
      <div className='sidebarTitle'><Translate>Sponsored</Translate></div>
      {adArr.length > 0 && adArr.map((x, i) => ads[i]?.sale?.adType === 'Sidebar' || ads[0]?.sale?.adType === 'Sidebar' ? <Ad refresh={refreshAds} key={i} ad={ads[i] || ads[0]} /> : null)}
    </Fragment>
  );

}