import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../Components/Layout';
import { useUserContext } from '../../Contexts/userContext';
import { request } from '../../Services/networkService';
import Gallery from '../../Components/Gallery';

export default function HonoreePhotos() {
  const { id } = useParams();
  const [refreshMemobjects, setRefreshMemobjects] = useState();
  const { targetUser, setTargetUser } = useUserContext();
  const groupId = targetUser && targetUser.OwnGroup;
  const { user } = useUserContext();

  const refreshHonoree = () => id && request('honoree.get', {id: id}).then(setTargetUser);

  useEffect(()=>{
    refreshHonoree();
  }, [groupId, id]);

  return(
    <Layout refresh={refreshHonoree} asUser={targetUser} memobjects setRefreshMemobjects={setRefreshMemobjects} >
      <div className={'col-lg-12 central-meta-container'}>
        <div className="central-meta">
          <div className="editing-interest">
            <Gallery galleryType="common" user={targetUser} readOnly={targetUser && targetUser.Owner === user.id ? false : true} onlyImages refreshMemobjects={refreshMemobjects} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
