import React, { useEffect, useCallback, useRef } from 'react';

// a wrapper component to detect a mouse click outside the children
export default function OutsideClick({children, onOutsideClick, ...others}){
  const el = useRef();

  const isElementVisible = element => {
    return !!element.offsetParent;
  }

  const handleUserClick = useCallback((e) => {
    if (el && el.current && onOutsideClick && !el.current.contains(e.target) && isElementVisible(el.current)) {
      onOutsideClick();
    }
  }, [el, onOutsideClick]);

  useEffect(() => {
    document.addEventListener("mousedown", handleUserClick);
    return () => {
      document.removeEventListener("mousedown", handleUserClick);
    }
  }, [handleUserClick]);

  return (
    <div {...others} ref={el}>
      {children}
    </div>
  );
}