import React, { useRef } from 'react';

export default function FileUpload ({ input, label, meta, required, isInvalid, multiselect, ...others }) {
  const style = ((meta.error || meta.submitError) && meta.touched && !others.readOnly) ? { 'color': 'red' } : {};
  const ref = useRef(null);

  const text = () => {
    if (input.value) {
      if (input.value.length > 1) {
        return `${input.value.length} files selected`;
      }
      return input.value[0].name;
    }
    return 'Select file';
  }

  return (
    <>
      <label className="file-input-label" style={style}>{label} {required &&
        <span className="is-required">*</span>}
      </label>
      <div className="file-input-wrapper" >
        <div className="input-group">
          <div className="form-file-upload" onClick={() => ref.current.click()}>
            {text()}
          </div>
          <input
            ref={ref}
            onChange={e => {
              const arr = Array.from(e.target.files);
              input.onChange(arr);
              others.onLoad && others.onLoad(e);
            }}
            type="file"
            className="form-file-upload-hidden"
            multiple={multiselect ? true : false}
            {...others}
          />
        </div>
      </div>
    </>
  );
}
