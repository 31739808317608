import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import ReactTable from '../ReactTable';
import { debounce } from 'debounce';
import { useLocation } from 'react-router-dom';
import { request } from '../../Services/networkService';

const ReactTablePagination = forwardRef(function({api, apiParam, dataProp='data', totalProp='total', pagesProp='pages', onError, ...props}, ref){
  const [{ [dataProp]:elements, [pagesProp]:totalPages, [totalProp]:totalElements, ...extraData }, setGridData] = useState({elements: [], totalElements: 0, totalPages: 0 });
  const [loading, setLoading] = useState(false);
  // const [selectedRow, setSelectedRow] = useState(null);
  const [savedParams, setSavedParams] = useState();
  const tableRef = useRef();

  const params = new URLSearchParams(useLocation().search);

  const filter = {
    prop: params.get('filterProp'),
    value: params.get('filterValue')
  };

  useEffect(()=>{
    if (filter.prop && filter.value){
      tableRef.current.filterByProp(filter.prop, filter.value);
    }
  },[filter.prop, filter.value]);

  useImperativeHandle(ref, () => ({
    refresh(){
      tableRef.current.refresh();
    },
    selectRow(index){
      tableRef.current.selectRow(index);
    },
    filterBy(index, filter){
      tableRef.current.filterBy(index, filter);
    },
    filterByProp(prop, filter){
      tableRef.current.filterByProp(prop, filter);
    },
    updateSingleRow(index, row){
      if (totalElements > 0) {
        setGridData({[dataProp]: [...elements.slice(0, index), row, ...elements.slice(index+1)], [totalProp]: totalElements, [pagesProp]: totalPages });
        //tableRef.current.refreshRow(index);   
      }
    },
    updateSelectedRow(row){
      const idx = tableRef.current.getSelectedIndex();
      idx && setGridData({[dataProp]: [...elements.slice(0, idx), row, ...elements.slice(idx+1)], [totalProp]: totalElements, [pagesProp]: totalPages });
      tableRef.current.refreshSelectedRow();
    },
    getSelectedRow(){
      return elements && elements[tableRef.current.getSelectedIndex()];
    },
    getSearchParams(){
      return savedParams;
    },
    getRows() {
      return elements;
    }
  }));

  const onFetchData = (options) => {

    const sortColumn = props.columns.find(col => col.accessor === options.sorted[0].id);

    const defaultParams = {
      page_number: options.page,
      page_size: options.pageSize,
      sorting_direction: options.sorted[0].desc ? 'DESC':'ASC',
      sorting_parameter: sortColumn && sortColumn.filterAs ? sortColumn.filterAs : options.sorted[0].id
    };

    const filters = options.filtered.reduce((acc, { id, value }) => {
      const col = props.columns.find(col => col.accessor === id);
      const filterName = col && col.filterAs ? col.filterAs : id;
      return (
        col ? (typeof value === 'object' && value !== null && col.type === 'dateRange' ? 
                  { ...acc, [`f_from_${filterName}`]: value.dateFrom,  [`f_to_${filterName}`]: value.dateTo} :
                  { ...acc, [`${col.filterExact ? 'fe' : 'f'}_${filterName}`]: value }) : acc
      );
    }, {});

    const params = {...defaultParams, ...filters};
    setSavedParams(params);
    api && setLoading(true);
    api && request(api, {...params, extraParam: apiParam}).then((data) => {
      if (data) {
        setGridData(data);
        // if (props.onRowClick && selectedRow != null) {
        //   const rowIndex = data.elements.length > selectedRow ? selectedRow : 0;
        //   tableRef.current && tableRef.current.selectRow(rowIndex);
        // }
        tableRef.current && tableRef.current.scrollTop();
      }
    })
    .catch(e => onError && onError(e))
    .finally(() => setLoading(false));
  };

  const defaultSorted = props.defaultSorted || [{id: props.columns[0].accessor, desc: false}];

  return ( 
    <ReactTable
      ref={tableRef}
      {...props}
      manual
      loading={loading}
      totalRows={totalElements}
      data={elements || []}
      pages={totalPages}
      onFetchData={debounce(onFetchData, 500)}
      defaultSorted={defaultSorted}
      // selectRow={setSelectedRow}
      extraData={extraData}
    />
  );
});

export default ReactTablePagination; 