import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../Components/Layout';
import { useUserContext } from '../../Contexts/userContext';
import { request } from '../../Services/networkService';
import Posts from '../../Components/Posts';
import UsersList from '../../Components/Widgets/usersList';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";

import Members from '../HonoreeProfile/Members';
import TabPanel from '../../Components/TabPanel';
import MyMementos from '../PostedMementos';
import BlockedUsers from '../HonoreeProfile/BlockedUsers';
import { Translate } from 'react-auto-translate/lib/commonjs';
import Fundraising from '../../Components/Fundraising';
import Calendar from '../../Components/Calendar';

export default function HonoreeTimeline() {
  const { id } = useParams();
  const { user, targetUser, setTargetUser } = useUserContext();
  const { handleError } = usePopupContext();
  const {t} = useTranslation('common');
  const [refreshPosts, setRefreshPosts] = useState();
  const [refreshMemobjects, setRefreshMemobjects] = useState();

  const [mostActive, setMostActive] = useState();

  const refreshHonoree = () => id && request('honoree.get', {id: id}).then(setTargetUser);

  const targetGroup = targetUser && targetUser.OwnGroup;

  useEffect(() => {
    targetGroup && request('group.userActivity', {id: targetGroup})
      .then(setMostActive)
      .catch(handleError)
  }, [targetGroup]);

  useEffect(()=>{
    refreshHonoree();
  }, [id]);

  const pageParams = {
    user: targetUser,
    setRefreshPosts: setRefreshPosts,
    refreshMemobjects: refreshMemobjects,
    groupId: targetUser && targetUser.OwnGroup,
    ...(targetUser && targetUser.HonoreeType === 'Pet' && {postType: 'Pet'})
  };

  const mementosConfig = () => ([{
    text: 'Posted Mementos',
    iconCls: 'ti-files',
    content: <MyMementos key={0} />
  },{
    text: 'Posted Premium Mementos',
    iconCls: 'ti-files',
    content: <MyMementos key={1} mementoType="Premium" />
  }]);

  const Mementos = () => (
    <TabPanel tabs={mementosConfig()} />
  );

  const menuConfig = [
    {
      title: t('honoree.allPosts'),
      contents: Posts,
      iconCls: 'fa fa-connectdevelop',
      path: '',
      params: pageParams
    },{
      title: t('honoree.myPosts'),
      contents: Posts,
      iconCls: 'fa fa-list-alt',
      path: 'myposts',
      params: {...pageParams, byUser: user._id}
    },{
      title: <Translate>Group members</Translate>,
      contents: Members,
      iconCls: 'ti-calendar',
      path: 'members',
      params: pageParams
    },{
      title: <Translate>My mementos</Translate>,
      contents: Mementos,
      iconCls: 'ti-files',
      path: 'mymementos',
      params: {byUser: user._id}
    },{
      title: <Translate>Blocked Users</Translate>,
      contents: BlockedUsers,
      iconCls: 'ti-lock',
      path: 'blocked',
      params: pageParams
    },{
      title: <Translate>Fundraising Options</Translate>,
      contents: Fundraising,
      iconCls: 'ti-hand-open',
      path: 'fundraising',
      params: pageParams
    }, {
      title: <Translate>Calendar</Translate>,
      contents: Calendar,
      iconCls: 'ti-calendar',
      path: 'calendar',
      params: pageParams
    }
  ];

  const extraPanelsConfig = mostActive ? [...(mostActive.free.length ? [{
      title: t('honoree.mostActiveUsers'),
      tooltip: 'Displays group members who posted the most free mementos or contributed most timeline posts',
      contents: (<UsersList list={mostActive.free} />)
    }]: []),
    ...(mostActive.premium.length ? [{
      title: t('honoree.mostActivePremium'),
      tooltip: 'Displays group members who posted the most premium mementos',
      contents: (<UsersList list={mostActive.premium} />)
    }]: []),
    ...(mostActive.auto.length ? [{
      title: t('honoree.mostActiveAuto'),
      tooltip: 'Displays group members who posted the most auto-renewable premium mementos',
      contents: (<UsersList list={mostActive.auto} />)
    }]: [])] : null;

  return(
    <Layout 
      asUser={targetUser} 
      refreshPosts={refreshPosts} 
      refresh={refreshHonoree} 
      setRefreshMemobjects={setRefreshMemobjects} 
      memobjects 
      config={menuConfig} 
      title={t('honoree.honoreeTimeline')} 
      extraPanels={extraPanelsConfig} 
    />
  );
};
