import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import LoadingButton from '../../Components/LoadingButton';
import { useTranslation } from "react-i18next";
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Footer() {
  const {t} = useTranslation('common');
  const year = new Date().getFullYear()
  return(
    <Fragment>
      <footer id="site-footer">
        <div className="row">
          <div className="col-lg-8 col-md-6">
            <div className="widget">
              <div className="foot-logo">
                <div className="logo">
                  <Link to="/timeline"><img src={logo} alt="Memkeepers" width={275} height={30}/></Link>
                  <LoadingButton className="contactBtn" title={<Translate>Contact us</Translate>} link="/contact" />
                </div>
                <p>
                  <Translate>Preserving the Memory of Our Loved Ones</Translate>
                </p>
              </div>                  
            </div>
          </div>
          <div className="col-lg-4 col-md-3" style={{ textAlign: 'center' }}>
            <div className="widget">
              <div className='list-style'>
                <Link to="/about"><Translate>About us</Translate></Link>|
                <Link to="/contact"><Translate>Contact us</Translate></Link>|
                <Link to="/policies"><Translate>Policies</Translate></Link>|
                <Link to="/howitworks"><Translate>How it works</Translate></Link>
              </div>
            </div>
            <br/>
            <div className='social'>
                <a href="https://www.facebook.com/memkeepers" target="new"><i className="fa fa-facebook-square" /></a>
                <a href="https://www.instagram.com/memkeepers/" target="new"><i className="fa fa-instagram" /></a>
                <a href="https://twitter.com/memkeepers" target="new"><i className="fa fa-twitter-square" /></a>
                <a href="https://www.linkedin.com/in/memkeepers-social-platform-798382201" target="new"><i className="fa fa-linkedin-square" /></a>
            </div>
          </div>
        </div>
      </footer>
      <div className="bottombar">
        <div className="row">
          <div className="col-md-12">
            <p className="copyright">© MEMKEEPERS {year}. All rights reserved.</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
