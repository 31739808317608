import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import PostBox from '../PostBox';
import NewPost from '../PostBox/newPost';
import { request } from '../../Services/networkService';
import { useUserContext } from '../../Contexts/userContext';
import LoadingButton from '../../Components/LoadingButton';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";
import { Translate } from 'react-auto-translate/lib/commonjs';


export default function Posts({byUser, setRefreshPosts, refreshUser, groupId, postType, ...other}){
  const [{data, currentPage, pages, total}, setPosts] = useState({data: [], currentPage: 1, pages: 0, total: 0});
  const [loading, setLoading] = useState(false);
  const { user, targetUser } = useUserContext();
  const intersectionObserver = useRef();
  const { handleError } = usePopupContext();
  const targetGroup = groupId || (targetUser && targetUser.OwnGroup);
  const [groupStatus, setGroupStatus] = useState();
  const disablePosting = other.page && other.page.Settings && other.page.Settings.DisableUserPosting && other.page.Owner !== user._id;
  const {t} = useTranslation('common');
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    }
  }, []);

  const refreshPosts = useCallback(() => { 
    targetGroup && request(`group.amIPartOf`, {groupId: targetGroup}).then(r => componentIsMounted.current && setGroupStatus(r))
    groupId && setLoading(true);
    !!groupId && request(`group.posts`, {id: groupId, byUser: byUser, postType: postType, page_number: 0, page_size: 5})
      .then(r => {
        componentIsMounted.current && setPosts(r);
        window.scrollTo(0, 0);
      })
      .finally(() => componentIsMounted.current && setLoading(false));
  }, [targetGroup, groupId, byUser, postType]);

  useEffect(() => {
    if (setRefreshPosts) {
      setRefreshPosts(() => refreshPosts);
    }
  }, [refreshPosts, setRefreshPosts]);

  const loadMorePosts = useCallback(() => {
    if (currentPage < pages) {
      setLoading(true);
      request(`group.posts`, {id: groupId, byUser: byUser, postType: postType, page_number: currentPage, page_size: 5})
        .then(res => componentIsMounted.current && setPosts({data: [...data, ...res.data], currentPage: res.currentPage, pages: res.pages, total: res.total}))
        .finally(() => componentIsMounted.current && setLoading(false));
    }
  }, [groupId, byUser, currentPage, data, pages, postType]);

  const refreshPost = post => {
    const postIndex = data.findIndex(o => o._id === post._id);
    setPosts({data: [...data.slice(0, postIndex), post, ...data.slice(postIndex+1)], currentPage, pages, total});
  };

  useEffect(()=>{
    refreshPosts();
  }, [groupId, refreshPosts]);

  useEffect(()=>{
    intersectionObserver.current = new IntersectionObserver(entries => {
      entries[0].intersectionRatio > 0 && currentPage < pages && loadMorePosts();
    });
    intersectionObserver.current.observe(document.querySelector('#site-footer'));
    return () => {
      intersectionObserver.current.unobserve(document.querySelector('#site-footer'));
      intersectionObserver.current = null;
    }
  }, [currentPage, pages, loadMorePosts]);

  const sendGroupRequest = () => {
    return request('group.request', {groupId: targetGroup})
      .then(status => {
        setGroupStatus(status);
        refreshUser && refreshUser();
      })
      .catch(handleError);
  };

  const updatePost = post => {
    const idx = data.findIndex(o => o._id === post._id);
    setPosts({data: [...data.slice(0, idx), post, ...data.slice(idx+1)], currentPage, pages, total});
  }

  return (
    <Fragment>
      {
        groupStatus !== undefined && (
          groupStatus === true && !disablePosting ?
          <NewPost apiId={groupId} refresh={refreshPosts} postType={postType} {...other} />
          :
          <div className="central-meta item">
            <div className="new-postbox">
              <div className="newpst-input posting-disabled">
                {
                  disablePosting && groupStatus !== false &&
                  // <div>{t('posts.disabled-group')}</div>
                  <div><Translate>Posting has been disabled for this group</Translate></div>
                }
                {
                  groupStatus === 'Requested' && !disablePosting &&
                  // <div>{t(targetUser?._type ==="user" ? 'posts.req-friends' : 'posts.req-join-group')}</div>
                  <div>{targetUser?._type === 'user' ? <Translate>You have already sent a request to become friends</Translate> : <Translate>You have already sent a request to join this group</Translate>}</div>
                }
                {
                  (groupStatus === 'Denied' || groupStatus === 'Blacklisted') && !disablePosting &&
                  // <div>{t('posts.req-denied')}</div>
                  <div><Translate>You are not allowed to post, because the owner of this group denied your request to join.</Translate></div>
                }
                {
                  groupStatus === false &&
                  <Fragment>
                    {/* <div>{t('posts.not-allowed')}</div> */}
                    <div><Translate>You are not allowed to post, because you are not part of this group.</Translate></div>
                    {/* <LoadingButton title={t(targetUser?._type ==="user" ? 'header.become-friends' : 'posts.group-join')} onClick={sendGroupRequest} /> */}
                    <LoadingButton title={targetUser?._type ==="user" ? <Translate>Become friends</Translate> : <Translate>Join Group</Translate>} onClick={sendGroupRequest} />
                  </Fragment>
                }
              </div>
            </div>
          </div>
        )
      }

      {
        !data.length && !loading &&
          <center>
            <h6 style={{margin: "20px 0 40px"}}><b><Translate>No Posts</Translate></b></h6>
          </center>
      }

      {
        !!data.length &&
          data.map((postData, index) => (
            <PostBox
              key={index}
              postData={postData}
              apiId={groupId}
              refresh={refreshPosts}
              refreshPost={refreshPost}
              updatePost={updatePost}
              {...other}
            />
          ))
      }

      {
        loading &&
          <center>
            <i className="loading" />
          </center>
      }

    </Fragment>
  );
}
