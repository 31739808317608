import React, { Fragment, useState, useEffect } from 'react';
import { usePopupContext } from '../../Contexts/popupContext';
import { useUserContext } from '../../Contexts/userContext';
import { useTranslation } from "react-i18next";
import { Button, Modal } from 'react-bootstrap';
import PostBox from '../../Components/PostBox';
import Ad from '../../Components/SideBarRight/ad';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function PostPreview({imgSrc, imgType, postText, postType}) {
  const { popupSubmit, popupClose } = usePopupContext();
  const {t} = useTranslation('common');
  const { user, products } = useUserContext();
  const timeline = products.find(o => o.name === 'Timeline Ad');
  const sidebar = products.find(o => o.name === 'Sidebar Ad');
  const [previewType, setPreviewType] = useState();
  const [description, setDescription] = useState('');

  const uploadType = imgType && imgType.split('/').shift();

  const previewData = {
     text: postText,
     postType,
     comments: [],
     reactions: [],
     postedBy: user,
     uploads: imgSrc ? [{url: imgSrc, uploadType: uploadType === 'application' ? 'pdf' : uploadType, wholeUrl: true}] : []
  };

  const isTimeline = previewType === timeline.id;

  const data = isTimeline ? previewData : {
    ...previewData,
    uploadType: previewData && previewData.uploads && !!previewData.uploads.length && previewData.uploads[0].uploadType,
    url: previewData && previewData.uploads && !!previewData.uploads.length && previewData.uploads[0].url
  };

  useEffect(() => {
    if (!previewType) {
      setPreviewType(timeline.id);
    }
    setDescription(products ? products.find(p => p.id === previewType)?.description : '');
  }, [products, previewType, timeline.id]);

  return (
    <Fragment>
      <Modal.Body className="text-center">

        <div>
          <label><b><Translate> Select ad type</Translate></b></label><br/>
          <select
            onChange={e => setPreviewType(e.target.value)}
            value={previewType}
          >
            <option key={timeline.id} value={timeline.id}>Timeline Ad</option>
            <option key={sidebar.id} value={sidebar.id}>Sidebar Ad</option>
          </select>
        </div><br/>

        {isTimeline && <PostBox postData={previewData} noDelete noComment />}
        {!isTimeline && <Ad ad={data} />}
      </Modal.Body>
      <Modal.Footer>
        {description}
        <Button onClick={() => popupSubmit()}>{t('popupcontext.k')}</Button>
        <Button onClick={popupClose}>{t('popupcontext.cancel')}</Button>
      </Modal.Footer>
    </Fragment>
  )
};