import React, { useRef } from 'react';
import ReactTable from '../../Components/ReactTablePagination';
import { usePopupContext } from '../../Contexts/popupContext';
import { Field } from 'react-final-form';
import { required } from '../../Util/form';
import { request } from '../../Services/networkService';
import { generateTextDropdown } from '../../Components/ReactTable/Filters';
import { useUserContext } from '../../Contexts/userContext';
import TextField from '../../Components/Form/TextField';
import { useTranslation } from "react-i18next";

export default function ReceivedTokens(){
  const { handleError, popupFormOpen, popupConfirmOpen, popupMessageOpen, popupPaymentOpen } = usePopupContext();
  const tableRef = useRef();
  const { products } = useUserContext();
  const {t} = useTranslation('common');

  const columns = [{
    Header: 'Token',
    accessor: 'token',
    width: 350
  },{
    Header: 'Sent From',
    accessor: 'issuedFromName',
    Cell: ({value}) => value
  },{
    Header: 'Token Type',
    accessor: 'type',
    Cell: ({value}) => products ? products.find(t => t.id === value)?.name : '',
    Filter: generateTextDropdown(products, true),
    width: 150
  },{
    Header: 'Used',
    accessor: 'used',
    Cell: ({value}) => value === true ? 'Yes' : 'No',
    Filter: generateTextDropdown([{name: 'Yes', id: true}], true),
    width: 60
  }];

  const actions = [{
    iconcls: 'fa fa-trash-o',
    onClick: row => popupConfirmOpen('Confirm', 'Are you sure you want to delete this token?')
      .then(() => {
        request('admin.tokens.delete', {id: row._id})
        .then(tableRef.current.refresh)
      }),
    title: 'Delete token'
  },{
    iconcls: 'fa fa-regular fa-gift',
    onClick: (row) => popupFormOpen('Redeem token', tokenFormPopup(row))
      .then((data) => save({user: data.user, tokenData: row})
      ).catch(()=>{}),
    title: "Redeem token",
    condition: row => row.used === false
  }];

  const save = async (values) => {
    const findedUser = await loadUsers(values.user);

    if(Object.keys(findedUser).length) {
        // Prepare the token data
        const data = await {...values.tokenData, owner: findedUser.id, ownerName: findedUser.name, ownerEmail: findedUser.email, redeemed: true};

        // Change token owner
        request(`admin.tokens.update`, {data, changeOwner: true})
        .then(tableRef.current.refresh)
        .catch(handleError);
    } else {
      popupMessageOpen(t('popupcontext.msg'), "User with that email doesn't exist!")
    }
  };

  const tokenFormPopup = (row) => (
    <div className="form-row">
      <ol>
        <ul>Token: <b>{row.token}</b></ul>
        <ul>Send this token to someone you want to surprise.</ul>
      </ol>

      <Field component={TextField}
       name="user"
       label="Send to"
       validate={required}
       required
       type="email"
      />

    </div>
  );

  const loadUsers = async (value, cb) => {
    let userData = {}
    await request('common.search', {search: value, self: true, byText: true})
    .then(data => {
      userData = {
        name: `${data.users[0].First_Name} ${data.users[0].Last_Name} - ${data.users[0].Email}`,
        id: data.users[0].id,
        email: data.users[0].Email,
      }
    })
    .catch(handleError);

    return userData;
  };

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-object-group" />Received Tokens</h5>

        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'admin.tokens.list'}
          apiParam={{receivedTokens: true}}
          onError={handleError}
        //   openAddPopup={() => popupFormOpen('Create token', tokenFormPopup(false))
        //     .then(save)
        //     .then(tableRef.current.refresh)
        //   }
          columnRefreshTrigger={products}
        />

      </div>
    </div>
  );
}