import React, { useState } from 'react';
import OutsideClick from '../../Components/OutsideClick';
import { request } from '../../Services/networkService';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../Contexts/userContext';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";
import { getName } from '../../Util/common';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Search() {
  const [searchResults, setSearchResults] = useState([]);
  const { getAvatar } = useUserContext();
  const { handleError } = usePopupContext();
  const {t} = useTranslation('common');

  const doSearch = str => {
    if (str.length > 2) {
      request('common.search', {search: str})
        .then(setSearchResults)
        .catch(handleError)
      } else {
        setSearchResults([]);
      }
  };

  const getResultName = r => r._type === 'page' ? r.Name : getName(r);

  const renderSearchResults = () => {
    const arr = [...((searchResults.users && searchResults.users.length) ? [{divider: t('search.users')}, ...searchResults.users] : []),
      ...((searchResults.honorees && searchResults.honorees.length) ? [{divider: t('search.honorees')}, ...searchResults.honorees] : []),
      ...((searchResults.pages && searchResults.pages.length) ? [{divider: t('search.pages')}, ...searchResults.pages] : [])];

    return !!arr.length &&
      (<ul className="search-results">
         {
           arr.map((val, idx) => {
             return(
               <li key={idx} className={val.divider ? 'divider' : ''}>
                 {
                    val.divider ? <span>{val.divider}</span> :
                     <Link onClick={() => setSearchResults([])} to={{pathname:`/${val._type === 'user' ? 'usertimeline' : (val._type === 'page' ? 'userpage' : 'honoreetimeline')}/${val._id}`}} >
                       <div className="results-avatar" style={{backgroundImage: `url('${getAvatar(val.ProfileImage || null)}')`}} />
                       <span>{getResultName(val)}</span>
                     </Link>
                 }
              </li>
             )
           })
         }
       </ul>)
  };

  return (
    <OutsideClick className="top-search" onOutsideClick={() => setSearchResults([])}>
      <form key="searchForm" onSubmit={doSearch}>
        <input
          key="searchInput"
          type="text" 
          placeholder={t('search.search') !== 'search.search' ? t('search.search') : 'Search'} 
          onChange={e => {
            doSearch(e.target.value);
          }} 
        />
        <i className="fa fa-search" />
      </form>
      { renderSearchResults() }
    </OutsideClick>
  )
};
