import React from 'react';
import { Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import { required } from '../../Util/form';
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";
import AddForm from '../../Components/Form/AddForm';

export default function Events({user, update, readOnly=false}){
  const { handleError } = usePopupContext();
  const {t} = useTranslation('common');
  const onSubmit = (data, form) => {
    return request('honoree.update', {NotableEvents: [...user.NotableEvents, data], _id: user._id})
      .then(res => {
        const usr = {...user, ...res};
        update(usr);
        setTimeout(() => form.reset(), 0);
      })
      .catch(handleError);
  };

  const remove = index => {
    return request('honoree.update', {NotableEvents: [...user.NotableEvents.slice(0, index), ...user.NotableEvents.slice(index+1)], _id: user._id})
      .then(res => {
        const usr = {...user, ...res};
        update(usr);
      })
      .catch(handleError);
  };

  return (
    <div className="central-meta">
      <div className="editing-interest">
        <h5 className="f-title"><i className="ti-calendar" /> {t('honoree.notableEvents')}</h5>

        <ol>
          {
            user && user.NotableEvents.map((item, index) => (
              <li key={index}>
                <div>
                  {item.Date} - {item.Name} <br/>
                  {item.About}
                  {
                    !readOnly &&
                    <span className="remove" title={t('honoree.remove')} onClick={() => remove(index)}>
                      <i className="fa fa-close" />
                    </span>
                  }
                </div>
              </li>
            ))
          }
        </ol>

        <AddForm readOnly={readOnly} onSubmit={onSubmit}>
          <div className="form-row">
            <div className="col">
              <Field component={TextField}
                     name="Date"
                     label={t('honoree.date')}
                     type="date"
                     validate={required}
                     required
                     readOnly={readOnly}
              />
            </div>
            <div className="col">
              <Field component={TextField}
                     name="Name"
                     label={t('honoree.eventName')}
                     validate={required}
                     required
                     readOnly={readOnly}
              />
            </div>
          </div>

          <Field component={TextField}
                 name="About"
                 label={t('honoree.aboutEvent')}
                 validate={required}
                 required
                 readOnly={readOnly}
          />
        </AddForm>

      </div>
    </div>
  );
}
