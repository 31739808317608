const ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV.toUpperCase() : 'DEV';

const CONF = {
  PROD: {
    BACKEND_URL: 'https://api.memkeepers.me',
    DOMAIN_CANNONICAL: 'memkeepers.me',
    S3_BUCKET: 'memkeepers-prod',
    STRIPE_API_KEY: 'pk_live_bQ70irgRs6GqMru08U1HTPsD00kL85vlOC',
    PAYPAL_CLIENT_ID: 'ASedC2QN4qCkVVB_Tpgj7xJ7LFhfOTFNAVJLdrXhNdHGA4RYpT_CNZBlo5_n6wpscZ5nXpf62a3Y7y52',
    sendExceptionMail: true,
    GOOGLE_TRANSLATE_KEY: 'AIzaSyC0D57HD5zedYuebm4Yx4pJZlD2jDYvkW0',
    ENV
  },
  DEV: {
    BACKEND_URL: 'https://api.dev.memkeepers.me',
    DOMAIN_CANNONICAL: 'dev.memkeepers.me',
    S3_BUCKET: 'memkeepers-dev',
    PAYPAL_CLIENT_ID: 'AXu5LfH2XgkKiK3uedDQKer1gsKI2nq9KKVAQNDEBVNxlbpTerUFefFhS4Qf1XYeeuyWCThJg6DToBJW',
    STRIPE_API_KEY: 'pk_live_bQ70irgRs6GqMru08U1HTPsD00kL85vlOC',
    GOOGLE_TRANSLATE_KEY: 'AIzaSyC0D57HD5zedYuebm4Yx4pJZlD2jDYvkW0',
    ENV
  },
  LOC: {
    BACKEND_URL: 'http://localhost:3001',
    DOMAIN_CANNONICAL: 'localhost:8000',
    PAYPAL_CLIENT_ID: 'AXu5LfH2XgkKiK3uedDQKer1gsKI2nq9KKVAQNDEBVNxlbpTerUFefFhS4Qf1XYeeuyWCThJg6DToBJW',
    //S3_BUCKET: 'memkeepers-dev',
    STRIPE_API_KEY: 'pk_test_51HhUnWBTigs3cT1HWinpaw1kAl0n9bsmssRwBFmMzXZCNjS6LxkP1oWXBAjBjGFFX8ugZRyOMKrei5POPgOCS78700lMLWMiDg',
    GOOGLE_TRANSLATE_KEY: 'AIzaSyC0D57HD5zedYuebm4Yx4pJZlD2jDYvkW0',
    ENV
  }
};

if (!CONF[ENV]) {
  // here we break the app because the environment variables are wrong
  throw new Error(`Missing config ${ENV}!`);
}

export const CONFIG = CONF[ENV];