import React from 'react';
import { Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import Select from '../../Components/Form/Select';
import { required } from '../../Util/form';
import { relations } from '../../Util/constants';
import { useTranslation } from "react-i18next";
import { usePopupContext } from '../../Contexts/popupContext';
import { request } from '../../Services/networkService';
import AddForm from '../../Components/Form/AddForm';
import { useHistory } from 'react-router-dom';

const Rel = ({values, readOnly}) => {
  const {t} = useTranslation('common');
  return (
    <div className="form-row">
      <div className="col">
        <Field component={Select}
               name="Relation"
               label={t('honoree.relation')}
               validate={required}
               options={relations}
               required
               readOnly={readOnly}
        />
      </div>
      <div className="col">
        <Field component={TextField}
               name="OtherRelation"
               label={t('honoree.otherRelation')}
               validate={values.Relation === "Other" ? required : undefined}
               required={values.Relation === "Other"}
               readOnly={readOnly}
               disabled={values.Relation !== "Other"}
        />
      </div>
    </div>
  )
}

export default function PersonalInfo({user, update, readOnly=false}){
  const { handleError } = usePopupContext();
  const {t} = useTranslation('common');
  const history = useHistory();

  const onSubmit = (data, form) => {
    return request('honoree.relation', {relation: data, _id:user.id})
      .then(r => {
        const usr = {...user, ...r};
        update(usr);
        setTimeout(()=>form.reset(), 0);
      })
      .catch(handleError);
  };

  const remove = index => {
    return request('honoree.update', {Relations: [...user.Relations.slice(0, index), ...user.Relations.slice(index+1)], _id:user.id})
      .then(r => {
        const usr = {...user, ...r};
        update(usr);
      })
      .catch(handleError);    
  };

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-notepad" /> {t('honoree.familyInformation')}</h5>  

        <table className="info-table">
          <tbody>
            {user && user.Relations.map((item,index)=>(
              <tr key={index} style={{...(item.Link && {cursor: 'pointer'})}} onClick={()=> item.Link && history.push({pathname: `/usertimeline/${item.Link}`})} >
                <td>{item.OtherRelation || item.Relation}</td>
                <td>{item.Full_Name}</td>
                <td>{item.Emails}</td>
                <td>
                  <i className="info-table-icon fa fa-close" title={t('honoree.remove')} onClick={()=> remove(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {
          (!user || !user.Relations.length) && <center>{t('honoree.informationMesseg')}</center>
        }

        <AddForm readOnly={readOnly} onSubmit={onSubmit}>

          <Rel />

          <div className="form-row">
            <div className="col">
              <Field component={TextField}
                     name="Full_Name"
                     label={t('honoree.fullName')}
                     validate={required}
                     required
                     readOnly={readOnly}
              />
            </div>
            <div className="col">
              <Field component={TextField}
                     name="Emails"
                     label={t('fields.emailAddress')}
                     type="email"
                     readOnly={readOnly}
              />
            </div>
          </div>

        </AddForm>

      </div>
    </div>
  );
}