import React, { Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as StorageUtil from '../../Util/storage';
import { useTranslation } from "react-i18next";

export default function TabPanel({tabs, loading, onTabChange, saveTab=true, hideNoContent=false}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const storageKey = 'tabpanel_' + useLocation().pathname;
  const {t} = useTranslation('common');

  useEffect(() => {
    const tab = StorageUtil.getItem(storageKey);
    saveTab && setSelectedTab(tab ? tab * 1 : 0);
  }, [storageKey, saveTab]);

  return(
    <Fragment>
      <div className="tabpanel-holder">
        {
          tabs && !!tabs.length && tabs.map((tab,i) => (
          <h5
            key={i}
            className={`f-title tabpanel-header ${selectedTab === i ? 'tab-active' : ''}`}
            onClick={() => {
              setSelectedTab(i);
              StorageUtil.setItem(storageKey, i);
              onTabChange && onTabChange(i);
            }}
            title={tab.title || tab.text}
          >
            <i className={tab.iconCls} />
            {tab.text}
          </h5>))
        }
      </div>
      {
        tabs && !!tabs.length && tabs[selectedTab].content && !loading && tabs[selectedTab].content
      }

      {
        loading &&
          <center>
            <i className="loading" />
          </center>
      }

      {
        !tabs[selectedTab].content && !loading && !hideNoContent &&
          <div className="no-content">
            {t('tabpanel.no-content')}
          </div>
      }

    </Fragment>
  );
}
