import React, { useEffect } from 'react';
import Image from './image'
import { usePopupContext } from '../../Contexts/popupContext';
import ReportDialog from './reportDialog';
import { request } from '../../Services/networkService';
import { useUserContext } from '../../Contexts/userContext';
import { getImageUrl } from '../../Util/common';

export default function Ad({ad, refresh}){
  const { handleError, popupFormOpen } = usePopupContext();
  const { user, setUser } = useUserContext();

  const showReportDialog = () => {
    popupFormOpen('Ad', <ReportDialog ad={ad} />)
      .then(data => {
        request('group.post.report', {postId: ad._id, reasonId: data.reportId})
          .then(HiddenPosts => {
            setUser({...user, HiddenPosts, __v: (user.__v + 1)});
            refresh && refresh();
          }) 
          .catch(handleError)
      })
  };

  const adFormPopup = (o) => {

    const ad = {
      ...o,
      uploadType: o && o.uploads && !!o.uploads.length && o.uploads[0].uploadType,
      url: o && o.uploads && !!o.uploads.length && getImageUrl(o.uploads[0].url)
    };

    return (
      <div className="form-row">
        <div className='adFull' style={{ maxHeight: '500px', overflow: 'scroll' }}>
          {
            ad.url && <div className='form-group image-preview' style={{ float: 'left', width: '110px', height: '80px', paddingRight: '10px' }}>    
              <Image ad={ad} borderRadius='5px'/>
            </div>
          }
          <div className='adText' style={{ float: 'none' }} dangerouslySetInnerHTML={{__html: ad.text}}></div>
        </div>
      </div>
    )
  };

  const doUpdate = (body) => {
    request('ad.tracking.update', body);
  }

  const saveAdTracking = (ad, clicks = false) => {
    let alreadyClicked = false;
    request('ad.tracking.listById', {id: ad._id})
    .then(result => {
      if(result && Object.keys(result).length) {
        if(clicks) {
          result.clicks.sidebar.forEach(element => {
            if(element.user === user._id) alreadyClicked = true;
          });
  
          if(!alreadyClicked) {
            result.clicks.sidebar.push({ user: user._id })
            doUpdate(result);
          }
        } else {
          result.times.sidebar = (result.times.sidebar + 1);
          doUpdate(result);
        }
      }
    })
  }

  const showAdPopup = () => {
    popupFormOpen('Ad', adFormPopup(ad), {initialValues: ad});
    saveAdTracking(ad, true);
  }

  useEffect(() => {
    if(ad && ad._id) {
      saveAdTracking(ad);
    }
  }, [ad]);

  return (
    <aside className="sidebar right">
      {/* { ad.text && 
      	<div className="sbr-text">
      		<a href={ad.link && ad.link.length > 0 ? ad.link : null} target="_new">
            <Image ad={ad} />
      			<div className='adText' dangerouslySetInnerHTML={{__html: ad.text}}></div>
      		</a>
      	</div> 
      } */}
      {
        ad.text ?
        <div className="sbr-text" onClick={showAdPopup}>
          {
            ad.link && ad.link.length ?
            <a href={ad.link} target="_new">
              <Image ad={ad} />
              <div className='adText' dangerouslySetInnerHTML={{__html: ad.text}}></div>
            </a>
            :
            <span>
              <Image ad={ad} />
              <div className='adText' dangerouslySetInnerHTML={{__html: ad.text}}></div>
            </span>
          }
        </div> 
        :
        <div className="sbr-text" onClick={showAdPopup}>
          {
            ad.link && ad.link.length ?
            <a href={ad.link} target="_new">
              <Image ad={ad} />
            </a>
            :
            <span>
              <Image ad={ad} />
            </span>
          }
      	</div>
      }
      <i className="ti-more-alt report-ad-btn" title="Hide this ad" onClick={showReportDialog} />
    </aside>
  );

}