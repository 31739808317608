import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";

export default function Pagination(props) {
  const activePage = props.page + 1;
  const pages = props.pages || 0;
  const changePage = page => page !== activePage && props.onPageChange(page - 1);
  const {t} = useTranslation('common');

  const getVisiblePages = (page, total) => {
    if (total < 7) {
      return  [1, 2, 3, 4, 5, 6].filter(page => page <= total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  const visiblePages = getVisiblePages(props.page, pages);

  return (
    <Fragment>

      { props.totalRows > 0 && <div className="total-rows"> {t('pagination.total')} {props.totalRows}</div>}

      <div className="table-navigation">
        <button
          title={t('pagination.first')}
          className="btn-first"
          onClick={() => changePage(1)}
          disabled={activePage === 1}
        >
          <div className="icon fa fa-angle-double-left"/>
        </button>

        <button
          title={t('pagination.prev')}
          className="btn-prev"
          onClick={() => activePage > 1 && changePage(activePage - 1)}
          disabled={activePage === 1}
        >
          <div className="icon fa fa-angle-left"/>
        </button>

        <div className="visible-pages-wrapper">
          {visiblePages.map((page, index, array) => {
            return (
              <Fragment key={index}>
                {array[index - 1] + 2 < page && <span>...</span>}
                <button
                  className={`page-button ${activePage === page ? 'page-button-active' : ''}`}
                  onClick={()=>changePage(page)}
                >
                  {page}
                </button>
              </Fragment>
            );
          })}
        </div>

        <button
          title={t('pagination.next')}
          className="btn-next"
          onClick={() => activePage < pages && changePage(activePage + 1)}
          disabled={activePage === pages || pages === 0}
        >
          <div className="icon fa fa-angle-right"/>
        </button>

        <button
          title={t('pagination.last')}
          className="btn-last"
          onClick={() => changePage(pages)}
          disabled={activePage === pages || pages === 0}
        >
          <div className="icon fa fa-angle-double-right"/>
        </button>

        <select
          className="sl-page-size"
          onChange={e => props.onPageSizeChange(e.target.value)}
          value={props.pageSize}
        >
          { props.pageSizeOptions.map((n,i)=> <option key={i} value={n} >{n}</option>) }
        </select>
      </div>

      { props.customMessage && <div className="custom-message">{props.customMessage}</div> }

    </Fragment>
  );
}
