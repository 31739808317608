import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../Contexts/userContext';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";
import { request } from '../../Services/networkService';
import { getName } from '../../Util/common';
import 'react-multi-email/style.css';

export default function BlockedUsers({user, page}){
  const history = useHistory();
  const {t} = useTranslation('common');
  const { user:currentUser, getAvatar } = useUserContext();
  const { handleError, popupMessageOpen } = usePopupContext();
  const [data, setData] = useState([]);
  const group = user ? user.OwnGroup : page?.OwnGroup;
  const admin = (user?.Owner || page?.Owner) === currentUser._id;

  const getMembers = () => {
    group && request('honoree.members', {id: group, blocked: true})
      .then(arr => 
        setData(arr.filter(o => o.User).map(o => ({
          text: getName(o.User),
          image: getAvatar(o.User.ProfileImage || null),
          link: '/usertimeline/' + o.User._id,
          status: o.Status,
          id: o.User._id
        }))
      ))
      .catch(handleError);
  };

  useEffect(() => {
    getMembers();
  }, [group]);

  const UnblockUser = (id) => {
    request('group.setUserStatus', {userId: id, groupId: group, status: 'Removed'})
    .then(() => {
      getMembers();
      popupMessageOpen('', 'The user is successfully unblocked and it can be added to your group!');
    })
    .catch(handleError)
  };

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-lock" /> Blocked Users</h5>

            <div className="photos-container small avatars">
              {
                (!data || !data.length) &&
                <center>
                  <div className="photos-empty"> No blocked users to display </div>
                </center>
              }

              {
                data && Object.keys(data).length && data.map((p,i) => {
                  return (
                    <div className="photos-holder" key={i}>
                      <div
                        className="photos-item"
                        style={{backgroundImage: `url("${p.image}")`}}
                        onClick={()=> !admin && p.link && history.push({pathname: p.link})}
                        title={p.text}
                      >
                      {
                        admin && p.status !== 'Owner' && <div className='removeUser'>
                          <span onClick={() => UnblockUser(p.id)}>Unblock User</span>
                        </div>
                      }
                      </div>
                      <div className="photo-title">
                        {p.text} <br/> 
                        {p.status === 'Owner' && `(${t("admin.groupAdmin")})`}
                        {p.status === 'Moderator' && !admin && `(${t("admin.moderator")})`}
                      </div>
                    </div>
                  )
                })
              }
            </div>            
      </div>
    </div>
  );
}
