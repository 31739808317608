import React, { useEffect, useRef } from 'react';

export default function TextArea ({ input, meta, label, required, children, ...others }) {
  const style = ((meta.error || meta.submitError) && meta.touched && !others.readOnly) ? { 'color': 'red' } : {};
  const fieldRef = useRef(null);

  useEffect(()=>{
    fieldRef.current && fieldRef.current.setCustomValidity((meta.error && meta.touched) ? meta.error : '');
  });

  return (
    <div className={`form-group ${others.className ? others.className : ''}`} style={{ opacity: others.disabled ? 0.5 : 1}}>    
      <label htmlFor={others.id} style={style}>{label} {required && !others.readOnly && <span className="is-required">*</span>}</label>
      
      {
        others.readOnly ? 
          <div>{input.value}</div>
        :
          <textarea ref={fieldRef} rows={4} {...input} {...others} onChange={e => {
            input.onChange(e);
            others.onChange && others.onChange(e);
          }} />
      }
      
      <i className="mtrl-select" />
      {children}
    </div>
  );
}