import React, { Fragment, useEffect, useState } from 'react';
import { usePopupContext } from '../../../Contexts/popupContext';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import SkeletonBlog from '../skeletonBlog';
import { request } from '../../../Services/networkService';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import TopBar from '../../../Components/TopBar';
import Footer from '../../../Components/Footer';
import { countriesList, userLocation } from '../../../Util/common';
import PageNotFound from '../../../ProfileComponent/PageNotFound.component';


const SEO_BlogContent = () => {
    const [blogURL, setBlogURL] = useState('');
    const [data, setData] = useState();
    const [wrongRegion, setWrongRegion] = useState(false);
    const params = useParams()
    const { handleError } = usePopupContext();
    const region = window.location.pathname.split('/')[2]

    const geoAvailability = async (current_region) => {
        const userGeo = await userLocation();
        const us_states = countriesList('us_states')
        const eu_countries = countriesList('eu_countries')
        const na_countries = countriesList('north_america')
        const sa_countries = countriesList('south_america')
        const af_countries = countriesList('afrika')
        const as_countries = countriesList('asia')
        const oc_countries = countriesList('oceania')

        if(userGeo.status === 200) {
            if(current_region.toLowerCase().includes('us')) {
                return Object.values(us_states).filter(item => item.name === userGeo.data.state).length ? true : false
            } else if(current_region.toLowerCase().includes('eu')) {
                return Object.values(eu_countries).filter(item => item.code === userGeo.data.country_code).length ? true : false
            } else if(current_region.toLowerCase().includes('na')) {
                return Object.values(na_countries).filter(item => item.code === userGeo.data.country_code).length ? true : false
            } else if(current_region.toLowerCase().includes('sa')) {
                return Object.values(sa_countries).filter(item => item.code === userGeo.data.country_code).length ? true : false
            } else if(current_region.toLowerCase().includes('af')) {
                return Object.values(af_countries).filter(item => item.code === userGeo.data.country_code).length ? true : false
            } else if(current_region.toLowerCase().includes('as')) {
                return Object.values(as_countries).filter(item => item.code === userGeo.data.country_code).length ? true : false
            } else if(current_region.toLowerCase().includes('oc')) {
                return Object.values(oc_countries).filter(item => item.code === userGeo.data.country_code).length ? true : false
            }
        }

    }

    function blogAccess() {
        geoAvailability(region)
        .then(result => {
            if(result) {
                setBlogURL(params.URL)
                setWrongRegion(false)
            }
            else setWrongRegion(true)
        })
    }

    useEffect(() => {
        if(params) {
            blogAccess()
        }
    }, [params])

    const modules = {
        toolbar: false
    }

    const listFeature = () => {
        request('public.listSEO_BlogURL', {url: blogURL, region})
        .then(res => { 
            if(res) setData(res)
            else setWrongRegion(true)    
        })
        .catch((err) => handleError(err))
    }

    useEffect(() => {
        if(blogURL) listFeature();
    }, [blogURL])

    return (
        <>
            {
                wrongRegion === true ? 
                <PageNotFound/>
                : 
                <Fragment>
                    <TopBar />
                        <div className="theme-layout marble">
                            <div className="welcome container">
                                <div className='row'>
                                    <div className='features col-lg-12'>
                                    {
                                        typeof(data) !== 'undefined' && Object.keys(data).length ?
                                            <div className='content-container'>
                                                <Helmet>
                                                    <meta name="keywords" content={data.meta.keywords} />
                                                    <meta name="description" content={data.meta.description} />
                                                    <title>{data.title}</title>
                                                </Helmet>
                                                <ReactQuill 
                                                theme="snow"
                                                value={data.text}
                                                readOnly
                                                modules={modules}
                                                />
                                            </div>
                                        
                                        :
                                        <SkeletonBlog />
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    <Footer />
                </Fragment>
            }
        </>
    );
}

export default SEO_BlogContent;
