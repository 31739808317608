import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Form, Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import FileField from '../../Components/Form/FileField';
import { required } from '../../Util/form';
import LoadingButton from '../../Components/LoadingButton';
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import { useGalleryContext } from '../../Contexts/galleryContext';
import { getImageUrl } from '../../Util/common';
import { useUserContext } from '../../Contexts/userContext';

import logoPdf from '../../assets/images/pdf-icon.png';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Gallery({galleryType, user, readOnly, onlyImages, refreshMemobjects}){
  const {t} = useTranslation('common');
  const { handleError, popupConfirmOpen, popupPaymentOpen } = usePopupContext();
  const { galleryOpen } = useGalleryContext();
  const [progress, setProgress] = useState(0);
  const [photos, setPhotos] = useState();
  const groupId = user && user.OwnGroup;
  const { user:currentUser } = useUserContext();

  const getPhotos = () => request('group.photos', {id: groupId, pictureType: galleryType, onlyImages}).then(setPhotos);

  useEffect(()=>{
    groupId && getPhotos();
  }, [groupId]);

  const gallery = () => {
    if(user && typeof user.groupRelation !== 'undefined') {
      if(user?.groupRelation === 'Accepted' || user?.groupRelation === 'Moderator' || user?.groupRelation === 'Owner') {
        return (
          <Form
            onSubmit={onSubmitImage}
            render={({ handleSubmit, submitting, values, pristine, form}) => {
              return (
                <form onSubmit={e => handleSubmit(e, form)} className="form-gallery">
                  <div className="form-row">
                    <div className="col">
                      <Field component={TextField}
                            name="text"
                            label={<Translate>Image description</Translate>}
                      />
                      <div className="progress" style={{width: `${progress}%`}}/>
                    </div>
                    <div className="col">
                      <Field component={FileField}
                      name="file"
                      type="file"
                      label={<Translate>Select file</Translate>}
                      validate={required}
                      required
                      accept="image/*"
                      multiselect
                      />
                    </div>
                    <div className="col">
                      <div className="submit-btns text-center">
                        <LoadingButton 
                          iconCls="fa fa-save" 
                          loading={submitting} 
                          disabled={pristine || progress} 
                          title={t('honoree.save')} 
                        />
                      </div>
                    </div>
                  </div>
    
                </form>
            )}}
          />
        )
      }
    }
  }

  const onSubmitImage = (data, form) => {
    data.file.forEach(f => {
      let formData = new FormData();
      formData.append('text', data.text || '');
      formData.append('postType', "Picture");
      formData.append('pictureType', galleryType);
      formData.append('id', groupId);
      formData.append('file', f);

      return request('group.post.create', formData, setProgress)
        .then(res => { 
          getPhotos();
          setTimeout(() => form.reset(), 0);
        })
        .catch(handleError)
        .finally(() => {
          setProgress(0);
        });
    })
  };

  const deleteImage = id => {
    return popupConfirmOpen(t('postbox.confirm'), t('postbox.delete-post'))
    .then(
      () => request(`group.post.delete`, {id})
      .then(getPhotos)
      .catch(handleError)
    ).catch(()=>{})
  };

  const postAsMemento = photo => {
    popupPaymentOpen({itemName: 'Premium Memento', showMaintanence: true})
      .then(paymentData => {

        const data = {
          id: groupId,
          postAsMemobject: true,
          public: true,
          ...(paymentData && {
            autoRenew: paymentData.autoRenew,
            paymentIntentId: paymentData.paymentIntentId,
            productId: paymentData.productId,
            token: paymentData.token,
            paypalId: paymentData.paypalId
          }),
          uploads: [{
            url: photo.url,
            name: 'something',
            hash: '',
            size: 0,
            uploadType: 'image'
          }]
        };

        return request('group.post.create', data)
          .then(() => refreshMemobjects && refreshMemobjects())
          .catch(handleError)
      })

  };

  return (
   <Fragment>
     <h5 className="f-title"><i className="fa fa-user" /> <Translate>Photos</Translate> </h5>
      {gallery()}
     {/* {
        !readOnly &&
        <Form
          onSubmit={onSubmitImage}
          render={({ handleSubmit, submitting, values, pristine, form}) => {
            return (
              <form onSubmit={e => handleSubmit(e, form)} className="form-gallery">
                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                           name="text"
                           label={t('fields.imgDesc')}
                    />
                    <div className="progress" style={{width: `${progress}%`}}/>
                  </div>
                  <div className="col">
                    <Field component={FileField}
                     name="file"
                     type="file"
                     label={t('admin.selectFile')}
                     validate={required}
                     required
                     accept="image/*"
                     multiselect
                    />
                  </div>
                  <div className="col">
                    <div className="submit-btns text-center">
                      <LoadingButton 
                        iconCls="fa fa-save" 
                        loading={submitting} 
                        disabled={pristine || progress} 
                        title={t('honoree.save')} 
                      />
                    </div>
                  </div>
                </div>

              </form>
          )}}
        />
      } */}

      <div className="photos-container small avatars">
        {
          photos && photos.data && !photos.data.length &&
          <center>
            <div className="photos-empty"> {t('honoree.noPhotosDisplay')} </div>
          </center>
        }

        {
          photos && photos.data && photos.data.map((p,i) => {
            const photoUrl = getImageUrl(p.url);
            return (
              <div className="photos-holder" key={i}>
                {
                  p.type === "image" &&
                  <div
                    className="photos-item"
                    style={{backgroundImage: `url("${photoUrl}")`}}
                    onClick={() => galleryOpen(photoUrl)}
                  />
                }

                {
                  p.type === "video" &&
                  <video controls className="photos-item">
                    <source src={photoUrl} />
                    {t('postbox.video-tag')}
                  </video>
                }

                {
                  p.type === "audio" &&
                  <audio controls src={photoUrl}>
                    {t('postbox.audio-tag')}
                  </audio>
                }

                {
                  p.type === "pdf" &&
                  <div
                    className="photos-item" 
                    style={{backgroundImage: `url("${logoPdf}")`}}
                    onClick={() => galleryOpen(photoUrl, 'pdf')}
                  >
                  </div>
                }

                <div className="photo-title">{p.text !== 'undefined' ? p.text : ''}</div>

                {
                  currentUser._id === user.Owner &&
                  <div className="icon-delete">
                    <i className="fa fa-trash" title={t('postbox.post-delete')} onClick={() => deleteImage(p._id)}></i>
                  </div>
                }

                {
                  galleryType === 'common' && onlyImages === true &&
                  <div className="icon-memento">
                    <i className="fa fa-diamond" title="Post as memento" onClick={() => postAsMemento(p)}></i>
                  </div>
                }

              </div>
            )
          })
        }
      </div>

   </Fragment>
  );
};
