import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function LoadingButon({disabled, loading, title, iconCls, link, noClass, noTitle, tooltip, ...others}){
  const history = useHistory();
  const componentIsMounted = useRef(true);
  const [internaLoading, setInternalLoading] = useState(false);
  const {t} = useTranslation('common');

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    }
  }, []);

  const onClick = e => {
    if (link) {
      history.push({pathname: link});
    } else if (others.onClick) {
      setInternalLoading(true);
      Promise.resolve(others.onClick(e))
        .then(() => componentIsMounted.current && setInternalLoading(false))
        .catch(() => componentIsMounted.current && setInternalLoading(false));
    }
  }

  return (
    <button
      disabled={disabled || loading || internaLoading}
      type={link || others.onClick ? "button" : "submit"}
      title={tooltip}
      {...others}
      onClick={onClick}
      className={`${noClass ? '' : 'mtr-btn'} btn-progress ${others.className ? others.className : ''}`}
    >
      { loading || internaLoading ? <i className="loading" /> : iconCls && <i className={iconCls} /> }
      {!noTitle && <span>{title || t('loadingbutton.submit')}</span>}
    </button>
  );
};
