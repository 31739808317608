import React from 'react';
import Layout from '../../Components/Layout';
import { useUserContext } from '../../Contexts/userContext';

import PersonalInfo from './BasicInfo';
import Education from './Education';
import Interests from './Interests';
import Membership from './Membership';
import Tokens from './tokens';
import ReceivedTokens from './receivedTokens';
import AccountSettings from './AccountSettings';
import ChangePass from './ChangePass';
import Delete from './Delete';

import Friends from '../Groups/friends';

import { useTranslation } from "react-i18next";
import TwoFactorAuth from './TwoFactorAuth';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function About() {

  const { user, setUser } = useUserContext();
  const {t} = useTranslation('common');

  const pageParams = {
    user: user,
    readOnly: false,
    update: setUser
  };

  const menuConfig = [
    {
      title: t('profile.basicInfoSidebar'),
      contents: PersonalInfo,
      iconCls: 'ti-info-alt',
      path: '',
      params: pageParams
    },{
      title: t('groups.friends'),
      contents: Friends,
      iconCls: 'fa fa-users',
      path: 'friends'
    },{
      title: t('profile.eduWorkSidebar'),
      contents: Education,
      iconCls: 'ti-mouse-alt',
      path: 'education',
      params: pageParams
    },{
      title: t('profile.intHobbySidebar'),
      contents: Interests,
      iconCls: 'ti-heart',
      path: 'interests',
      params: pageParams
    },{
      title: t('profile.membershipsSidebar'),
      contents: Membership,
      iconCls: 'ti-package',
      path: 'membership',
      params: pageParams
    },{
      title: <Translate>Sent Tokens</Translate>,
      contents: Tokens,
      iconCls: 'fa fa-diamond',
      path: 'tokens',
      params: pageParams
    },{
      title: <Translate>Received Tokens</Translate>,
      contents: ReceivedTokens,
      iconCls: 'fa fa-diamond',
      path: 'receivedTokens',
      params: pageParams
    },{
      title: t('profile.accSettingsSidebar'),
      contents: AccountSettings,
      iconCls: 'ti-settings',
      path: 'settings'
    },
    {
      title: <Translate>Two-Factor Auth</Translate>,
      contents: TwoFactorAuth,
      iconCls: 'ti-lock',
      path: 'twoFA'
    },{
      title: t('profile.passChangeSidebar'),
      contents: ChangePass,
      iconCls: 'ti-lock',
      path: 'changePass'
    },{
      title: t('profile.deleteProfileSidebar'),
      contents: Delete,
      iconCls: 'ti-trash',
      path: 'deleteprofile'
    }
  ];

  return(
    <Layout config={menuConfig} title={t('profile.editInfoSidebar')} />
  );
};
