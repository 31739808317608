import React, { useEffect, useRef } from 'react';
import Select, { createFilter } from 'react-select';
import ServerSideSelect from 'react-select/async';

export default function MultiSelectField ({ input, meta, label, required, loadOptions, ...others }) {
  const style = ((meta.error || meta.submitError) && meta.touched && !others.disabled) ? { 'color': 'red' } : {};
  const ref = useRef(null);

  useEffect(()=>{
    ref.current && ref.current.setCustomValidity((meta.error && meta.touched) ? meta.error : '')
  });

  return (
    <div className='input-group' style={{ ...others.style, opacity: others.disabled ? 0.5 : 1}}>
      {
        label && <label htmlFor={others.id} style={style}>{label} {required && <span className="is-required">*</span>}</label>
      }
      { 
        loadOptions ?
        <ServerSideSelect
          cacheOptions
          className={`multi-select-input ${others.isSearchable ? 'searchable' : ''}`}
          classNamePrefix="react-select"
          loadOptions={loadOptions}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          defaultOptions
          maxMenuHeight={200}
          isSearchable={others.readOnly ? false : others.isSearchable}
          menuIsOpen={others.readOnly ? false : undefined}
          {...others}
          {...input}
          //onInputChange={this.handleInputChange}
          onChange={(...args) => {
            input && input.onChange(...args);
            others.onChange && others.onChange(...args);
          }}
        />
        :
        <Select
          className={`multi-select-input ${others.isSearchable ? 'searchable' : ''}`}
          classNamePrefix="react-select"
          isMulti
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          maxMenuHeight={200}
          isDisabled={others.disabled}
          isSearchable={false}
          {...input}
          {...others}
          filterOption={createFilter({ ignoreAccents: false })}
          onChange={(...args) => {
            input.onChange(...args);
            others.onChange && others.onChange(...args);
          }}
        />
      }
      {/* The hidden input is needed for displaying the error messages in field validation
      https://github.com/JedWatson/react-select/issues/3140 */}
      <input
        ref={ref}
        tabIndex={-1}
        autoComplete="off"
        style={{ opacity: 0, height: 0, padding: 0 }}
        defaultValue={input.value}
      />
    </div>
  );
}