import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../Components/Layout';
import { useUserContext } from '../../Contexts/userContext';
import { request } from '../../Services/networkService';

import BasicInfo from '../../Pages/Honorees/BasicInfo';
import BasicInfoPet from '../../Pages/Honorees/BasicInfoPet';
import Relation from './Relation';
import Interests from './Interests';
import Membership from './Membership';
import Achievements from './Achievements';
import Events from './Events';
import Education from './Education';
import Members from './Members';

import { useTranslation } from "react-i18next";
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function HonoreeProfile() {
  const { id } = useParams();
  const { user, targetUser, setTargetUser } = useUserContext();
  const {t} = useTranslation('common');
  const refreshHonoree = () => id && request('honoree.get', {id: id}).then(setTargetUser);

  useEffect(()=>{
    refreshHonoree();
  }, [id]);

  const readOnly = targetUser && targetUser.Owner !== user._id && targetUser.groupRelation !== 'Moderator';

  const pageParams = {
    user: targetUser,
    readOnly: readOnly,
    update: setTargetUser
  };

  const menuConfig = [
    {
      title: targetUser && targetUser.HonoreeType === 'Pet' ? t('honoree.petInformation') : t('honoree.personalInformation'),
      contents: targetUser && targetUser.HonoreeType === 'Pet' ? BasicInfoPet : BasicInfo,
      iconCls: 'ti-info-alt',
      path: '',
      params: pageParams
    },
    ...(
        targetUser && targetUser.HonoreeType === 'Pet' ? [] : [{
        title: t('honoree.relation'),
        contents: Relation,
        iconCls: 'ti-notepad',
        path: 'relation',
        params: pageParams
      },{
        title: t('honoree.education&Work'),
        contents: Education,
        iconCls: 'ti-mouse-alt',
        path: 'education',
        params: pageParams
      },{
        title: t('honoree.interests&Hobbies'),
        contents: Interests,
        iconCls: 'ti-heart',
        path: 'interests',
        params: pageParams
      },{
        title: t('honoree.memberships'),
        contents: Membership,
        iconCls: 'ti-package',
        path: 'membership',
        params: pageParams
      },{
        title: t('honoree.achievements'),
        contents: Achievements,
        iconCls: 'ti-star',
        path: 'achievements',
        params: pageParams
      },{
        title: t('honoree.notableEvents'),
        contents: Events,
        iconCls: 'ti-calendar',
        path: 'events',
        params: pageParams
      },{
        title: <Translate>Group Members</Translate>,
        contents: Members,
        iconCls: 'ti-calendar',
        path: 'members',
        params: pageParams
      }]
    )
  ];

  return(
    <Layout asUser={targetUser} refresh={refreshHonoree} memobjects config={menuConfig} title={t('honoree.honoreeInformation')} />
  );
};
