import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout';
// import { useTranslation } from "react-i18next";
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function HowItWorks() {
  // const {t} = useTranslation('quicklinks');
  const [docData, setDoc] = useState('');
  const { handleError, popupMessageOpen } = usePopupContext();
  
  const getDocument = () => {
    request('public.listHowItWorks')
    .then(res => {
      if(Object.keys(res).length) {
        setDoc(res[0].text)
      } else {
        popupMessageOpen('Message', 'Missing Document!');
      }
    })
    .catch(err => {
      handleError(err)
    })
  }

  const modules = {
    toolbar: false
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getDocument();
  }, []);

  return (
    <Layout showHeader={false} infoPage>
      <div className="container documents">
        <div className="contct-info policies">
          {
              docData && 
              <ReactQuill 
                theme="snow"
                value={docData}
                readOnly
                modules={modules}
              />
          }
          {/* <div className="cnt-title ">

            <h1 align="center"><b>{t('howItWorks.title')}</b></h1>
            <ul id="first_comment" className="comments_list">
            <li id="comment-11948059" className="ci" data-comment-id="11948059" data-application-id="2" data-author-id="1634554">
            <div className="flex">
            <div className="flex-auto">
            <div className="comment_content">
            <p>{t('howItWorks.firstP')}</p>
            <p>1.&nbsp; {t('howItWorks.firstB')}</p>
            <p>2.&nbsp; {t('howItWorks.secondB')}</p>
            <ul>
            <li>
            <p>{t('howItWorks.secondSubB1')}</p>
            </li>
            <li>
            <p>{t('howItWorks.secondSubB2')}</p>
            </li>
            <li>
            <p>{t('howItWorks.secondSubB3')}</p>
            </li>
            <li>
            <p>{t('howItWorks.secondSubB4')}</p>
            </li>
            <li>
            <p>{t('howItWorks.secondSubB5')}</p>
            </li>
            <li>
            <p>{t('howItWorks.secondSubB6')}</p>
            </li>
            <li>
            <p>{t('howItWorks.secondSubB7')}</p>
            </li>
            <li>
            <p>{t('howItWorks.secondSubB8')}</p>
            </li>
            <li>
            <p>{t('howItWorks.secondSubB9')}</p>
            </li>
            </ul>
            <p>3.&nbsp; {t('howItWorks.thirdB')}</p>
            <p>4.&nbsp; {t('howItWorks.fourthB')} </p>
            </div>
            </div>
            </div>
            <div className="attachments_html">&nbsp;</div>
            <div className="clear">&nbsp;</div>
            </li>
            </ul>

          </div> */}
        </div>
      </div>
    </Layout>
  );
}