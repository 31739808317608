import React, { Fragment } from 'react';
import './PasswordStrength.css';
import zxcvbn from 'zxcvbn';
import { useTranslation } from "react-i18next";

export default function PasswordStrength({password}) {

  const {t} = useTranslation('common');
  const createPasswordLabel = result => {
    switch (result.score) {
      case 0:
        return t('pass-strenght.weak');
      case 1:
        return t('pass-strenght.weak');
      case 2:
        return t('pass-strenght.fair');
      case 3:
        return t('pass-strenght.good');
      case 4:
        return t('pass-strenght.strong');
      default:
        return t('pass-strenght.weak');
    }
  };

  const testedResult = password ? zxcvbn(password) : 0;

  return (
    <div className="password-strength-meter">
      <progress
        className={`password-strength-meter-progress strength-${createPasswordLabel(testedResult)}`}
        value={testedResult.score}
        max="4"
      />
      <br />
      <label className="password-strength-meter-label">
        {password && (
          <Fragment>
            <strong>{t('pass-strenght.pass')}</strong> {createPasswordLabel(testedResult)}
          </Fragment>
        )}
      </label>
    </div>
  );
};
