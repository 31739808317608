import React from 'react';
import { useTranslation } from "react-i18next";
import { Field } from 'react-final-form';
import TextField from '../../../Components/Form/TextField';
import Select from '../../../Components/Form/Select';
import { required } from '../../../Util/form';
import csc from 'country-state-city';

export default function CityStateCountry({values, readOnly, cityProp, stateProp, countryProp, placeTitle}){
  const {t} = useTranslation('common');
  const countryProperty = countryProp || "CountryId";

  return (
    <div className="form-row">
      <div className="col">
        <Field component={TextField}
               name={cityProp || "City"}
               label={placeTitle || t('honoree.cityName')}
               validate={required}
               readOnly={readOnly}
               required
        />
      </div>
      <div className="col">
        <Field component={Select}
               name={countryProperty}
               label={t('honoree.country')}
               validate={required}
               options={csc.getAllCountries()}
               required
               readOnly={readOnly}
        />
      </div>
      <div className="col">
        <Field component={Select}
               name={stateProp || "StateId"}
               label={t('honoree.state')}
               options={values[countryProperty] && csc.getStatesOfCountry(values[countryProperty].toString())}
               readOnly={readOnly}
        />
      </div>
    </div>
  )
}