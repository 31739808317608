import React, { useState, useEffect, Fragment } from 'react';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Modal } from 'react-bootstrap';
import { formatCurrency } from '../../Util/common';
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import { useUserContext } from '../../Contexts/userContext';
import { useTranslation } from "react-i18next";
import PayPalBtn from './payPalBtn';

export default function PaymentForm ({onClose, memobject, showMaintanence, itemName, multipleMemo = false}) {
  const { popupSubmit, popupClose } = usePopupContext();
  const elements = useElements();
  const stripe = useStripe();
  const { user, setUser, getNames, products } = useUserContext();
  const {t} = useTranslation('common');

  const availableProducts = (Array.isArray(itemName) ? itemName : [itemName]).map(p => products.find(o => o.name === p));

  const [card, setCard] = useState();
  const [token, setToken] = useState('');
  const [saveCard, setSaveCard] = useState(true);
  const [maintenance, setMaintenance] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [loading, setLoading] = useState(false);
  const payBtnDisabled = loading || ((!selectedMethod || !selectedProduct || (selectedMethod === 1 && !token)) && (!stripe || !card || card.empty || !card.complete));

  const CARD_ELEMENT_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: false
  };

  useEffect(() => {
    if (user.StripeCustomerId) {
      setLoading(true);
      request('payment.getMethods')
        .then(res => {
          if (res && res.length) {
            setPaymentMethods(res);
            setSelectedMethod(res[0].id);
          }
        })
        .catch(popupClose)
        .finally(() => setLoading(false))
      }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    if (selectedMethod === 1) { //paying with token
      popupSubmit({...memobject, autoRenew: false, productId: selectedProduct._id, token})
      window.location.href = '/thank-you'
    } else { //paying with Stripe
      const card = elements.getElement(CardElement);
      const paymentMethod = {
        payment_method: {
          card: card,
          billing_details: {
            name: getNames()
          }
        },
        ...(saveCard && {setup_future_usage: 'off_session'})
      };
      setLoading(true);
      selectedProduct && request('payment.createIntent', {id: selectedProduct._id, numOfProducts: memobject && Object.keys(memobject).length ? Object.keys(memobject).length - 1 : 1,  ...(selectedMethod && {paymentMethod: selectedMethod})})
        .then(intent => {
          setUser({...user, StripeCustomerId: intent.localCustomerId});
          if (selectedMethod) {
            return stripe.confirmCardPayment(intent.client_secret, {payment_method: selectedMethod});
          } else {
            return stripe.confirmCardPayment(intent.client_secret, paymentMethod);
          }
        })
        .then(result => {
          if (result.error) {
            popupClose(result.error);
          } else {
            popupSubmit({...memobject, paymentIntentId: result.paymentIntent.id, autoRenew: maintenance, productId: selectedProduct._id});
            window.location.href = '/thank-you'
          }
        })
      .catch(popupClose)
    }
  };

  const paypalPaymentComplete = (info) => {
    //paying with paypal
    if (info.status === "COMPLETED") {
      popupSubmit({...memobject, autoRenew: maintenance, productId: selectedProduct._id, paypalId: info.id})
    } else {
      popupClose('Error');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>

        <div>
          <div className="form-radio" style={{marginTop: '20px'}}>
              <h5>{t('paymentform.item')}</h5>
              {
                availableProducts.map((o,i) => (
                  <div key={o.id} className="radio">
                    <label>
                      <input
                        type="radio"
                        name="radioProducts"
                        value={o._id}
                        checked={selectedProduct?._id === o._id}
                        onChange={() => setSelectedProduct(o)}
                      />
                      <i className="check-box" />
                      {/* {o.name}: {formatCurrency(o.price)} ({o.description}) */}
                      {
                        memobject && multipleMemo && Object.keys(memobject).length ?
                        `${o.name}: ${formatCurrency(o.price * (Object.keys(memobject).length - 1))} (${o.description})`
                        :
                        `${o.name}: ${formatCurrency(o.price)} (${o.description})`
                      }
                    </label>
                  </div>
              ))
            }
          </div>

          {
            showMaintanence && selectedProduct && selectedMethod !== 1 &&
            <div>
              <div className="checkbox" title={t('paymentform.renew-long')} >
                <label>
                  <input type="checkbox" checked={maintenance} onChange={e => setMaintenance(e.target.checked)}/>
                  <i className="check-box" />
                  {
                    memobject && multipleMemo && Object.keys(memobject).length ? 
                    <span>{t('paymentform.auto-renew')}: {formatCurrency(selectedProduct.autorenew * (Object.keys(memobject).length - 1))} - {t('purchases.monthly')}</span>
                    :
                    <span>{t('paymentform.auto-renew')}: {formatCurrency(selectedProduct.autorenew)} - {t('purchases.monthly')}</span>
                  }
                  {/* <span>{t('paymentform.auto-renew')}: {formatCurrency(selectedProduct.autorenew)} - {t('purchases.monthly')}</span> */}
                </label>
              </div>
            </div>
          }  


            <div className="form-radio" style={{marginTop: '20px'}}>
              <h5>{t('paymentform.select-payment')} </h5>
              {
                memobject && multipleMemo && (Object.keys(memobject).length - 1) !== 1 ? 
                null
                :
                <div key={1} className="radio">
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value={1}
                      checked={selectedMethod === 1}
                      onChange={() => setSelectedMethod(1)}
                    />
                    <i className="check-box" />
                    Pay with token
                    <input className="token-input" disabled={selectedMethod !== 1} value={token} onChange={e => setToken(e.target.value)}/>
                  </label>
                </div> 
              }

                {
                  paymentMethods.map((o,i) => (
                    <div key={o.id} className="radio">
                      <label>
                        <input
                          type="radio"
                          name="radio"
                          value={o.id}
                          checked={selectedMethod === o.id}
                          onChange={() => setSelectedMethod(o.id)}
                        />
                        <i className="check-box" />
                        {`${o.brand} ***${o.last4} ${t('paymentform.expire')} ${o.exp_month}/${o.exp_year}`}
                      </label>
                    </div>
                ))
              }

              <div key={0} className="radio">
                <label>
                  <input
                    type="radio"
                    name="radio"
                    value={0}
                    checked={selectedMethod === 0}
                    onChange={() => setSelectedMethod(0)}
                  />
                  <i className="check-box" />
                  {t('paymentform.other')}
                </label>
              </div>

            </div>
          

          {
            !selectedMethod &&
            <Fragment>
              <div className="stripe-card-container">
                <CardElement options={CARD_ELEMENT_OPTIONS} onChange={setCard} />
              </div>

              <div style={{marginTop: '10px'}}>
                <div className="checkbox" >
                  <label>
                    <input type="checkbox" checked={saveCard} onChange={e => setSaveCard(e.target.checked)}/>
                    <i className="check-box" />
                    <span>{t('paymentform.save-card')}</span>
                  </label>
                </div>
              </div>
            </Fragment>
          }


        </div>

        {
          loading &&
          <div className="loading-mask">
            <i className="loading" />
          </div>
        }

      </Modal.Body>
      <Modal.Footer>
        <PayPalBtn product={selectedProduct} onComplete={paypalPaymentComplete} />
        <Button type="submit" disabled={payBtnDisabled} className="btn-pay">{t('paymentform.pay')}</Button>
        <Button type="button" onClick={() => onClose()}>{t('paymentform.cancel')}</Button>
      </Modal.Footer>
    </form>
  );
};
