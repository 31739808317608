import React, { useEffect, useState } from 'react';
import { useGalleryContext } from '../../Contexts/galleryContext';
import { usePopupContext } from '../../Contexts/popupContext';
import { useUserContext } from '../../Contexts/userContext';
import { request } from '../../Services/networkService';
import { formatDate, getImageUrl, userRole } from '../../Util/common';
import LoadingButton from '../../Components/LoadingButton';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function MyMementos(props) {
  const mementoType = props.mementoType;
  const { handleError } = usePopupContext();
  const [memos, setMemos] = useState([]);
  const [ASCsort, setSort] = useState(true);
  const { galleryOpen } = useGalleryContext();
  const { user } = useUserContext();
  const now = new Date();

  const listMementos = () => {
    if(typeof mementoType !== 'undefined' && mementoType === 'Premium') {
        request('payment.purchases', {memobjects: true})
        .then(({data}) => setMemos(data))
        .catch(err => handleError(err))
    } else {
        request('group.memobjects')
        .then(({data}) => setMemos(data))
        .catch(err => handleError(err))
    }
  }

  const setInfinite = (model, infinite, type = '') => {
    if(type) {
        request('group.post.infinite', {_id: model._id, infinite, type})
        .then(() => listMementos(props.mementoType))
        .catch(err => handleError(err.message))
    }
  }

  const deletePost = (postId = '', sale = {}) => {
    if(typeof mementoType !== 'undefined' && mementoType === 'Premium' && Object.keys(sale).length > 0) {
        request('group.post.deleteBySale', {id: sale._id})
        .then(() => listMementos())
        .catch((err) => handleError(err))
    } else if(postId.length > 0) {
        request('group.post.delete', {id: postId})
        .then(() => listMementos())
        .catch((err) => handleError(err))
    }
  }

  const formatExpire = (userAccess, infinite, expiry) => {
    const eDate = expiry;
    const currentDate = now.toISOString();

    if(userAccess === 'Global Admin') {
        if(infinite) {
            return <p><Translate>Does Not Expire</Translate></p>
        } else {
            if(eDate < currentDate) {
                return <p className='expired'><Translate>Expired</Translate></p>
            } else if(eDate === currentDate) {
                return <p><Translate>Expire Today</Translate></p>
            } else {
                return <p><Translate>Expire</Translate>: <b>{formatDate(eDate)}</b></p>
            }
        }
    } else {
        if(eDate < currentDate) {
            return <p className='expired'><Translate>Expired</Translate></p>
        } else if(eDate === currentDate) {
            return <p><Translate>Expire Today</Translate></p>
        } else {
            return <p><Translate>Expire</Translate>: <b>{formatDate(eDate)}</b></p>
        }
    }
  }

  const sortMemos = () => {
    setSort(prev => !prev);
  }

  useEffect(() => {
    if(user) {
        listMementos();
    }
  }, [user])

  return (
    <div className="central-meta">
      <div className="editing-info">
        {
            memos && Object.keys(memos).length && user &&
            <h5 className="f-title"><Translate>You have posted</Translate> '<b>{Object.keys(memos).length}</b>' {props.mementoType && <Translate>{props.mementoType}</Translate>} <Translate>Mementos</Translate> <LoadingButton className='postedMementos sortBtn' onClick={sortMemos} iconCls={ASCsort ? 'ti-arrow-down' : 'ti-arrow-up'} title={<Translate>Sort by date</Translate>} /></h5>
        }
        {
            memos && Object.keys(memos).length && user &&
            <div className='mementosCnt'>
                {
                    props.mementoType && props.mementoType === 'Premium' &&
                    memos.sort((a, b) => ASCsort ? (a.expiry < b.expiry) ? 1 : -1 : (a.expiry > b.expiry) ? 1 : -1).map((sale) => {
                        const saleId = sale._id?.toString();
                        const imageUrl = getImageUrl(sale.memobjectUrl);
                        return (
                            !sale.deleted && <div className='memento' key={sale._id}>
                                <div className='moExpiry'>
                                    {
                                        formatExpire(userRole(user.AccessLevel), sale.infinite, sale.expiry)
                                    }
                                </div> 
                                <div onClick={() => galleryOpen(imageUrl)} className="grid-row-img" style={{backgroundImage: `url("${imageUrl}")`}}></div>
                                <div className='moActions'>
                                    {
                                        userRole(user.AccessLevel) === 'Global Admin' && <i className={sale.infinite ? 'ti-infinite active' : 'ti-infinite'} title='Does Not Expire' onClick={() => setInfinite(sale, sale.infinite ? false : true, 'sale')}></i>
                                    }
                                    {/* <i className='ti-bar-chart' title='Statistics'></i> */}
                                    <i className='ti-trash' title='Delete this Memento' onClick={() => deletePost('', sale)}></i>
                                </div>
                                <span>ID: {saleId.substr(saleId.length - 6)}</span>
                            </div>
                        )
                    })
                }
                {
                    !props.mementoType && 
                    memos.sort((a, b) => ASCsort ? (a.memobjectExpiry < b.memobjectExpiry) ? 1 : -1 : (a.memobjectExpiry > b.memobjectExpiry) ? 1 : -1).map((post) => {
                        const postId = post._id?.toString();
                        const imageUrl = getImageUrl(post.mo && post.mo[0] && post.mo[0].Url);
                        return (
                            post.mo && post.mo[0] && <div className='memento' key={post._id}>
                                <div className='moExpiry'>
                                    {
                                        formatExpire(userRole(user.AccessLevel), post.memobjectInfinite, post.memobjectExpiry)
                                    }
                                </div> 
                                <div onClick={() => galleryOpen(imageUrl)} className="grid-row-img" style={{backgroundImage: `url("${imageUrl}")`}}></div>
                                <div className='moActions'>
                                    {
                                        userRole(user.AccessLevel) === 'Global Admin' && <i className={post.memobjectInfinite ? 'ti-infinite active' : 'ti-infinite'} title='Does Not Expire' onClick={() => setInfinite(post, post.memobjectInfinite ? false : true, 'post')}></i>
                                    }
                                    {/* <i className='ti-bar-chart' title='Statistics'></i> */}
                                    <i className='ti-trash' title='Delete Post' onClick={() => deletePost(post._id)}></i>
                                </div>
                                <span>ID: {postId.substr(postId.length - 6)}</span>
                            </div>
                        )
                    })
                }
            </div>
        }   
        {
            memos && !Object.keys(memos).length && <h3><Translate>There are no posted Mementos yet.</Translate></h3>
        }
      </div>
    </div>
  );
}
