import React, { useRef } from 'react';
import { Form, Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import DateField from '../../Components/Form/DateField';
import TextArea from '../../Components/Form/TextArea';
import RadioGroup from '../../Components/Form/RadioGroup';
import CheckBox from '../../Components/Form/CheckBox';
import { required } from '../../Util/form';
import { useTranslation } from "react-i18next";
import { usePopupContext } from '../../Contexts/popupContext';
import LoadingButton from '../../Components/LoadingButton';
import { request } from '../../Services/networkService';
import CityStateCountry from '../../Components/Form/CityStateCountry';
import moment from 'moment';
import ChangeEmail from './ChangeEmail';

export default function PersonalInfo({ user, update, readOnly = false }) {
  const { handleError } = usePopupContext();
  const { t } = useTranslation('common');
  const modalRef = useRef();

  const onSubmit = data => {
    return request('user.update', data)
      .then(r => update({ ...user, ...r }))
      .catch(handleError);
  };

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-info-alt" /> {t('profile.basicInfo')}</h5>

        <Form
          onSubmit={onSubmit}
          initialValues={{ ...user, DOB: moment(user.DOB).format('MM/DD/YYYY') }}
          render={({ handleSubmit, submitting, values, pristine }) => {
            return (
              <form onSubmit={handleSubmit}>

                <Field component={RadioGroup}
                  name="Gender"
                  options={[{ name: t('honoree.mr'), value: 'Male' }, { name: t('honoree.mrs'), value: 'Female' }]}
                  validate={required}
                  required
                  readOnly={readOnly}
                />

                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name="First_Name"
                      label={t('fields.firstName')}
                      validate={required}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="Last_Name"
                      label={t('fields.lastName')}
                      validate={required}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                  {
                    !readOnly &&
                    <div className="col" style={{ maxWidth: '150px' }}>
                      <Field component={CheckBox}
                        name="AccountSetting.NamesPublic"
                        type="checkbox"
                        label="Info is public"
                      />
                    </div>
                  }
                </div>

                <div className="form-row">
                  {/* <div className="col">
                    <Field component={TextField}
                      name="Email"
                      label={t('fields.emailAddress')}
                      type="email"
                      validate={required}
                      required
                      readOnly={readOnly}
                    />
                  </div> */}
                  <div className='col'>
                    <Field component={TextField}
                      name="DisplayName"
                      label={t('fields.displayName')}
                      readOnly={readOnly}

                      validate={!values.AccountSetting.NamesPublic && required}
                      required={!values.AccountSetting.NamesPublic}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="Mobile_No"
                      label={t('fields.mobile')}
                      validate={required}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                  {
                    !readOnly &&
                    <div className="col" style={{ maxWidth: '150px' }}>
                      <Field component={CheckBox}
                        name="AccountSetting.MobileAndEmailPublic"
                        type="checkbox"
                        label="Info is public"
                      />
                    </div>
                  }
                </div>

                <div className="form-row">
                  <div className="col">
                    <Field component={DateField}
                      name="DOB"
                      label={t('fields.dob')}
                      type="text"
                      validate={required}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="Religion"
                      label={t('fields.religion')}
                      readOnly={readOnly}
                    />
                  </div>
                  {
                    !readOnly &&
                    <div className="col" style={{ maxWidth: '150px' }}>
                      <Field component={CheckBox}
                        name="AccountSetting.BirthAndReligionPublic"
                        type="checkbox"
                        label="Info is public"
                      />
                    </div>
                  }
                </div>

                <CityStateCountry
                  values={values}
                  readOnly={readOnly}
                />

                <Field component={TextArea}
                  name="About"
                  label={t('profile.aboutLabel')}
                  readOnly={readOnly}
                />

                {
                  !readOnly && <div className="submit-btns text-center">
                    <LoadingButton iconCls="ti-save" loading={submitting} disabled={pristine} title={t('profile.updateBtn')} />
                    <LoadingButton iconCls="ti-pencil-alt" onClick={() => modalRef.current?.showModal()} title='Change Email' />
                  </div>
                }

              </form>
            )
          }}
        />
        <ChangeEmail user={user} update={update} changeEmailRef={modalRef} initialValues={{currentEmail: user.Email}}/>
      </div>
    </div>
  );
}