import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../Services/networkService';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { usePopupContext } from '../../Contexts/popupContext';
import { getName } from '../../Util/common';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const {t} = useTranslation('common');
  const history = useHistory();
  const { handleError } = usePopupContext();

  useEffect(() => {
    request('user.notifications').then(setNotifications)
  }, []);

  const clearNotification = id => {
    request('user.notificationsClear', {id})
      .then(() => request('user.notifications'))
      .then(setNotifications)
      .catch(handleError);
  };

  const receiveNotification = n => {
    request('user.notificationsReceive', {id: n._id})
      .then(() => request('user.notifications'))
      .then(setNotifications)
      .then(() => n.externalModelType === 'Group' && history.push({pathname: '/requests'}))
      .catch(handleError);
  };

  const activeNotifications = notifications.length ? notifications.filter(o => o.Received === false).length : 0;

  const getNotificationText = n => {
    if (n.externalModelType === 'Group') {  //notifications about groups
      switch(n.Status) {
        case 'Denied': return `${t('notifications.membership')} ${n.About.Name} ${t('notifications.rejected')}`;
        case 'Accepted': return `${t('notifications.membership')} ${n.About.Name} ${t('notifications.accepted')}`;
        case 'Blacklisted': return `${t('notifications.membership')} ${n.About.Name} ${t('notifications.rejected')}`;
        case 'Moderator': return `${t('notifications.membership')} ${n.About.Name} ${t('notifications.mod')}`;
        default: return `${getName(n.From)} ${t('notifications.join')} ${n.About.Name}`;
      }
    } else if (n.externalModelType === 'Token') {
      switch(n.Status) {
        default: return 'You have been issued a token, please check your email';
      }
    } else if (n.externalModelType === 'Posts') {
      switch(n.Status) {
        default: return 'A memento that you posted has been deleted by a site admin. Please add a different memento to commemorate your loved one.';
      }
    } else if (n.externalModelType === 'Event') {
      switch(n.Status) {
        default: return 'There is a scheduled event for tomorrow.';
      }
    }
  };

  return (
    <Fragment>
      <Link
        to="#"
        title={<Translate>Notifications</Translate>}
        data-ripple
        className={notifications.length ? 'active' : ''}
      >
        <i className="ti-bell" />
        {!!activeNotifications && <span>{activeNotifications}</span>}
      </Link>
      <ul className="notifications-dropdown">
        {/* {
          notifications.length ?
          notifications.map((n,i) => 
            (<li key={i} onClick={() => receiveNotification(n)}>
              <span className="notification" style={{...(!n.Received && {fontWeight: 'bold'})}} >{getNotificationText(n)}</span>
              <i className="fa fa-times pull-right" onClick={() => clearNotification(n._id)} />
            </li>))
          :
          <li><span className="notification">{t('notifications.no-new-notif')}</span></li>
        } */}
        {
          notifications.length ?
          notifications.map((n,i) => 
            (
            n.externalModelType === 'Group' ?
              typeof n.About !== 'undefined' && typeof n.About.Name !== 'undefined' ?
              <li key={i} onClick={() => receiveNotification(n)}>
                <span className="notification" style={{...(!n.Received && {fontWeight: 'bold'})}} >{getNotificationText(n)}</span>
                <i className="fa fa-times pull-right" onClick={() => clearNotification(n._id)} />
              </li>
              :null
            :
            <li key={i} onClick={() => receiveNotification(n)}>
              <span className="notification" style={{...(!n.Received && {fontWeight: 'bold'})}} >{getNotificationText(n)}</span>
              <i className="fa fa-times pull-right" onClick={() => clearNotification(n._id)} />
            </li>
            ))
          :
          <li><span className="notification"><Translate>No new notifications</Translate></span></li>
        }
      </ul>
    </Fragment>
  )
};
