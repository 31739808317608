import React from "react"
import Layout from "../../Components/Layout"
import { Helmet } from "react-helmet"
import '../../assets/css/thank-you_page.css'
import { usePopupContext } from "../../Contexts/popupContext";
import { Translate } from "react-auto-translate/lib/commonjs";

export default function ThankYou() {
    const { popupFeedbackOpen } = usePopupContext();

    const openFeedback = () => {
        popupFeedbackOpen();
    }

    return (
        <Layout showHeader={false} infoPage>
            <Helmet>
                <title>Thank you | Memkeepers</title>
            </Helmet>
            <div className="thank-you container">
                <div className="wrapper">
                    <section className="section appreciation">
                        <h1><Translate>Thank You!</Translate></h1>
                        <h4><Translate>We appreciate your business on </Translate><span className="brand-name">Memkeepers.me</span></h4>
                    </section>

                    <section className="section confirmation">
                        <h4><Translate>Your transaction has been successfully completed.</Translate></h4>
                    </section>

                    <section className="section next-steps mt-4">
                        <h2><Translate>What's Next?</Translate></h2>
                        <span className="step"><Translate>1. Check your email for a transaction receipt.</Translate></span>
                        <span className="step"><Translate>2. Complete any remaining steps in your account.</Translate></span>
                        <span className="step"><Translate>3. Explore our features and services.</Translate></span>
                    </section>

                    <section className="section feedBack">
                        <h2><Translate>We Value Your Feedback</Translate></h2>
                        <div className='feedBackButton' onClick={() => openFeedback()}><i className='ti-comment-alt'/> <Translate>Feedback Survey</Translate></div>
                    </section>
                </div>
            </div>
        </Layout>
    )
}