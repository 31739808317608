import React, { Fragment, useEffect, useState, useCallback } from 'react';
import Slider from "react-slick";
import { usePopupContext } from '../../Contexts/popupContext';
import MoSelect from './memobjectSelect';
import { request } from '../../Services/networkService';
import { useUserContext } from '../../Contexts/userContext';
import PostBox from '../../Components/PostBox';
import { Button, Modal } from 'react-bootstrap';
import { getImageUrl, getName } from '../../Util/common';
import { useTranslation } from "react-i18next";

export default function Memobjects({honoree, refreshPosts, setRefreshMemobjects}) {
  const { popupOpen, popupMessageOpen, popupPaymentOpen, handleError, popupConfirmOpen, popupClose } = usePopupContext();
  const gId = honoree && honoree.OwnGroup;
  const [mos, setMos] = useState([]);
  const { user, targetUser, products } = useUserContext();
  const targetGroup = targetUser && targetUser.OwnGroup;
  const [groupStatus, setGroupStatus] = useState();
  const [openedPost, setOpenedPost] = useState();
  const [openedPostId, setOpenedPostId] = useState();
  const {t} = useTranslation('common');

  const memobj = products.find(o => o.name === 'Memento');

  useEffect(()=>{
    targetGroup && request(`group.amIPartOf`, {groupId: targetGroup}).then(setGroupStatus)
  }, [targetGroup]);

  const enablePosting = groupStatus === true;

  const loadMos = useCallback(() => {
    gId && request('group.posts', {id: gId, type: 'memobject'})

      .then(({data}) => setMos(data));

    //   .then(({data}) => setMos(data.reduce((acc,o) => {
    //   if (o === undefined || !acc.find(m => m && m.memobject._id === o.memobject._id )) {
    //     return [...acc, o];
    //   }
    //   return acc;
    // }, [])));

  }, [gId]);

  useEffect(() => {
    if (setRefreshMemobjects) {
      setRefreshMemobjects(() => loadMos);
    }
  }, [setRefreshMemobjects, loadMos]);

  useEffect(()=>{
    loadMos();
  }, [loadMos, gId]);

  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    arrows: false,

    responsive: [{
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }]
  }

  const deleteMo = (postId, e) => {
    e.stopPropagation();
    popupConfirmOpen(t('memobjects.confirm'), t('memobjects.delete'))
    .then(
      () => request(`group.post.delete`, {id: postId})
      .then(() => {
        loadMos();
        refreshPosts && refreshPosts();
      })
      .catch(handleError)
    ).catch(()=>{})
  };

  const popupMemobjectsOpen = () => {
    const popupOptions = {
      title: t('memobjects.choose'),
      closeReject: true,
      content: <MoSelect />
    }
    if (enablePosting) {
      return popupOpen(popupOptions)
      .then(mo => {
        if (mo.premium) {
          return popupPaymentOpen({memobject: mo, itemName: 'Premium Memento', showMaintanence: true, multipleMemo: true})
        }
        return mo;
      })
      .then(mo => {
        if(typeof mo._id === 'undefined') {
          for(let i=0; i<Object.keys(mo).length; i++) {
            if(typeof mo[i] !== 'undefined') {
              mo && request('group.post.memobject', {id: gId, token: mo.token, paypalId: mo.paypalId, memobjectId: mo[i]._id, paymentIntentId: mo.paymentIntentId, autoRenew:mo.autoRenew, premium: mo.premium, productId: mo.productId || memobj._id})
              .then(() => {
                loadMos();
                refreshPosts && refreshPosts();
              })
              .catch(handleError);
            }
          }
        } else {
          mo && request('group.post.memobject', {id: gId, token: mo.token, paypalId: mo.paypalId, memobjectId: mo._id, paymentIntentId: mo.paymentIntentId, autoRenew:mo.autoRenew, premium: mo.premium, productId: mo.productId || memobj._id})
          .then(() => {
            loadMos();
            refreshPosts && refreshPosts();
          })
          .catch(handleError);
        }
      })
      .catch(e => e && handleError(e));
    } else {
      popupMessageOpen(t('popupcontext.msg'), t('memobjects.not-linked'));
    }
  };

  const popupPostOpen = (postId) => {
    setOpenedPostId(postId);
    request(`group.post.get`, {id: gId, postId: postId}).then(setOpenedPost);
  };

  useEffect(() => {
    if (openedPost) {
      const popupOptions = {
        title: t('memobjects.post-details'),
        closeReject: false,
        content:
          <Fragment>
            <Modal.Body>
              <PostBox
                postData={openedPost}
                apiId={gId}
                refreshPost={() => popupPostOpen(openedPostId)}
                noDelete
              />
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" onClick={popupClose}>{t('memobjects.btn-close')}</Button>
            </Modal.Footer>
          </Fragment>
      }
      popupOpen(popupOptions); 
    }
  }, [openedPost, gId]);


  const placeholders = 3 - mos.length;
  const memObjects = (mos.length >= 3 ? mos : [...(new Array(placeholders)), ...mos]);

  const emptyImageUrl = `${process.env.PUBLIC_URL}/images/leaf.png`;
  const scrollUrl = `${process.env.PUBLIC_URL}/images/scroll.png`;

  return (
    <div className="memobjects" title="Here you post graphical mementos like flowers, candles, balloons, etc., just like you would at the physical grave site">
      <div className="mo-add" onClick={popupMemobjectsOpen}><i className="fa fa-plus-circle" />{t('memobjects.add')}</div>
      <Slider {...sliderSettings}>
        {
          memObjects.map((mo, i) => {
            if (mo) {
              const imageUrl = mo.memobject ? getImageUrl(mo.memobject.Url) : (mo.uploads ? (mo.uploads[0].uploadType === 'image' ? getImageUrl(mo.uploads[0].url) : scrollUrl) : emptyImageUrl);
              return (
                <div key={i} >
                  <div className="memobject" style={{backgroundImage: `url("${imageUrl}")`}} onClick={() => popupPostOpen(mo._id)} >
                    {
                      mo.postedBy._id === user._id &&
                      <i className="postedby modelete fa fa-trash" title= {t('memobjects.dlt-memobject')} onClick={(e) => deleteMo(mo._id, e)} />
                    }
                    { mo.memobjectPremium && <div className="premium">Premium</div> }
                    <div className="postedby">{getName(mo.postedBy)}</div>
                  </div>
                </div>
              )
            } else {
              
              return (
                <div key={i}>
                  {
                    <div className="memobject memobject-invisible" onClick={popupMemobjectsOpen} style={{backgroundImage: `url("${emptyImageUrl}")`}}></div>
                  }
                </div>
              )
            }
          })
        }

      {
        memObjects.length <= 3 && 
        <div key="mo-last">
          <div className="memobject memobject-invisible" onClick={popupMemobjectsOpen} style={{backgroundImage: `url("${emptyImageUrl}")`}}></div>
        </div>
      }

      </Slider>
    </div>
  );

};
