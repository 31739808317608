import React, { useEffect, useRef } from 'react';

export default function Chip({ input, meta, label, options, ...others }) {
  const style = ((meta.error || meta.submitError) && meta.touched) ? { 'color': 'red' } : {};
  const ref = useRef(null);

  useEffect(()=>{
    ref.current && ref.current.setCustomValidity((meta.error && meta.touched) ? meta.error : '')
  });

  const match = others.readOnly && options.find(o => o.value === input.value);
  const text = match ? match.name : '';

  return (
    <div className="form-radio">
      { 
        others.readOnly ? 
          <div>{text}</div>
        :
          options.map(o => (
            <div key={o.value} className="radio">
              <label>
                <input 
                  type="radio" 
                  name="radio" 
                  value={o.value} 
                  checked={input.value === o.value}
                  onChange={() => input.onChange(o.value)}
                />
                <i className="check-box" style={style} />
                {o.name}
              </label>
            </div>
        ))
      }
      <input
        ref={ref}
        tabIndex={-1}
        autoComplete="off"
        style={{ width: "auto", padding: 0, opacity: 0, height: 0 }}
        defaultValue={input.value}
      />
    </div>
  )
};