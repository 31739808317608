import React from 'react';
import { usePopupContext } from '../../Contexts/popupContext';
import LoadingButton from '../../Components/LoadingButton';
import { request } from '../../Services/networkService';
import { useUserContext } from '../../Contexts/userContext';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function DeleteProfile(){
  const {t} = useTranslation('common');
  const { handleError, popupConfirmOpen } = usePopupContext();
  const { setUser } = useUserContext();
  const history = useHistory();

  const doDelete = () => {
    popupConfirmOpen(t('profile.confirm'), t('profile.confirmDelete'))
    .then(
      () => request(`user.deleteProfile`)
      .then(() => {
        setUser({});
        history.push({pathname: '/'})
      })
      .catch(handleError)
    ).catch(()=>{})
  };

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-trash" /> {t('profile.deleteProfile')}</h5>
        <h6>{t('profile.deleteTxt')}</h6>
        <div className="submit-btns text-center">
          <LoadingButton iconCls="fa fa-trash" className={"btn-red"} title={t('profile.profileDeleteBtn')} onClick={doDelete} />
        </div>
      </div>
    </div>
  );
}
