import React, { Fragment, useEffect, useState } from 'react';
import TopBar from '../../Components/TopBar';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import SideBarLeft from '../../Components/SideBarLeft';
import SideBarRight from '../../Components/SideBarRight';
import FeedLeft from '../../Components/FeedLeft';
//import WidgetPanel from '../../Components/WidgetPanel';
import { useUserContext } from '../../Contexts/userContext';
import { findLanguage } from '../../Util/common';
import { CONFIG } from '../../Config';
import { Translator } from 'react-auto-translate/lib/commonjs';
import { Helmet } from 'react-helmet';
//import Adsense from '../../Components/Adsense';

export default function Layout({ showHeader = true, children, infoPage, config, title, tooltip, extraPanels, ...headerProps }) {
  const { userLoggedIn, user } = useUserContext();
  const header = showHeader && userLoggedIn();
  const [lng, setLng] = useState('en');
  const pathName = window.location.pathname

  useEffect(() => {
    let language = user.Language;
    if(language === 'enus') setLng('en');
    else setLng(findLanguage(language))
  }, [user.Language])

  return (
    <Fragment> 
      <Helmet><link rel="canonical" href={`https://www.memkeepers.me${pathName}`} /></Helmet>
      <Translator
        from='en'
        to={lng}
        googleApiKey={CONFIG.GOOGLE_TRANSLATE_KEY}
      >
      <TopBar />
      <div className="feed-left">
        {config && <FeedLeft config={config} title={title} tooltip={tooltip} extraPanels={extraPanels} />}
      </div>

      <div className="theme-layout marble">
        <div className={`container ${infoPage ? "gray-bg" : ""}`}>
            {header && <Header {...headerProps} />}
          <div className={`container-fluid ${!infoPage ? "gray-bg" : ""}`}>
            <div className="row">
              <div className="col-lg-12">
                <div className={`row ${header ? "" : "page-contents"}`} id="page-contents">
                  {config ? <SideBarLeft config={config} title="title" /> : children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>

      <div className="feed-right">
        <SideBarRight />
      </div>
      </Translator>
    </Fragment>
  );
};