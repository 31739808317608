import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout';
import { request } from '../../Services/networkService';
import { useUserContext } from '../../Contexts/userContext';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';

import All from '../Groups/all';
import Friends from '../Groups/friends';
import Honorees from '../Groups/honorees';
import Pages from '../Groups/pages';

export default function Groups() {
  const { getAvatar, getObjectAvatar } = useUserContext();
  const [friends, setFriends] = useState([]);
  const [honorees, setHonorees] = useState([]);
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const { handleError } = usePopupContext();
  const {t} = useTranslation('common');
  const { targetUser, setTargetUser } = useUserContext();
  const { id } = useParams();

  const refreshUser = () => id && request('user.get', {id: id}).then(setTargetUser);

  useEffect(()=>{
    refreshUser();
  }, [id]);
  
  useEffect(() => {
    setLoading(true);
    id && request('user.groups', {id})
    .then(data => {
      setFriends(data.filter(o => o.GroupType === 'Register' && o.RelatedTo && o.RelatedTo._id !== id).map(o => ({
        text: o.Name,
        image: getAvatar(o.RelatedTo.ProfileImage || null),
        link: '/usertimeline/' + o.RelatedTo._id
      })));

      setHonorees(data.filter(o => o.GroupType === 'Honoree' && o.RelatedTo).map(o => ({
        text: o.Name,
        image: getObjectAvatar(o.RelatedTo),
        link: '/honoreetimeline/' + o.RelatedTo._id
      })));

      setPages(data.filter(o => o.GroupType === 'Page' && o.RelatedTo).map(o => ({
        text: o.Name,
        image: getAvatar(o.RelatedTo.ProfileImage || null, true),
        link: '/userpage/' + o.RelatedTo._id
      })));
    })
    .catch(handleError)
    .finally(() => setLoading(false));
  }, [id]);

  const menuConfig = [
    {
      title: t('groups.allGroups'),
      contents: All,
      iconCls: 'fa fa-users',
      path: '',
      params: {friends, honorees, pages, loading}
    },{
      title: t('groups.friends'),
      contents: Friends,
      iconCls: 'fa fa-users',
      path: 'friends',
      params: {friends, hideInvite: true}
    },{
      title: t('groups.honorees'),
      contents: Honorees,
      iconCls: 'fa fa-users',
      path: 'honorees',
      params: {honorees}
    },{
      title: t('groups.pages'),
      contents: Pages,
      iconCls: 'fa fa-users',
      path: 'pages',
      params: {pages}
    }
  ];

  return(
    <Layout asUser={targetUser} refresh={refreshUser} config={menuConfig} title={t('groups.groupsTitle')} />
  );
};
