import React, { Fragment } from 'react';
import Carousel from '../../Components/Carousel';
import { useTranslation } from "react-i18next";

export default function AllGroups({friends, honorees, pages, loading}){
  const {t} = useTranslation('common');
  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-users" /> {t('groups.allGroups')}</h5>
        {
          !loading &&
          <Fragment>
            <Carousel data={friends} title={t('groups.friends')} />
            <Carousel data={honorees} title={t('groups.honorees')} />
            <Carousel data={pages} title={t('groups.pages')} />
          </Fragment>
        }
        {
          loading &&
            <center>
              <i className="loading" />
            </center>
        }
      </div>
    </div>
  );
}
