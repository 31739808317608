import React, { Fragment } from 'react';
import Slider from "react-slick";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function Carousel({data, title}){
  const history = useHistory();
  const {t} = useTranslation('common');

  const SampleNextArrow = props => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      > <i className="fa fa-chevron-right"/>
      </div>
    );
  }

  const SamplePrevArrow = props => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      > <i className="fa fa-chevron-left"/>
      </div>
    );
  }

  const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 2000,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [{
      breakpoint: 480,
      settings: {slidesToShow: 2}
    },{
      breakpoint: 763,
      settings: {slidesToShow: 3}
    },{
      breakpoint: 992,
      settings: {slidesToShow: 4}
    },{
      breakpoint: 1200,
      settings: {slidesToShow: 5}
    }]
  };

  return (
    <Fragment>
      <div className="carousel-title">{title}</div>
      {
        !!data.length &&
        <Slider {...sliderSettings} className="slick-photo-slider">
          {
            data.map((g, i) => {
              return (
                <div key={i} >
                  <div
                    title={g.text}
                    className="slick-photo"
                    style={{backgroundImage: `url("${g.image}")`}}
                    onClick={()=> g.link && history.push({pathname: g.link})}
                  />
                  <div className="slick-text">
                    {g.text}
                  </div>
                </div>
              )
            })
          }
        </Slider>
      }
      {
        !data.length && <div>{t('carousel.notInGroup')}</div>
      }
    </Fragment>
  );
};
