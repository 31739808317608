import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import Welcome from '../../Components/Welcome';
import TextField from '../../Components/Form/TextField';
import { composeValidators, required, minLength, validatePassword } from '../../Util/form';
import { passwordResetToken } from '../../Services/userService';
import { useHistory } from 'react-router-dom';
import { usePopupContext } from '../../Contexts/popupContext';
import {useTranslation} from "react-i18next";
import PasswordStrength from '../../Components/PasswordStrength';

export default function ForgotPassword() {
  const history = useHistory();
  const { popupMessageOpen, handleError } = usePopupContext();
  const [user, setUser] = useState();
  const {t} = useTranslation('common');

  const { id } = useParams();

  useEffect(() => {
    id && passwordResetToken(id)
      .then(({data}) => setUser(data.Email))
      .catch(e => handleError(e).then(() => history.push({pathname: '/'})));
  }, [id]);

  const onSubmit = data => {
    if (data.Password !== data.Confirm_Password){
      popupMessageOpen(t('passwordreset.error'), t('passwordreset.not-match'));
    } else {
      passwordResetToken(id, data.Password)
        .then(() => popupMessageOpen(t('passwordreset.success'), t('passwordreset.change-success')))
        .catch(handleError)
        .finally(() => history.push({pathname: '/'}))
    }
  };

  const renderForm = (
    <Fragment>
      <h2 className="log-title">{t('passwordreset.pass-reset')}</h2>
      <p>{t('passwordreset.new-pass')} {user}</p>

      <Form
        onSubmit={onSubmit}
        initialValues={{remember: true}}
        render={({ values, handleSubmit, submitError, submitErrors, submitting}) => {
          return (
            <form onSubmit={handleSubmit}>

                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                           name="Password"
                           label={t('fields.Password')}
                           type="password"
                           validate={composeValidators(required, minLength(8), validatePassword)}
                           children={<PasswordStrength password={values.Password} />}
                           required
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                           name="Confirm_Password"
                           label={t('fields.confirm')}
                           type="password"
                           validate={required}
                           required
                    />
                  </div>
                </div>

              <div className="submit-btns text-center">
                <button className="mtr-btn btn-round" type="submit"><span>{t('passwordreset.submit')}</span></button>
                <Link to="/" className="mtr-btn btn-round forgot"><span>{t('passwordreset.login-go')}</span></Link>
              </div>
            </form>
        )}}
      />
    </Fragment>
  );

  return(
    <Welcome>
      <div className="log-reg-area sign">
        {
          user ? renderForm : <h2 className="log-title">{t('passwordreset.load')}</h2>
        }
      </div>
    </Welcome>
  );
}