import React, { useState, useEffect, useRef, useMemo, forwardRef, useImperativeHandle } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';
import Pagination from './Pagination';
import ActionIcon from './actionIcon';
import { useTranslation } from "react-i18next";

const ReactTableFilters = forwardRef(function({ customButtons, customMessage, disableFilters, openAddPopup, customAddAction, totalRows, selectRow, forceColumnRefresh=false, columnRefreshTrigger, ...others }, ref) {
  const [showFilters, setShowFilters] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [customFilterTrigger, setCustomFilterTrigger] = useState(null);
  const tableEl = useRef();
  const rootEl = useRef();
  const {t} = useTranslation('common');

  const getProperty = (prop, data) =>  {
    return typeof prop === "function" ? prop(data) : prop;
  };

  const customRowSelect = index => {
    setSelectedRow(index);
    selectRow && selectRow(index);
    others.onRowClick && others.onRowClick(tableEl.current.state.data[index], index);
  };

  const setShowFiltersState = (show) => {
    setShowFilters(show);
  }

  useImperativeHandle(ref, () => ({
    refresh(){
      tableEl.current.state.page = 0;
      tableEl.current.fireFetchData();
    },
    selectRow(index){
      customRowSelect(index);
    },
    filterBy(index, filter){
      setShowFiltersState(true);
      setCustomFilterTrigger([{id: columns[index].accessor, value: filter}]);
    },
    filterByProp(prop, filter){
      setShowFiltersState(true);
      setCustomFilterTrigger([{id: prop, value: filter}]);
    },
    refreshRow(index){
      others.onRowClick && others.onRowClick(tableEl.current.state.data[index], index, true);
    },
    refreshSelectedRow(){
      others.onRowClick && others.onRowClick(tableEl.current.state.data[selectedRow], selectedRow, true);
    },
    getSelectedIndex(){
      return selectedRow;
    },
    scrollTop(){
      if (rootEl.current && rootEl.current.querySelector('.rt-tbody')) {
        rootEl.current.querySelector('.rt-tbody').scrollTop = 0;
      }
    }
  }));

  useEffect(() => {
    if (customFilterTrigger != null) {
      tableEl.current.state.filtered = customFilterTrigger;
      tableEl.current.fireFetchData();
    }
  }, [customFilterTrigger]);

  useEffect(() => {
    if (customFilterTrigger != null) {
      totalRows > 0 && customRowSelect(0);
      setCustomFilterTrigger(null);
    }
  }, [others.data]);

  useEffect(() => {
    setTotal(others.manual ? (totalRows ? totalRows : 0) : tableEl.current.getResolvedState().sortedData.length);
    //selectedRow === null && selectRow && others.data.length && customRowSelect(0);
  }, [others.data.length, totalRows, filtered, others.manual]);

  useEffect(()=>{
    //setting padding on the header and footer to align columns to the scrollbar
    const body = rootEl.current.querySelector('.rt-tbody');
    const header = rootEl.current.querySelector('.rt-thead.-header');
    const headerFilters = rootEl.current.querySelector('.rt-thead.-filters');
    const footer = rootEl.current.querySelector('.rt-tfoot');
    const offset = header.clientWidth - body.clientWidth;
    header && (header.style.paddingRight = `${offset}px`);
    headerFilters && (headerFilters.style.paddingRight = `${offset}px`);
    footer && (footer.style.paddingRight = `${offset}px`);
  });

  const filterFn = (filter, row) => {
    if (filter.value == null) {
      return true;
    }
    if (filter.value && row[filter.id] && moment(new Date(filter.value)).isValid()) {
      return moment(new Date(filter.value)).isSame(moment(new Date(row[filter.id])), 'day');
    }
    return `${row[filter.id]}`.toLowerCase().includes(`${filter.value}`.toLowerCase());
  };

  const colTypes = col => {
    switch(col.type) {
      // case 'date': return {
      //   Cell: ({ value }) => formatDate(value),
      //   headerClassName: 'calendar-column',
      //   Filter: CalendarDropdown
      // }
      // case 'dateTime': return {
      //   Cell: ({ value }) => formatDateTime(value),
      //   headerClassName: 'calendar-column',
      //   Filter: CalendarDropdown
      // }
      // case 'dateRange': return {
      //   Cell: ({ value }) => formatDateTime(value),
      //   headerClassName: 'calendar-column',
      //   Filter: DateRangeDropdown
      // }
      // case 'sum': return {
      //   Cell: ({ value }) => formatCurrencyInGrid(value, false),
      //   className: 'align-right',
      //   Footer: () => (<div className="align-right">{formatCurrencyInGrid(others.extraData[col.propTotal], false)}</div>),
      //   width: 200
      // }
      default: return {}
    }
  };

  const processColumns = (cols, actions) => {
    const columns = cols.map(col => ({...colTypes(col), ...col}));

    if (actions) {

      const actionColumn = {
        header: '',
        className: 'action-column',
        resizable: false,
        sortable: false,
        accessor: '',
        width: actions.length * 32,
        Filter: () => null
      };

      actionColumn.Cell = (cell) => (
        actions.reduce((acc, action, index) => {
          const allowed = action.onlyInMenu ? false : (action.condition != null ? getProperty(action.condition, cell.value) : true);
          return allowed ? [...acc, <ActionIcon key={index} cell={cell} action={action} /> ] : acc;
        }, [])
      );

      return [...columns, actionColumn];
    } else {
      return columns;
    }
  }

  const colLength = others.columns && others.columns.length;
  const actLength =  others.actions && others.actions.length;
  const columns = forceColumnRefresh ?
    processColumns(others.columns, others.actions) :
    useMemo(() => processColumns(others.columns, others.actions), [colLength, actLength, columnRefreshTrigger]);

  const rowProperties = (state, rowInfo, column, instance) => {
    return rowInfo && rowInfo.row && others.onRowClick && typeof others.onRowClick === 'function' ? {
      onClick: (e, t) => {
        setSelectedRow(rowInfo.index);
        selectRow && selectRow(rowInfo.index);
        others.onRowClick(rowInfo.row._original, rowInfo.index);
      },
      className: others.rowHighlight && rowInfo.index === selectedRow ? 'rt-row-selected' : ''
    } : {}
  };

  return (
    <div className='react-table-filterable' ref={rootEl}>
      <div className='header-container-wrapper'>
        <table className='header-container'>
          <tbody>
            <tr>
              <td>
                {
                  disableFilters ||
                  <div className="filters-btn" onClick={()=> setShowFiltersState(!showFilters) }>
                    <div className={`fa icon ${showFilters ? 'fa-chevron-up' : 'fa-chevron-down'}`}></div>
                    { showFilters ? t('reacttable.filter-hide') : t('reacttable.filter-show')}
                  </div>
                }
              </td>
                { others.title && <td className="table-title"><div>{others.title}</div></td> }
              <td>
                { customButtons && <div className="custom-buttons">{customButtons}</div> }
              </td>
              <td className="add-btn">
                {
                  customAddAction ? customAddAction :
                  (
                    openAddPopup && <ActionIcon action={{
                      onClick: openAddPopup,
                      title: t('reacttable.add') ,
                      iconcls: "icon fa fa-plus-circle"
                    }} />
                  )
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ReactTable
        ref={tableEl}
        filterable={showFilters}
        defaultFilterMethod={filterFn}
        defaultPageSize={100}
        pageSizeOptions={[10, 25, 50, 75, 100]}
        previousText={t('reacttable.prev-text')}
        nextText={t('reacttable.next-text')}
        pageText={t('reacttable.page-text')}
        ofText={t('reacttable.of')}
        loadingText={t('reacttable.loading-text')}
        noDataText={t('reacttable.no-results')}
        rowsText=""
        onFilteredChange={() => setFiltered(!filtered)}
        PaginationComponent={props => <Pagination {...props} totalRows={total} customMessage={customMessage}/>}
        getTrProps={rowProperties}
        {...others}
        className={`text-center ${others.className ? others.className : ''}`}
        columns={columns}
      />
    </div>
  );
});

export default ReactTableFilters;
