import React, { useRef } from 'react';
import ReactTable from '../../Components/ReactTablePagination';
import { usePopupContext } from '../../Contexts/popupContext';
import { request } from '../../Services/networkService';
import { useTranslation } from "react-i18next";

export default function SavedCards(){
  const {t} = useTranslation('common');
  const { handleError, popupConfirmOpen } = usePopupContext();
  const tableRef = useRef();


  const capitalizeText = (value) => {
    const str = value.toString();
    const newStr = str.charAt(0).toUpperCase() + str.slice(1);

    return newStr;
  }

  const columns = [{
    Header: '',
    accessor: 'brand',
    sortable: false,
    width: 60,
    Filter: () => {},
    Cell: ({value}) => {
      if (value === 'visa') {
        return (<div style={{ textAlign: 'center' }}><i className='fa fa-cc-visa' style={{ color: '#04A5C0', fontSize: '18px' }}/></div>);
      } else if (value === 'mastercard') {
        return (<div style={{ textAlign: 'center' }}><i className='fa fa-cc-mastercard' style={{ color: '#04A5C0', fontSize: '18px' }}/></div>);
      } else if (value === 'amex') {
        return (<div style={{ textAlign: 'center' }}><i className='fa fa-cc-amex' style={{ color: '#04A5C0', fontSize: '18px' }}/></div>);
      } else {
        return (<div style={{ textAlign: 'center' }}><i className='fa fa-credit-card' style={{ color: '#04A5C0', fontSize: '18px' }}/></div>);
      }
    }
  },{
    Header: t('purchases.brand'),
    accessor: 'brand',
    sortable: false,
    Cell: ({value}) => capitalizeText(value),
    width: 160
  },{
    Header: t('purchases.last4'),
    accessor: 'last4',
    sortable: false,
    Cell: ({value}) => `**** **** **** ${value}`
  },{
    Header: t('purchases.cardExpires'),
    sortable: false,
    Cell: ({row}) => `${row._original.exp_month}/${row._original.exp_year}`,
    width: 160
  },{
    Header: t('purchases.cardType'),
    sortable: false,
    accessor: 'funding',
    Cell: ({value}) => capitalizeText(value)
  }];
  
  const actions = [
    {
      iconcls: 'fa fa-trash',
      onClick: (row) => popupConfirmOpen(t('purchases.confirm'), t('purchases.deleteCard'))
        .then(
          () => request('payment.deletePM', {pmID: row.id})
          .then(res => tableRef.current.refresh())
        ).catch(()=>{}),
      title: "Delete saved card",
    },
  ];

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-object-group" /> {t('purchases.savedCards')}</h5>

        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'payment.getMethods'}
          apiParam={true}
          disableFilters
          onError={handleError}
        />

      </div>
    </div>
  );
}