import React, { useState, useEffect, useRef } from 'react';

export default function ActionIcon({ cell, action }) {
  const [loading, setLoading] = useState(false);
  const componentIsMounted = useRef(true);

  const getProperty = (prop, data) =>  {
    return typeof prop === "function" ? prop(data) : prop;
  };

  const disabled = getProperty(action.disabled, cell && cell.value) || loading;

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    }
  }, []);

  return (
    <div 
     className={`icon grid-icon ${loading ? 'icon-loader' : getProperty(action.iconcls, cell && cell.value)}`}
     title={getProperty(action.title, cell && cell.value)}
     disabled={disabled}
     style={{...(!disabled && {cursor: 'pointer'})}}
     onClick={e => {
      if (!disabled) {
        e.preventDefault(); 
        e.stopPropagation();
        if (action.onClick) {
          setLoading(true);
          Promise.resolve(action.onClick(cell && cell.value, cell))
            .then(() => componentIsMounted.current && setLoading(false))
            .catch(() => componentIsMounted.current && setLoading(false));
        }
      }
     }}
    ></div>
  )
};