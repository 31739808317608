import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../Contexts/userContext';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Help() {
  const { user, setUser } = useUserContext();
  const history = useHistory();

  const startTour = () => {
    setUser({...user, TourCompleted: false});
    history.push('/timeline');
  };

  return (
    <Fragment>
      <Link to="#" title="Help" className="active">
        <i className="fa fa-question-circle-o" />
      </Link>
      <ul className="help-dropdown">

        <li>
          <Link className="notification" title="How To document" to="/howitworks">
            <Translate>How It Works page</Translate>
          </Link>
        </li>

        <li>
          <span className="notification" title="Guided Tour" onClick={startTour}>
            <Translate>Guided Tour</Translate>
          </span>
        </li>
        
      </ul>
    </Fragment>
  )
};
