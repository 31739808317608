import React, { useEffect, useState } from 'react';
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import useScript from '../../hooks/useScript';

export default function CookiePolicy() {
  const [policyLink, setPolicyLink] = useState('');
  const { handleError, popupMessageOpen } = usePopupContext();

  const getDocument = () => {
    request('public.listPolicy', {type: 'Cookies Policy'})
    .then(res => {
      if(Object.keys(res).length) {
        setPolicyLink(res.policyLink);
      } else {
        popupMessageOpen('Message', 'Missing Document!');
      }
    })
    .catch(err => {
      handleError(err)
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getDocument();
  }, []);

  useScript('https://cdn.iubenda.com/iubenda.js');

  return(
    <div className="contct-info policies documents">
      <a href={`${policyLink && policyLink.length ? policyLink : 'https://www.iubenda.com/privacy-policy/56595294/cookie-policy'}`} class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Cookie Policy">Cookie Policy</a>
      {/* The hardcoded url is for the default policy in case the one from the DB doesn't load */}
    </div>
  );
}