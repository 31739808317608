import React from 'react';

export default function Loading({data, loading, message}){
  return (
    (!data || (data && !data.length)) &&
    <center>
      <p>
        {
          loading ? ( <i className="loading" /> ) : message
        }
      </p>
      <p> &nbsp; </p>
    </center>
  );
};