import React from 'react';
import debounce from 'debounce';
import { Field } from 'react-final-form';
import { required } from '../../Util/form';
import SelectAsync from '../../Components/Form/SelectAsync';
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import { Form } from 'react-final-form';
import { useUserContext } from '../../Contexts/userContext';

export default function Tag({postData, updatePost}) {
  const { handleError } = usePopupContext();
  const { user } = useUserContext();

  const loadUsers = (value, cb) => {
    request('common.search', {search: value, self: true, friends: true})
      .then(data => cb(data.users.map((d) => ({ name: `${d.First_Name} ${d.Last_Name} - ${d.Email}`, id: d.id, email: d.Email }))))
      .catch(handleError);
  };

  const submit = () => {};

  const onUserSelect = (usr, form) => {
    if (postData.tags.find(o => o.tagged._id === usr.id)) {
      setTimeout(() => form.reset(), 0);
    } else {
      request('group.post.update', 
        {tags: [...postData.tags, {tagged: usr.id, taggedBy: user._id}], _id: postData._id}
      )
      .then(o => {
        updatePost(o);
        setTimeout(() => form.reset(), 0);
      })
      .catch(handleError);
    }
  }

  return (
    <div className="post-tag-dropdown">
      <Form
        onSubmit={submit}
        render={({ handleSubmit, submitting, values, form}) => {
          return (
            <form onSubmit={handleSubmit}>
    
              <Field component={SelectAsync}
                 name="user"
                 validate={required}
                 required
                 isSearchable
                 isMulti={false}
                 loadOptions={debounce(loadUsers, 500)}
                 placeholder="Select a user to tag"
                 onChange={o => onUserSelect(o, form)}
                />
     
            </form>
        )}}
      />
    </div>
  )
};