import React, { Fragment, useEffect, useState } from 'react';
import { usePopupContext } from '../../Contexts/popupContext';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import SkeletonBlog from './skeletonBlog';
import { request } from '../../Services/networkService';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import TopBar from '../../Components/TopBar';
import Footer from '../../Components/Footer';


const BlogContent = () => {
    const [blogURL, setBlogURL] = useState('');
    const [data, setData] = useState();
    const params = useParams()
    const { handleError } = usePopupContext();

    useEffect(() => {
        if(params) setBlogURL(params.URL)
    }, [params])

    const modules = {
        toolbar: false
    }

    const listFeature = () => {
        request('public.listBlogURL', {url: blogURL})
        .then(res => setData(res))
        .catch((err) => handleError(err))
    }

    useEffect(() => {
        if(blogURL) listFeature();
    }, [blogURL])

    return (
        <Fragment>
            <TopBar />
                <div className="theme-layout marble">
                    <div className="welcome container">
                        <div className='row'>
                            <div className='features col-lg-12'>
                            {
                                typeof(data) !== 'undefined' && Object.keys(data).length ?
                                    <div className='content-container'>
                                        <Helmet>
                                            <meta name="keywords" content={data.meta.keywords} />
                                            <meta name="description" content={data.meta.description} />
                                            <title>{data.title}</title>
                                        </Helmet>
                                        <ReactQuill 
                                        theme="snow"
                                        value={data.text}
                                        readOnly
                                        modules={modules}
                                        />
                                    </div>
                                
                                :
                                <SkeletonBlog />
                            }
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </Fragment>
    );
}

export default BlogContent;
