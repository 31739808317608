import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Welcome from '../../Components/Welcome';
import { request } from '../../Services/networkService';
import { Redirect } from 'react-router-dom';
// import { useTranslation } from "react-i18next";
import { usePopupContext } from '../../Contexts/popupContext';
import { getTwoFAReminder } from '../../Util/storage';
import { useUserContext } from '../../Contexts/userContext';
import LoadingButton from '../../Components/LoadingButton';
import { CONFIG } from '../../Config';
import { useLocation } from 'react-router-dom';
import { userHasTwoFA } from './TwoFAActions';

import logoG from '../../assets/images/logo_google.svg';
import logoFb from '../../assets/images/logo_fb.svg';
import FacebookPosts from '../../Components/FacebookPosts';
import { useRef } from 'react';
import AuthModal from './AuthModal';
import { findLanguage } from '../../Util/common';
import { Translate, Translator } from 'react-auto-translate/lib/commonjs';
import { Helmet } from 'react-helmet';

export default function Login({ header }) {
  const { handleError, popupMessageOpen } = usePopupContext();
  // const { t } = useTranslation('common');
  const { user } = useUserContext();
  const [posts, setPosts] = useState([]);
  const hasError = new URLSearchParams(useLocation().search).get('error');
  const [authVariant, setAuthVariant] = useState(0);
  const modalRef = useRef();
  const [lng, setLng] = useState('en');

  useEffect(() => {
    hasError && popupMessageOpen('Error', 'Error registering with this account, probably already registered with the same username?')
  }, [hasError]);

  const openAuthModal = (authVar = 0) => {
    setAuthVariant(authVar);
    return modalRef.current?.showModal();
  }

  function getPosts() {
    request('public.listPosts')
      .then(res => setPosts(res))
      .catch(handleError)
  }

  useEffect(() => {
    getPosts();
  }, []);

  useEffect(() => {
    let language = user.Language;
    if (language === 'enus') setLng('en');
    else setLng(findLanguage(language))
  }, [user.Language])

  return (
    user.token && !userHasTwoFA(user) && getTwoFAReminder() ?
      <Redirect to={'/profile/twoFA'} />
      : user.token && !userHasTwoFA(user) && !getTwoFAReminder() ?
        <Redirect to={'/timeline'} />
        : user.token && userHasTwoFA(user) ?
          <Redirect to={'/timeline'} />
          : header ?
            <div className="login-form">
              <div className="submit-btns text-center">
                {/* <LoadingButton className='logBtn' title={t('login.LOGIN')} tooltip="Click here to login to your account" onClick={() => openAuthModal(0)} /> */}
                {/* <LoadingButton className='logBtn' title={t('login.join')} onClick={() => openAuthModal(1)} /> */}
                <LoadingButton className='logBtn' title={<Translate>LOGIN</Translate>} tooltip="Click here to login to your account" onClick={() => openAuthModal(0)} />
                <LoadingButton className='logBtn' title={<Translate>JOIN NOW</Translate>} onClick={() => openAuthModal(1)} />
              </div>
              <div className='login-reg-bg'>
                <AuthModal authRef={modalRef} authVariant={authVariant} />

              </div>
            </div>
            :
            <Translator
              from='en'
              to={lng}
              googleApiKey={CONFIG.GOOGLE_TRANSLATE_KEY}
            >
              <Helmet>
                <script type='application/ld+json'>
                {`{
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": [{
                    "@type": "Question",
                    "name": "How do I create an online memorial?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To create an online memorial, go to Memkeepers.me to get started with a lifetime free account. You can create an Honoree profile to keep the legacy of your loved ones and preserve their memories by sharing stories, experiences, photos and videos."
                    }
                  },{
                    "@type": "Question",
                    "name": "How can I remember someone online?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To preserve the memory of a loved one, you can make a virtual tribute by signing up on a memorial website. By creating a free memorial page dedicated to someone you’ve lost, you can keep their legacy for future generations and collect memories that will provide solace for your grief and relief to your healing."
                    }
                  },{
                    "@type": "Question",
                    "name": "What is the best way to remember someone?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "The best way to remember someone is to keep their journey, achievements, milestones, experiences, and stories alive in a place where you can go at any time, from any place - for example, an online memorial page. Paying a tribute and remembering your loved one in a long-lasting and meaningful way is the most honorable thing that you can do for them after they’ve passed away."
                    }
                  }]
                }`}
                </script>
              </Helmet>
              <Welcome>
                <div className="log-reg-area sign submit-btns">

                  <h2 className="log-title"><Translate>Login</Translate></h2>
                  <span>
                    <Translate>Not a member of MEMKEEPERS Yet?</Translate>
                    <Link><b><span onClick={() => openAuthModal(1)}>&nbsp;<Translate>JOIN NOW</Translate></span></b></Link><br />
                    <Translate>Membership is forever free for unlimited use</Translate>
                  </span>
                  <div className="submit-btns text-center">
                    <LoadingButton className='logBtn' title={<Translate>LOGIN</Translate>} tooltip="Click here to login to your account" onClick={() => openAuthModal(0)} />
                    &nbsp;
                    <span style={{ "color": "white" }}><b><Translate>OR</Translate></b></span>
                    <a className="btn btn-primary" href={`${CONFIG.BACKEND_URL}/auth/google`} role="button">
                      <img width="20px" height={20} style={{ marginBottom: "3px", marginRight: "5px" }} alt="Google sign-in" src={logoG} />
                      <span>Google</span>
                    </a>
                    <a className="btn btn-primary" href={`${CONFIG.BACKEND_URL}/auth/facebook`} role="button">
                      <img width="20px" height={20} style={{ marginBottom: "3px", marginRight: "5px" }} alt="Facebook sign-in" src={logoFb} />
                      <span>Facebook</span>
                    </a>
                  </div>
                  <FacebookPosts posts={posts} />
                </div>
                <AuthModal authRef={modalRef} authVariant={authVariant} />
              </Welcome>
            </Translator>
  );
}