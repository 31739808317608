import moment from 'moment';
import { CONFIG } from '../Config';
import { request, userGeo } from '../Services/networkService';
import 'moment/min/locales';
import flagEn from '../assets/images/countries/webp/flag-en.webp';
import flagBg from '../assets/images/countries/webp/flag-bg.webp';
import flagUs from '../assets/images/countries/webp/flag-us.webp';
import flagEs from '../assets/images/countries/webp/flag-es.webp';
import flagHn from '../assets/images/countries/webp/flag-hn.webp';
import flagRo from '../assets/images/countries/webp/flag-ro.webp';
import flagKo from '../assets/images/countries/webp/flag-ko.webp';
import flagRu from '../assets/images/countries/webp/flag-ru.webp';
import flagIt from '../assets/images/countries/webp/flag-it.webp';
import flagFr from '../assets/images/countries/webp/flag-fr.webp';
import flagDe from '../assets/images/countries/webp/flag-de.webp';
import flagGr from '../assets/images/countries/webp/flag-gr.webp';
import flagTr from '../assets/images/countries/webp/flag-tr.webp';
import flagJp from '../assets/images/countries/webp/flag-jp.webp';
import flagCn from '../assets/images/countries/webp/flag-cn.webp';
import flagPl from '../assets/images/countries/webp/flag-pl.webp';
import flagCz from '../assets/images/countries/webp/flag-cz.webp';
import flagPo from '../assets/images/countries/webp/flag-po.webp';
import flagCr from '../assets/images/countries/webp/flag-cr.webp';
import flagNl from '../assets/images/countries/webp/flag-nl.webp';
import flagAr from '../assets/images/countries/webp/flag-ar.webp';
import flagAf from '../assets/images/countries/webp/flag-af.webp';
import flagIceLandic from '../assets/images/countries/webp/flag-is.webp';
import flagSq from '../assets/images/countries/webp/flag-sq.webp';
import flagAm from '../assets/images/countries/webp/flag-am.webp';
import flagHy from '../assets/images/countries/webp/flag-hy.webp';
import flagAs from '../assets/images/countries/webp/flag-as.webp';
import flagAy from '../assets/images/countries/webp/flag-ay.webp';
import flagAz from '../assets/images/countries/webp/flag-az.webp';
import flagBm from '../assets/images/countries/webp/flag-bm.webp';
import flagEu from '../assets/images/countries/webp/flag-eu.webp';
import flagBe from '../assets/images/countries/webp/flag-be.webp';
import flagBn from '../assets/images/countries/webp/flag-bn.webp';
import flagBho from '../assets/images/countries/webp/flag-bho.webp';
import flagBs from '../assets/images/countries/webp/flag-bs.webp';
import flagCa from '../assets/images/countries/webp/flag-ca.webp';
import flagCeb from '../assets/images/countries/webp/flag-ceb.webp';
import flagCo from '../assets/images/countries/webp/flag-co.webp';
import flagDa from '../assets/images/countries/webp/flag-da.webp';
import flagDv from '../assets/images/countries/webp/flag-dv.webp';
import flagEo from '../assets/images/countries/webp/flag-eo.webp';
import flagEt from '../assets/images/countries/webp/flag-et.webp';
import flagEe from '../assets/images/countries/webp/flag-ee.webp';
import flagFi from '../assets/images/countries/webp/flag-fi.webp';
import flagFy from '../assets/images/countries/flag-fy.svg';
import flagGl from '../assets/images/countries/webp/flag-gl.webp';
import flagKa from '../assets/images/countries/webp/flag-ka.webp';
import flagGn from '../assets/images/countries/webp/flag-gn.webp';
import flagGu from '../assets/images/countries/webp/flag-gu.webp';
import flagHt from '../assets/images/countries/webp/flag-ht.webp';
import flagHa from '../assets/images/countries/webp/flag-ha.webp';
import flagHaw from '../assets/images/countries/webp/flag-haw.webp';
import flagHe from '../assets/images/countries/webp/flag-he.webp';
import flagHmn from '../assets/images/countries/webp/flag-hmn.webp';
import flagHu from '../assets/images/countries/webp/flag-hu.webp';
import flagId from '../assets/images/countries/webp/flag-id.webp';
import flagGa from '../assets/images/countries/webp/flag-ga.webp';
import flagKn from '../assets/images/countries/webp/flag-kn.webp';
import flagKk from '../assets/images/countries/webp/flag-kk.webp';
import flagKm from '../assets/images/countries/webp/flag-km.webp';
import flagRw from '../assets/images/countries/webp/flag-rw.webp';
import flagKri from '../assets/images/countries/webp/flag-kri.webp';
import flagKu from '../assets/images/countries/webp/flag-ku.webp';
import flagLo from '../assets/images/countries/webp/flag-lo.webp';
import flagLa from '../assets/images/countries/webp/flag-la.webp';
import flagLv from '../assets/images/countries/webp/flag-lv.webp';
import flagLn from '../assets/images/countries/webp/flag-ln.webp';
import flagLt from '../assets/images/countries/webp/flag-lt.webp';
import flagLg from '../assets/images/countries/webp/flag-lg.webp';
import flagLb from '../assets/images/countries/webp/flag-lb.webp';
import flagMk from '../assets/images/countries/webp/flag-mk.webp';
import flagMg from '../assets/images/countries/webp/flag-mg.webp';
import flagMs from '../assets/images/countries/webp/flag-ms.webp';
import flagMt from '../assets/images/countries/webp/flag-mt.webp';
import flagMi from '../assets/images/countries/webp/flag-mi.webp';
import flagMn from '../assets/images/countries/webp/flag-mn.webp';
import flagNe from '../assets/images/countries/webp/flag-ne.webp';
import flagNo from '../assets/images/countries/webp/flag-no.webp';
import flagOm from '../assets/images/countries/webp/flag-om.webp';
import flagFa from '../assets/images/countries/webp/flag-fa.webp';
import flagSm from '../assets/images/countries/webp/flag-sm.webp';
import flagNso from '../assets/images/countries/webp/flag-nso.webp';
import flagSr from '../assets/images/countries/webp/flag-sr.webp';
import flagSt from '../assets/images/countries/webp/flag-st.webp';
import flagSn from '../assets/images/countries/webp/flag-sn.webp';
import flagSd from '../assets/images/countries/webp/flag-sd.webp';
import flagSk from '../assets/images/countries/webp/flag-sk.webp';
import flagSl from '../assets/images/countries/webp/flag-sl.webp';
import flagSo from '../assets/images/countries/webp/flag-so.webp';
import flagSw from '../assets/images/countries/webp/flag-sw.webp';
import flagSv from '../assets/images/countries/webp/flag-sv.webp';
import flagTg from '../assets/images/countries/webp/flag-tg.webp';
import flagTa from '../assets/images/countries/webp/flag-ta.webp';
import flagTh from '../assets/images/countries/webp/flag-th.webp';
import flagTk from '../assets/images/countries/webp/flag-tk.webp';
import flagUk from '../assets/images/countries/webp/flag-uk.webp';
import flagUr from '../assets/images/countries/webp/flag-ur.webp';
import flagUg from '../assets/images/countries/webp/flag-ug.webp';
import flagUz from '../assets/images/countries/webp/flag-uz.webp';
import flagVi from '../assets/images/countries/webp/flag-vi.webp';
import flagCy from '../assets/images/countries/webp/flag-cy.webp';
import flagYo from '../assets/images/countries/webp/flag-yo.webp';
import flagZu from '../assets/images/countries/webp/flag-zu.webp';

export const languages = {
  enus: {
    title: 'English (US)',
    image: flagUs
  },
  en: {
    title: 'English (UK)',
    image: flagEn
  },
  bg: {
    title: 'Bulgarian',
    image: flagBg
  },
  ru: {
    title: 'Russian',
    image: flagRu
  },
  it: {
    title: 'Italian',
    image: flagIt
  },
  fr: {
    title: 'French',
    image: flagFr
  },
  de: {
    title: 'German',
    image: flagDe
  },
  gr: {
    title: 'Greek',
    image: flagGr
  },
  es: {
    title: 'Spanish',
    image: flagEs
  },
  tr: {
    title: 'Turkish',
    image: flagTr
  },
  jp: {
    title: 'Japanese',
    image: flagJp
  },
  cn: {
    title: 'Chinese',
    image: flagCn
  },
  pl: {
    title: 'Polish',
    image: flagPl
  },
  cz: {
    title: 'Czech',
    image: flagCz
  },
  hn: {
    title: 'Hungarian',
    image: flagHn
  },
  ro: {
    title: 'Romanian',
    image: flagRo
  },
  po: {
    title: 'Portugal',
    image: flagPo
  },
  cr: {
    title: 'Croatian',
    image: flagCr
  },
  nl: {
    title: 'Netherlands',
    image: flagNl
  },
  ar: {
    title: 'Arabic',
    image: flagAr
  },
  ko: {
    title: 'Korean',
    image: flagKo
  },
  af: {
    title: 'Afrikaans',
    image: flagAf
  },
  sq: {
    title: 'Albanian',
    image: flagSq
  },
  am: {
    title: 'Amharic',
    image: flagAm
  },
  hy: {
    title: 'Armenian',
    image: flagHy
  },
  as: {
    title: 'Assamese',
    image: flagAs
  },
  ay: {
    title: 'Aymara',
    image: flagAy
  },
  az: {
    title: 'Azerbaijani',
    image: flagAz
  },
  bm: {
    title: 'Bambara',
    image: flagBm
  },
  eu: {
    title: 'Basque',
    image: flagEu
  },
  be: {
    title: 'Belarusion',
    image: flagBe
  },
  bn: {
    title: 'Bengali',
    image: flagBn
  },
  bho: {
    title: 'Bhojpuri',
    image: flagBho
  },
  bs: {
    title: 'Bosnian',
    image: flagBs
  },
  ca: {
    title: 'Catalon',
    image: flagCa
  },
  ceb: {
    title: 'Cebuano',
    image: flagCeb
  },
  co: {
    title: 'Corsican',
    image: flagCo
  },
  da: {
    title: 'Danish',
    image: flagDa
  },
  dv: {
    title: 'Dhivehi',
    image: flagDv
  },
  doi: {
    title: 'Dogri',
    image: flagAs
  },
  eo: {
    title: 'Esperanto',
    image: flagEo
  },
  et: {
    title: 'Estonian',
    image: flagEt
  },
  ee: {
    title: 'Ewe',
    image: flagEe
  },
  fi: {
    title: 'Finnish',
    image: flagFi
  },
  fy: {
    title: 'Frisian',
    image: flagFy
  },
  gl: {
    title: 'Galician',
    image: flagGl
  },
  ka: {
    title: 'Georgian',
    image: flagKa
  },
  gn: {
    title: 'Guarani',
    image: flagGn
  },
  gu: {
    title: 'Gujarati',
    image: flagGu
  },
  ht: {
    title: 'Haitian Creole',
    image: flagHt
  },
  ha: {
    title: 'Hausa',
    image: flagHa
  },
  haw: {
    title: 'Hawaiian',
    image: flagHaw
  },
  he: {
    title: 'Hebrew',
    image: flagHe
  },
  hi: {
    title: 'Hindi',
    image: flagAs
  },
  hmn: {
    title: 'Hmong',
    image: flagHmn
  },
  hu: {
    title: 'Hungarian',
    image: flagHu
  },
  is: {
    title: 'Icelandic',
    image: flagIceLandic
  },
  id: {
    title: 'Indonesian',
    image: flagId
  },
  ga: {
    title: 'Irish',
    image: flagGa
  },
  kn: {
    title: 'Kannada',
    image: flagKn
  },
  kk: {
    title: 'Kazakh',
    image: flagKk
  },
  km: {
    title: 'Khmer',
    image: flagKm
  },
  rw: {
    title: 'Kinyarwanda',
    image: flagRw
  },
  gom: {
    title: 'Konkani',
    image: flagAs
  },
  kri: {
    title: 'Krio',
    image: flagKri
  },
  ku: {
    title: 'Kurdish',
    image: flagKu
  },
  lo: {
    title: 'Lao',
    image: flagLo
  },
  la: {
    title: 'Latin',
    image: flagLa
  },
  lv: {
    title: 'Latvian',
    image: flagLv
  },
  ln: {
    title: 'Lingala',
    image: flagLn
  },
  lt: {
    title: 'Lithuanian',
    image: flagLt
  },
  lg: {
    title: 'Luganda',
    image: flagLg
  },
  lb: {
    title: 'Luxembourgish',
    image: flagLb
  },
  mk: {
    title: 'Macedonian',
    image: flagMk
  },
  mai: {
    title: 'Maithili',
    image: flagAs
  },
  mg: {
    title: 'Malagasy',
    image: flagMg
  },
  ms: {
    title: 'Malay',
    image: flagMs
  },
  ml: {
    title: 'Malayalam',
    image: flagAs
  },
  mt: {
    title: 'Maltese',
    image: flagMt
  },
  mi: {
    title: 'Maori',
    image: flagMi
  },
  mn: {
    title: 'Mongolian',
    image: flagMn
  },
  ne: {
    title: 'Nepali',
    image: flagNe
  },
  no: {
    title: 'Norwegian',
    image: flagNo
  },
  om: {
    title: 'Oromo',
    image: flagOm
  },
  fa: {
    title: 'Persian',
    image: flagFa
  },
  sm: {
    title: 'Samoan',
    image: flagSm
  },
  nso: {
    title: 'Sepedi',
    image: flagNso
  },
  sr: {
    title: 'Serbian',
    image: flagSr
  },
  st: {
    title: 'Sesotho',
    image: flagSt
  },
  sn: {
    title: 'Shona',
    image: flagSn
  },
  sd: {
    title: 'Sindhi',
    image: flagSd
  },
  sk: {
    title: 'Slovak',
    image: flagSk
  },
  sl: {
    title: 'Slovenian',
    image: flagSl
  },
  so: {
    title: 'Somali',
    image: flagSo
  },
  sw: {
    title: 'Swahili',
    image: flagSw
  },
  sv: {
    title: 'Swedish',
    image: flagSv
  },
  tg: {
    title: 'Tajik',
    image: flagTg
  },
  ta: {
    title: 'Tamil',
    image: flagTa
  },
  th: {
    title: 'Thai',
    image: flagTh
  },
  tk: {
    title: 'Turkmen',
    image: flagTk
  },
  uk: {
    title: 'Ukranian',
    image: flagUk
  },
  ur: {
    title: 'Urdu',
    image: flagUr
  },
  ug: {
    title: 'Uyghur',
    image: flagUg
  },
  uz: {
    title: 'Uzbek',
    image: flagUz
  },
  vi: {
    title: 'Vietnamese',
    image: flagVi
  },
  cy: {
    title: 'Welsh',
    image: flagCy
  },
  yo: {
    title: 'Yoruba',
    image: flagYo
  },
  zu: {
    title: 'Zulu',
    image: flagZu
  }
};

export const FAQ_Helmet_Scripts = [
  { 
      forURL: 'memorial-groups-feature', 
      script: `{ 
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": [{
                  "@type": "Question",
                  "name": "How do I create a memorial group on Memkeepers?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Don’t forget that Memkeepers has a section for Pet memorial profiles with corresponding Pet Groups.
                      Can friends see each other's posts and contributions?
                      Yes, friends can see the posts and contributions that you or others publish on your timeline. However, it's important to note that privacy settings may allow users to control the visibility of their own posts or choose to limit their content to specific groups or individuals."
                  }
                  },{
                  "@type": "Question",
                  "name": "Is there a limit to the number of friends I can have in my network?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "No, there isn’t a limit to the number of friends that you can add to your online memorial website network. Our Friends feature gives you all the flexibility you need."
                  }
                  },{
                  "@type": "Question",
                  "name": "Can I invite friends who may not be registered users of Memkeepers?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "You can invite friends who are not registered users of Memkeepers, but they will have to sign up before they start using it. The signup process is extremely fast, easy, and most importantly - completely free. Click on the JOIN NOW button on top right so you can register."
                  }
                  },{
                  "@type": "Question",
                  "name": "Can I remove or block someone from my friends' list if needed?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, you can remove or block someone from your friends' list if needed. This functionality of our Friends feature allows you to have control over your network and ensures that you can manage your connections in a way that is comfortable and appropriate for you."
                  }
                  },{
                  "@type": "Question",
                  "name": "Can I join multiple Memorial Groups?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, you can join or invite people to multiple memorial groups. Joining multiple groups allows you to connect with different communities, find support from various perspectives, and engage in conversations that resonate with your experiences."
                  }
                  },{
                  "@type": "Question",
                  "name": "How do I find Memorial Groups that are relevant to me?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To find Memorial Groups that are relevant to you, use the Search field on Memkeepers banner, top left, and enter a name or key word you are looking for."
                  }
                  },{
                  "@type": "Question",
                  "name": "Can I leave a Memorial Group if I no longer wish to be a part of it?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, you can leave a Memorial Group you no longer wish to be a part of. Leaving a group allows you to disengage from its discussions and activities while giving you the freedom to explore other groups or focus on different aspects of your grief journey."
                  }
                  }]
              }` 
  }, {
      forURL: 'online-memorial-timeline',
      script: `{
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": [{
                  "@type": "Question",
                  "name": "Is there a limit to the number of events I can add to the timeline?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "No, there is no limit to the number of events you can add to your online memorial timeline. Add as many events, photos and videos as you want."
                  }
                  },{
                  "@type": "Question",
                  "name": "Can I upload and include both photos and videos in the timeline?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Certainly! You have the ability to upload and include both photos and videos in the timeline. This feature allows you to showcase a diverse range of visual elements that bring your loved one's memories to life. Whether it's cherished photographs capturing special moments or heartfelt videos that encapsulate their essence, you can incorporate them seamlessly into the timeline."
                  }
                  },{
                  "@type": "Question",
                  "name": "Can other family members and friends contribute to the timeline?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Absolutely! We encourage and provide the ability for other family members and friends to contribute to the online memorial timeline. By inviting them to share their own memories, stories, and photos, we create a collaborative and inclusive tribute that reflects the collective impact your loved one had on those around them. 
              
              This feature allows everyone to come together and contribute to the rich tapestry of memories, fostering a sense of connection and shared remembrance."
                  }
                  }]
              }`
  }, {
      forURL: 'honoree-feature',
      script: `{
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": [{
                  "@type": "Question",
                  "name": "Can I edit or update the Honoree profile after it's been created?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, absolutely! We understand that memories and information about your loved one may evolve over time, and you may wish to make updates or additions to the Honoree profile. Our online memorial website allows you to edit and update the profile even after it has been created."
                  }
                  },{
                  "@type": "Question",
                  "name": "Are there any character limits for the biography section of the profile?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "No, there is no character limit for the profile’s biography section with our honoree feature. You can write as much as you want, and keep the legacy of your loved one by sharing all the memories, milestones, anecdotes and achievements you can think of."
                  }
                  },{
                  "@type": "Question",
                  "name": "Can I upload multiple photos and videos to the Honoree profile?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Absolutely! You have the ability to upload multiple photos and videos to the Honoree profile. This feature allows you to include a diverse collection of visual elements that celebrate and illustrate your loved one's life."
                  }
                  }]
              }`
  }, {
      forURL: 'memorial-page',
      script: `{
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": [{
                  "@type": "Question",
                  "name": "Can I have multiple pages on Memkeepers?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, you can create multiple pages on Memkeepers. Whether you want to create personal memorial pages for different loved ones or showcase multiple businesses or services related to memorials, you have the flexibility to create and manage multiple pages within your account."
                  }
                  },{
                  "@type": "Question",
                  "name": "Can I advertise my page on Memkeepers?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, you can advertise your page on Memkeepers. Advertising your page allows you to increase its visibility, attract more visitors, and engage with a wider audience within the memorial community. By promoting your page, you have the opportunity to reach individuals who are actively seeking personal tributes or are interested in memorial-related products or services."
                  }
                  },{
                  "@type": "Question",
                  "name": "Can I invite friends and family to like or follow my page?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, you can invite friends and family to like or follow your page. Inviting your loved ones to engage with your page allows them to stay connected, share their own memories or tributes, and participate in the ongoing remembrance of your loved one or support your memorial-related business."
                  }
                  },{
                  "@type": "Question",
                  "name": "How can I reach people beyond the group of my page?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "The advertising feature gives you access to everyone's timeline (not just your group’s timeline as above).
              
              Timeline Ads will appear between every 5th post, alternating with other timeline ads, on everyone’s timeline.
              
              Sidebar Ads will display alternating with other sidebar ads, and are continually visible to everyone."
                  }
                  }]
              }`
  }, {
      forURL: 'advertising-feature',
      script: `{
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": [{
                  "@type": "Question",
                  "name": "What advertising options are available on Memkeepers?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "You have the option to advertise on the Timeline, or on the Sidebar.
              
              Timeline Ads will appear between every 5th post, alternating with other timeline ads, on everyone’s timeline.
              
              Sidebar Ads will display alternating with other sidebar ads, and are continually visible to everyone."
                  }
                  },{
                  "@type": "Question",
                  "name": "What are the advertising packages and are there any discounts available?",
                  "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Timeline Ads cost $25 one time, and will display for 30 days.
              
              If you choose the auto renew option, the price goes down to $20/mo. 
              
              
              
              Sidebar Ads cost $45 one time, and will display for 30 days.
              
              If you choose the auto renew option, the price goes down to $40/mo."
                  }
                  }]
              }`
  }
]

export const userLocation = async () => {
  const geo = await userGeo();

  return geo;
}

export const countriesList = (continent) => {
  let european_countries = [{
    "name": "Andorra",
    "code": "AD"
  }, {
    "name": "Albania",
    "code": "AL"
  }, {
    "name": "Austria",
    "code": "AT"
  }, {
    "name": "Åland Islands",
    "code": "AX"
  }, {
    "name": "Bosnia and Herzegovina",
    "code": "BA"
  }, {
    "name": "Belgium",
    "code": "BE"
  }, {
    "name": "Bulgaria",
    "code": "BG"
  }, {
    "name": "Belarus",
    "code": "BY"
  }, {
    "name": "Switzerland",
    "code": "CH"
  }, {
    "name": "Cyprus",
    "code": "CY"
  }, {
    "name": "Czech Republic",
    "code": "CZ"
  }, {
    "name": "Germany",
    "code": "DE"
  }, {
    "name": "Denmark",
    "code": "DK"
  }, {
    "name": "Estonia",
    "code": "EE"
  }, {
    "name": "Spain",
    "code": "ES"
  }, {
    "name": "Finland",
    "code": "FI"
  }, {
    "name": "Faroe Islands",
    "code": "FO"
  }, {
    "name": "France",
    "code": "FR"
  }, {
    "name": "United Kingdom",
    "code": "GB"
  }, {
    "name": "Guernsey",
    "code": "GG"
  }, {
    "name": "Greece",
    "code": "GR"
  }, {
    "name": "Croatia",
    "code": "HR"
  }, {
    "name": "Hungary",
    "code": "HU"
  }, {
    "name": "Ireland",
    "code": "IE"
  }, {
    "name": "Isle of Man",
    "code": "IM"
  }, {
    "name": "Iceland",
    "code": "IC"
  }, {
    "name": "Italy",
    "code": "IT"
  }, {
    "name": "Jersey",
    "code": "JE"
  }, {
    "name": "Liechtenstein",
    "code": "LI"
  }, {
    "name": "Lithuania",
    "code": "LT"
  }, {
    "name": "Luxembourg",
    "code": "LU"
  }, {
    "name": "Latvia",
    "code": "LV"
  }, {
    "name": "Monaco",
    "code": "MC"
  }, {
    "name": "Moldova, Republic of",
    "code": "MD"
  }, {
    "name": "Macedonia, The Former Yugoslav Republic of",
    "code": "MK"
  }, {
    "name": "Malta",
    "code": "MT"
  }, {
    "name": "Netherlands",
    "code": "NL"
  }, {
    "name": "Norway",
    "code": "NO"
  }, {
    "name": "Poland",
    "code": "PL"
  }, {
    "name": "Portugal",
    "code": "PT"
  }, {
    "name": "Romania",
    "code": "RO"
  }, {
    "name": "Russian Federation",
    "code": "RU"
  }, {
    "name": "Sweden",
    "code": "SE"
  }, {
    "name": "Slovenia",
    "code": "SI"
  }, {
    "name": "Svalbard and Jan Mayen",
    "code": "SJ"
  }, {
    "name": "Slovakia",
    "code": "SK"
  }, {
    "name": "San Marino",
    "code": "SM"
  }, {
    "name": "Ukraine",
    "code": "UA"
  }, {
    "name": "Holy See (Vatican City State)",
    "code": "VA"
  }]

  let us_states = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
  ]

  let north_america_countries = [
    {"id": "AC", "name": "Acoma"},
    {"id": "AU", "name": "Aleutian"},
    {"id": "AL", "name": "Algonquin"},
    {"id": "AM", "name": "American"},
    {"id": "AN", "name": "Antigua and Barbuda"},
    {"id": "AT", "name": "Antilles"},
    {"id": "AR", "name": "Arikara"},
    {"id": "AB", "name": "Aruba"},
    {"id": "AZ", "name": "Aztec"},
    {"id": "BB", "name": "Barbados"},
    {"id": "BC", "name": "Bella Coola"},
    {"id": "BL", "name": "Blackfoot"},
    {"id": "BO", "name": "Bonaire"},
    {"id": "CA", "name": "Canada"},
    {"id": "CG", "name": "Canada/Greenland"},
    {"id": "CE", "name": "Cherokee"},
    {"id": "CY", "name": "Cheyenne"},
    {"id": "CH", "name": "Chickasaw"},
    {"id": "CI", "name": "Chinook"},
    {"id": "CO", "name": "Choktaw"},
    {"id": "CU", "name": "Chumash"},
    {"id": "BA", "name": "Commonwealth of the Bahamas"},
    {"id": "CR", "name": "Creek"},
    {"id": "CB", "name": "Cuba"},
    {"id": "DA", "name": "Dakota"},
    {"id": "DO", "name": "Dominica"},
    {"id": "DR", "name": "Dominican Republic"},
    {"id": "ES", "name": "Eskimo"},
    {"id": "EK", "name": "Eskimo (Greenland)"},
    {"id": "GL", "name": "Greenland"},
    {"id": "GR", "name": "Grenada"},
    {"id": "HA", "name": "Haiti"},
    {"id": "HO", "name": "Hopi"},
    {"id": "IN", "name": "Inuit"},
    {"id": "IR", "name": "Iroquois"},
    {"id": "JM", "name": "Jamaica"},
    {"id": "KL", "name": "Klamath"},
    {"id": "KW", "name": "Kwakiutl"},
    {"id": "LA", "name": "Lakota"},
    {"id": "MA", "name": "Mandan"},
    {"id": "ME", "name": "Mexico"},
    {"id": "NV", "name": "Navajo"},
    {"id": "OH", "name": "Ohlone"},
    {"id": "OJ", "name": "Ojibwe"},
    {"id": "OS", "name": "Osage"},
    {"id": "PW", "name": "Pawnee"},
    {"id": "PE", "name": "Pequot"},
    {"id": "PP", "name": "Popoluca"},
    {"id": "PO", "name": "Potawatomi"},
    {"id": "PU", "name": "Pueblo"},
    {"id": "PR", "name": "Puerto Rico"},
    {"id": "SK", "name": "Saint Kitts and Nevis"},
    {"id": "SL", "name": "Saint Lucia"},
    {"id": "SV", "name": "Saint Vincent and the Grenadines"},
    {"id": "SA", "name": "Salish"},
    {"id": "SE", "name": "Seneca"},
    {"id": "SH", "name": "Shoshoni"},
    {"id": "SX", "name": "Sioux"},
    {"id": "TL", "name": "Tlingit"},
    {"id": "US", "name": "United States"},
    {"id": "VI", "name": "Virgin Islands"},
    {"id": "YU", "name": "Yurok"},
    {"id": "ZU", "name": "Zuni"}
  ]
  
  let south_america_countries = [
    {"id": "AC", "name": "Araucanian"},
    {"id": "AR", "name": "Argentina"},
    {"id": "BE", "name": "Belize"},
    {"id": "BO", "name": "Bolivia"},
    {"id": "RR", "name": "Bororo"},
    {"id": "BR", "name": "Brazil"},
    {"id": "CH", "name": "Chile"},
    {"id": "CI", "name": "Chimalateco"},
    {"id": "CO", "name": "Colombia"},
    {"id": "CR", "name": "Costa Rica"},
    {"id": "EC", "name": "Ecuador"},
    {"id": "ES", "name": "El Salvador"},
    {"id": "FI", "name": "Falkland Islands"},
    {"id": "FG", "name": "French Guiana"},
    {"id": "GU", "name": "Guatemala"},
    {"id": "GY", "name": "Guyana"},
    {"id": "HO", "name": "Honduras"},
    {"id": "IN", "name": "Inca"},
    {"id": "MA", "name": "Mapuche"},
    {"id": "MY", "name": "Mayan"},
    {"id": "NA", "name": "Nahuatl"},
    {"id": "NI", "name": "Nicaragua"},
    {"id": "PM", "name": "Panama"},
    {"id": "PA", "name": "Paraguay"},
    {"id": "PE", "name": "Peru"},
    {"id": "SU", "name": "Suriname"},
    {"id": "TT", "name": "Trinidad and Tobago"},
    {"id": "TP", "name": "Tupi"},
    {"id": "UR", "name": "Uruguay"},
    {"id": "VE", "name": "Venezuela"}
  ]
  
  let afrika_countries = [
    {"id": "AI", "name": "Afrikaans"},
    {"id": "AL", "name": "Algeria"},
    {"id": "AN", "name": "Angola"},
    {"id": "BA", "name": "Bantu"},
    {"id": "BE", "name": "Benin"},
    {"id": "BT", "name": "Botswana"},
    {"id": "BF", "name": "Burkina Faso (Upper Volta)"},
    {"id": "BR", "name": "Burundi"},
    {"id": "BU", "name": "Bushman"},
    {"id": "BH", "name": "Bushongo"},
    {"id": "CR", "name": "Cameroon"},
    {"id": "CI", "name": "Canary Is."},
    {"id": "CV", "name": "Cape Verde"},
    {"id": "CA", "name": "Central African Republic"},
    {"id": "DH", "name": "Dahomean"},
    {"id": "DC", "name": "Democratic Republic of the Congo"},
    {"id": "EG", "name": "Egypt"},
    {"id": "ER", "name": "Eritrea"},
    {"id": "ET", "name": "Ethiopia"},
    {"id": "FT", "name": "Fula/Toucouleur"},
    {"id": "GB", "name": "Gabon"},
    {"id": "GA", "name": "Gambia"},
    {"id": "GH", "name": "Ghana"},
    {"id": "GC", "name": "Gold Coast"},
    {"id": "GU", "name": "Guinea"},
    {"id": "HO", "name": "Hottentot"},
    {"id": "IC", "name": "Ivory Coast"},
    {"id": "KY", "name": "Kenya"},
    {"id": "LE", "name": "Lesotho"},
    {"id": "LI", "name": "Liberia"},
    {"id": "LB", "name": "Libya"},
    {"id": "MD", "name": "Madagascar"},
    {"id": "MW", "name": "Malawi"},
    {"id": "ML", "name": "Mali"},
    {"id": "MN", "name": "Mande"},
    {"id": "MU", "name": "Mauritania"},
    {"id": "MA", "name": "Mauritius"},
    {"id": "MB", "name": "Mbundu"},
    {"id": "ME", "name": "Mende"},
    {"id": "MR", "name": "Morocco"},
    {"id": "MZ", "name": "Mozambique"},
    {"id": "NM", "name": "Namibia"},
    {"id": "NG", "name": "Niger"},
    {"id": "NI", "name": "Nigeria"},
    {"id": "PY", "name": "Pygmy"},
    {"id": "CH", "name": "Republic of Chad"},
    {"id": "RD", "name": "Republic of Djibouti"},
    {"id": "RE", "name": "Republic of Equatorial Guinea"},
    {"id": "RG", "name": "Republic of Guinea-Bissau"},
    {"id": "RS", "name": "Republic of Sao Tome and Principe"},
    {"id": "SY", "name": "Republic of Seychelles"},
    {"id": "RC", "name": "Republic of the Congo (Congo-Brazzaville)"},
    {"id": "RI", "name": "Reunion Island"},
    {"id": "RW", "name": "Rwanda"},
    {"id": "SX", "name": "Saint Helena Island"},
    {"id": "SE", "name": "Semitic"},
    {"id": "SN", "name": "Senegal"},
    {"id": "SL", "name": "Sierra Leone"},
    {"id": "SO", "name": "Somalia"},
    {"id": "SA", "name": "South Africa"},
    {"id": "SS", "name": "South Sudan"},
    {"id": "SU", "name": "Sudan"},
    {"id": "SH", "name": "Swahili"},
    {"id": "SW", "name": "Swaziland"},
    {"id": "TA", "name": "Tanzania"},
    {"id": "TO", "name": "Togo"},
    {"id": "TN", "name": "Tunisia"}
  ]
  
  let asia_countries = [
    {"id": "AH", "name": "Abkhazian"},
    {"id": "AF", "name": "Afghanistan"},
    {"id": "AI", "name": "Ainu"},
    {"id": "AK", "name": "Akkadian (Accadian)"},
    {"id": "AL", "name": "Altai"},
    {"id": "AR", "name": "Arabian"},
    {"id": "AM", "name": "Armenian"},
    {"id": "AY", "name": "Assyrian"},
    {"id": "AB", "name": "Assyro-Babylonian"},
    {"id": "AZ", "name": "Azerbaijan"},
    {"id": "BY", "name": "Babylon"},
    {"id": "BI", "name": "Bahrain"},
    {"id": "BA", "name": "Bangladesh"},
    {"id": "BE", "name": "Bengal"},
    {"id": "BH", "name": "Bhutan"},
    {"id": "BO", "name": "Borneo"},
    {"id": "BN", "name": "Brunei"},
    {"id": "BR", "name": "Buriat"},
    {"id": "BU", "name": "Burma"},
    {"id": "CM", "name": "Cambodia"},
    {"id": "CH", "name": "China"},
    {"id": "CU", "name": "Chukchi"},
    {"id": "DK", "name": "Democratic People's Republic of Korea"},
    {"id": "EL", "name": "Elamite"},
    {"id": "EV", "name": "Evenki"},
    {"id": "GE", "name": "Georgia"},
    {"id": "HA", "name": "Hattic"},
    {"id": "HE", "name": "Hebrew"},
    {"id": "HI", "name": "Hindu"},
    {"id": "HT", "name": "Hittite"},
    {"id": "IN", "name": "India"},
    {"id": "ID", "name": "Indonesia"},
    {"id": "IR", "name": "Iran"},
    {"id": "IQ", "name": "Iraq"},
    {"id": "IS", "name": "Israel"},
    {"id": "IT", "name": "Itelmen"},
    {"id": "JA", "name": "Japan"},
    {"id": "JW", "name": "Jewish"},
    {"id": "JO", "name": "Jordan"},
    {"id": "KA", "name": "Kashmir"},
    {"id": "KZ", "name": "Kazakhstan"},
    {"id": "KT", "name": "Ket"},
    {"id": "KR", "name": "Korea"},
    {"id": "KU", "name": "Kuwait"},
    {"id": "KY", "name": "Kyrgyzstan"},
    {"id": "LA", "name": "Laos"},
    {"id": "LE", "name": "Lebanon"},
    {"id": "MA", "name": "Malaysia"},
    {"id": "MS", "name": "Mansi"},
    {"id": "ME", "name": "Mesopotamian"},
    {"id": "MY", "name": "Minyong"},
    {"id": "MO", "name": "Mongolia"},
    {"id": "MG", "name": "Monguor"},
    {"id": "MM", "name": "Myanmar"},
    {"id": "NA", "name": "Nanai"},
    {"id": "NG", "name": "Neghidhian"},
    {"id": "NE", "name": "Nepal"},
    {"id": "NS", "name": "Nganasan"},
    {"id": "OM", "name": "Oman"},
    {"id": "OS", "name": "Ostyak"},
    {"id": "OT", "name": "Ottoman"},
    {"id": "PK", "name": "Pakistan"},
    {"id": "PE", "name": "Persian"},
    {"id": "PH", "name": "Philippines"},
    {"id": "PO", "name": "Phoenician"},
    {"id": "QA", "name": "Qatar"},
    {"id": "RK", "name": "Republic of Korea"},
    {"id": "ML", "name": "Republic of the Maldives"},
    {"id": "SA", "name": "Sanskrit"},
    {"id": "SB", "name": "Saudi Arabia"},
    {"id": "SC", "name": "Scythian"},
    {"id": "SE", "name": "Semitic"},
    {"id": "SI", "name": "Siberia"},
    {"id": "SR", "name": "Sri Lanka"},
    {"id": "SU", "name": "Sumerian"},
    {"id": "SY", "name": "Syria"},
    {"id": "TW", "name": "Taiwan"},
    {"id": "TJ", "name": "Tajik"},
    {"id": "TS", "name": "Tajikistan"},
    {"id": "TM", "name": "Tamil"},
    {"id": "TH", "name": "Thailand"},
    {"id": "TB", "name": "Tibet"},
    {"id": "TL", "name": "Timor-Leste"},
    {"id": "TN", "name": "Tungu"},
    {"id": "TU", "name": "Turkey"},
    {"id": "TK", "name": "Turkmenistan"},
    {"id": "TV", "name": "Tuva"},
    {"id": "UL", "name": "Ulci"},
    {"id": "UA", "name": "United Arab Emirates"},
    {"id": "UR", "name": "Urartu"},
    {"id": "UZ", "name": "Uzbekistan"},
    {"id": "VT", "name": "Vietnam"},
    {"id": "YK", "name": "Yakutian"},
    {"id": "YE", "name": "Yemen"}
  ]

  let oceania_countries = [
    {"id": "AU", "name": "Australia"},
    {"id": "CI", "name": "Caroline Is."},
    {"id": "CO", "name": "Cook Islands"},
    {"id": "FJ", "name": "Fiji"},
    {"id": "FP", "name": "French Polynesia"},
    {"id": "GM", "name": "Guam"},
    {"id": "HA", "name": "Hawaii"},
    {"id": "KI", "name": "Kiribati"},
    {"id": "MA", "name": "Marquesas Islands"},
    {"id": "MI", "name": "Marshall Is."},
    {"id": "ME", "name": "Melanesia"},
    {"id": "MC", "name": "Micronesia"},
    {"id": "NA", "name": "Nauru"},
    {"id": "NB", "name": "New Britain"},
    {"id": "NC", "name": "New Caledonia"},
    {"id": "GU", "name": "New Guinea"},
    {"id": "NZ", "name": "New Zealand"},
    {"id": "NI", "name": "Niue"},
    {"id": "PN", "name": "Papua New Guinea"},
    {"id": "PO", "name": "Polynesia"},
    {"id": "PA", "name": "Republic of Palau"},
    {"id": "SA", "name": "Samoa"},
    {"id": "SI", "name": "Society Is."},
    {"id": "SO", "name": "Solomon Islands"},
    {"id": "TO", "name": "Tonga"},
    {"id": "TU", "name": "Tuamotu"},
    {"id": "TV", "name": "Tuvalu"},
    {"id": "--", "name": "Unknown"},
    {"id": "VA", "name": "Vanuatu"},
    {"id": "YO", "name": "Yolngu"}
  ]

  switch (continent) {
    case 'eu_countries':
      return european_countries
    case 'north_america':
      return north_america_countries
    case 'south_america':
      return south_america_countries
    case 'afrika':
      return afrika_countries
    case 'asia':
      return asia_countries
    case 'oceania': 
      return oceania_countries
    case 'us_states':
      return us_states
    default: return 'Choose continent'
  }
}

export const findLanguage = (language) => {
  switch (language) {
    case 'gr':
      return 'el';
    case 'jp':
      return 'ja';
    case 'cn':
      return 'zh-TW';
    case 'cz':
      return 'cs';
    case 'po':
      return 'pt';
    case 'cr':
      return 'hr';
    case 'hn':
      return 'hu';
    default: return language
  }
}

export const arrayEqual = (arr1, arr2) => arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);

// this function finds a value from a deep object based on its string representation
export function resolveObj(str, obj){
  return str && obj ? str.split('.').reduce((o,i)=> o ? o[i] : false, obj) : null;
} 

export function formatDateTime(str) {
  const locale = window.navigator.userLanguage || window.navigator.language;
  return moment(str).locale(locale).format("L HH:mm");
}

export function formatDate(str) {
  // const locale = window.navigator.userLanguage || window.navigator.language;
  //return moment(str).locale(locale).format("L"); //THIS TYPE OF FORMAT HAD CAUSED BUG WHEN UPDATING A HONOREE 
  // return moment(str).format("L");
  const formatedDate = moment(str).format("MM/DD/YYYY");
  return formatedDate
}

export function Duration(difference) {
  let secondsInMiliseconds    = 1000, 
      minutesInMiliseconds    = 60 * secondsInMiliseconds,
      hoursInMiliseconds      = 60 * minutesInMiliseconds;

  var differenceInDays       = difference / (secondsInMiliseconds * 3600 * 24),
      differenceInHours      = difference / hoursInMiliseconds,
      differenceInMinutes    = differenceInHours     % 1 * 60;
      // differenceInSeconds    = differenceInMinutes   % 1 * 60;
  return {
      "days"    : Math.floor(differenceInDays),
      "hours"   : Math.floor(differenceInHours),
      "minutes" : Math.floor(differenceInMinutes),
      // "seconds" : Math.floor(differenceInSeconds)
  }
}

export function userIsAdmin(user) {
  return user.AccessLevel === 3;
}

export function formatCurrency(num) {
  return `$${num ? num.toFixed(2) : "0.00"}`;
}

export function userRole(accessLevel) {
  switch(accessLevel) {
    case 1: return 'Group Admin';
    case 2: return 'Global Moderator';
    case 3: return 'Global Admin';
    default: return 'User';
  }
}

export function getImageUrl(str) {
  return str ? 
    (CONFIG.S3_BUCKET ? `https://${CONFIG.S3_BUCKET}.s3.amazonaws.com/${str}` : `${CONFIG.BACKEND_URL}/images/${str}`) :
    `${process.env.PUBLIC_URL}/images/image-not-found.png`;
}

export function fbGetPostTextLength(str) {
  let regex = /(<([^>]+)>)/ig;
  let text = str;

  return text.replace(regex, '').length;
}

export function getName(u) {
  if(u && typeof u !== 'undefined') {
    return u.Name || (u._type === 'honoree' ?
    `${u.FirstName} ${u.Nickname ? `(${u.Nickname})` : ''} ${u.MiddleName ? u.MiddleName : ''} ${u.HonoreeType === 'Pet' ? '(' : ''}${u.LastName}${u.HonoreeType === 'Pet' ? ')' : ''}`
    :
    (u.DisplayName ? u.DisplayName : `${u.First_Name} ${u.Last_Name}`));
  }
}

export function logError(err, user){
  const res = err?.response || {};
  const data = {
    stack: Error().stack,
    user: user?._id,
    message: err && err.response && err.response.data ? err.response.data.message : (err?.message ? err.message : (err ? err.toString() : 'Unknown error')),
    statusCode: res.data?.statusCode,
    status: res.status,
    statusText: res.statusText,
    url: res.config?.url,
    method: res.config?.method,
    sendMail: CONFIG.sendExceptionMail ? true : false
  };
  request('public.logError', data);
};

export function loadImagePreview(upload) {
  return new Promise((resolve, reject) => {
    if (upload) {
      const reader = new FileReader();
      reader.readAsDataURL(upload);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    } else {
      resolve(null);
    }
  })
};

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}