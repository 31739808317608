import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { usePopupContext } from '../../Contexts/popupContext';
import { useGalleryContext } from '../../Contexts/galleryContext';
import { useUserContext } from '../../Contexts/userContext';
import { formatDateTime, userIsAdmin, getImageUrl, getName } from '../../Util/common';
import { request } from '../../Services/networkService';
import ReactTooltip from 'react-tooltip';
import LoadingButton from '../../Components/LoadingButton';
import PdfViewer from '../../Components/PdfViewer';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import Linkify from 'react-linkify';
import Tag from './tag';

import fbshare from '../../assets/images/fbshare.png';
import tweet from '../../assets/images/tweet.png';
import lnshare from '../../assets/images/ln-share.png';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Posts({postData, updatePost, apiId, refresh, refreshPost, refreshMemobjects, noDelete=false, showSecondName=false, noComment=false}){
  const { handleError, popupConfirmOpen } = usePopupContext();
  const g = useGalleryContext();
  const { user, getAvatar } = useUserContext();
  const [postText, setPostText] = useState(postData ? postData.text : '');
  const [comment, setComment] = useState('');
  const [showAllComments, setShowAllComments] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploads, setUploads] = useState([]);
  const fileFld = useRef();
  const {t} = useTranslation('common');

  const visibleComments = postData && postData ? (showAllComments ? postData.comments : postData.comments.slice(0, 2)) : [];

  const uploadUrl = postData && postData.uploads && !!postData.uploads.length && (postData.uploads[0].wholeUrl ? postData.uploads[0].url : getImageUrl(postData.uploads[0].url));
  const moUrl = postData && postData.memobject && getImageUrl(postData.memobject.Url);

  const moExpiry = postData && moment(postData.memobjectExpiry);
  const moMessage = moExpiry && moment().isBefore(moExpiry) ? t('postbox.expires-in') + moment.duration(moExpiry.diff(moment())).humanize() : postData.memobjectInfinite ? 'Does Not Expire' : t('postbox.expired');

  const addPost = e => {
    setLoading(true);
    e.preventDefault();

    let data = new FormData();
    data.append('text', postText);
    data.append('id', apiId);
    postData && postData._id && data.append('_id', postData._id);
    uploads.length && data.append('file', uploads[0]);

    request('group.post.update', data)
      .then(res => {
          refreshPost(res);
          setEditMode(false);
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
        setUploads([]);
        fileFld.current && (fileFld.current.value = '');
      });
  };

  const deletePost = () => {
    popupConfirmOpen(t('postbox.confirm'), t('postbox.delete-post'))
    .then(
      () => request(`group.post.delete`, {id: postData._id})
      .then(() => {
        refresh();
        refreshMemobjects && refreshMemobjects();
      })
      .catch(handleError)
    ).catch(()=>{})
  };

  const addComment = (e, data) => {
    setLoading(true);
    e.preventDefault();
    const commentData = {
      text: comment,
      id: data._id
    };
    request(`group.post.comment`, commentData)
      .then(res => {
        refreshPost(res);
        setComment('');
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  const deleteComment = id => {
    popupConfirmOpen(t('postbox.confirm'), t('postbox.delete-post'))
    .then(
      () => request(`group.post.commentDelete`, {id: postData._id, commentId: id})
      .then(res => {
        refreshPost(res);
      })
      .catch(handleError)
    ).catch(()=>{})
  };

  const addReaction = () => {
    const reactionData = {
      reactionType: 0,
      id: postData._id
    };
    return request(`group.post.react`, reactionData)
      .then(res => {
        refreshPost(res);
      })
      .catch(handleError);
  };

  const addCommentReaction = commentId => {
    const reactionData = {
      reactionType: 0,
      id: postData._id,
      commentId
    };
    return request(`group.post.commentReact`, reactionData)
      .then(res => {
        refreshPost(res);
      })
      .catch(handleError);
  };

  const getUserLink = (o) => {
    const type = o._type === 'user' ? 'userprofile' : 'honoree';
    return (<Link to={`/${type}/${o._id}`} >{getName(o)}</Link>);
  };

  const getReactionList = (obj) => obj && obj.reactions.slice(0, 20).map(r => (`${getName(r.postedBy)}<br/>`)).join('');
  const userReacted = reactions => !!reactions.find(r => r.postedBy._id === user._id);
  const userReactedToPost = postData && userReacted(postData.reactions);

  const canEdit = !postData.autoAd && (user._id === postData.postedBy._id || postData.groupRelation === 'Owner' || postData.groupRelation === 'Moderator' || userIsAdmin(user));
  const displayCommentArea = !postData.autoAd && !noComment;

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  const dofbshare = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=http://www.memkeepers.me&quote=${postData.text}`, "pop", "width=600, height=400, scrollbars=no");
  };

  const doTweet = () => {
    window.open(`https://twitter.com/intent/tweet?text=${postData.text}`, "pop", "width=600, height=400, scrollbars=no");
  }

  const doLnShare = () => {
    window.open(`https://www.linkedin.com/shareArticle?mini=true&title=f1&url=http://www.memkeepers.me&summary=${postData.text}`, "pop", "width=600, height=400, scrollbars=no");
  }

  const removeTag = tag => {
    const idx = postData.tags.findIndex(o => o.tagged._id === tag.tagged._id);
    const newTags = [...postData.tags.slice(0, idx), ...postData.tags.slice(idx+1)];
    request('group.post.update', {tags: newTags, _id: postData._id})
      .then(updatePost)
      .catch(handleError);
  }

  const doUpdate = (body) => {
    request('ad.tracking.update', body);
  }

  const saveAdTracking = (ad, clicks = false) => {
    let alreadyClicked = false;
    request('ad.tracking.listById', {id: ad._id})
    .then(result => {
      if(result && Object.keys(result).length) {
        if(clicks) {
          result.clicks.timeline.forEach(element => {
            if(element.user === user._id) alreadyClicked = true;
          });
  
          if(!alreadyClicked) {
            result.clicks.timeline.push({ user: user._id })
            doUpdate(result);
          }
        } else {
          result.times.timeline = (result.times.timeline + 1);
          doUpdate(result);
        }
      }
    })
  }

  useEffect(() => {
    if(postData && postData.ad && postData._id) {
      saveAdTracking(postData);
    } 
  }, [postData])

  return (
    <div className="central-meta item">
      <div className="user-post" onClick={() => postData.ad && saveAdTracking(postData, true)}>
        <div className="friend-info">
          <figure>
            <div className="usericon user-avatar-image" style={{backgroundImage: `url("${getAvatar((postData.postedAs && postData.postedAs.ProfileImage) || postData.postedBy.ProfileImage || null)}")`}} />
          </figure>

          <div className="friend-name">

            <div>
              {postData.autoAd && "(Sponsored)"}
              {(postData.postedAs && <Link to={`/userpage/${postData.postedAs.pageId}`}>{postData.postedAs.Name}</Link>) || getUserLink(postData.postedBy)}
              {
                showSecondName && !postData.postedAs && postData.postedBy && postData.postedTo && postData.postedBy.OwnGroup !== postData.postedTo._id &&
                <Fragment>
                  <i className="fa fa-share" />
                  {postData.postedTo.Name}
                </Fragment>
              }
            </div>

            {
              (postData.memobject || postData.postAsMemobject) && 
                <span>
                  {<Translate>Posted a</Translate>} {postData.memobjectPremium ? 'PREMIUM' : ''} {<Translate>Memento -</Translate>} { moMessage } 
                  {postData.sale?.autoRenew && <Translate>(Auto renew)</Translate>}
                </span>
            }
            <span>{formatDateTime(postData.date)}</span>
            {
              postData.tags?.length > 0 && !postData.autoAd &&
              <div>
                {
                  postData.tags.map((tag, i) => (
                    <div className="post-tag-name" key={i}>
                      {getUserLink(tag.tagged)}
                      { canEdit && <i className="fa fa-times" title="Remove tag" onClick={() => removeTag(tag)} />}
                    </div>
                  ))
                }
              </div>
            }
          </div>

          {
            canEdit && !noDelete &&
            <div className="pull-right">
              {/* { !postData.memobject && <i className="fa fa-pencil-square-o" title={t('postbox.post-edit')} onClick={() => setEditMode(!editMode)}></i> } */}
              <i className="fa fa-trash" title={<Translate>Delete post</Translate>} onClick={deletePost}></i>
            </div>
          }

          {
            editMode ?
            <div className="newpst-input">
              <form onSubmit={addPost}>
                <textarea rows={2} required value={postText} onChange={e=>setPostText(e.target.value)}/>
                <div className="attachments">
                  <ul>
                    <LoadingButton noClass title={<Translate>Save</Translate>} loading={loading} />
                    <li><button type="button" onClick={() => setEditMode(false)}>{<Translate>Cancel</Translate>}</button></li>
                  </ul>
                </div>
              </form>
            </div>
            :
            <div className="description" onClick={() => postData.ad ? saveAdTracking(postData, true) : null}>
              {
                postData.ad && postData.link && postData.link.length > 0 ?
                <a href={postData.link} target="_blank" rel="noopener noreferrer">
                  <div dangerouslySetInnerHTML={{__html: postData.text}}></div>
                </a>
                :
                <Linkify componentDecorator={componentDecorator}><div dangerouslySetInnerHTML={{__html: postData.text}}></div></Linkify>
              }
            </div>
          }

          <div className="post-meta">

            {
              moUrl &&
              <center>
                <img src={moUrl} alt="Post content" onClick={() => g && g.galleryOpen(moUrl)} style={{width: 'auto'}}/>
              </center>
            }

            {
              postData.uploads && !!postData.uploads.length && postData.uploads[0].uploadType === 'image' &&
              <img src={uploadUrl} alt="Post content" onClick={() => g && g.galleryOpen(uploadUrl)}/>
            }

            {
              postData.uploads && !!postData.uploads.length && postData.uploads[0].uploadType === 'video' &&
              <video controls>
                <source src={uploadUrl} />
                {<Translate>Your browser does not support the video tag.</Translate>}
              </video>
            }

            {
              postData.uploads && !!postData.uploads.length && postData.uploads[0].uploadType === 'audio' &&
              <audio controls src={uploadUrl}>
                {<Translate>Your browser does not support the audio tag.</Translate>}
              </audio>
            }

            {
              postData.uploads && !!postData.uploads.length && postData.uploads[0].uploadType === 'pdf' &&
              <PdfViewer url={uploadUrl} onClick={() => g && g.galleryOpen(uploadUrl, 'pdf')}/>
            }

            { 
              displayCommentArea &&
              <div className="we-video-info">
                <ul>
                  <li>
                    <span className="comment" data-toggle="tooltip" title={<Translate>Comments</Translate>}>
                      <i className="fa fa-comments-o" />
                      <ins>{postData.comments ? postData.comments.length : 0}</ins>
                    </span>
                  </li>
                  <li>
                    <span className="like" data-toggle="tooltip" title={<Translate>like</Translate>} data-tip={getReactionList(postData)} >
                      <LoadingButton noClass noTitle iconCls={`fa ${userReactedToPost ? 'fa-heart color-red' : 'fa-heart-o'}`} onClick={addReaction} />
                      <ins>{postData.reactions ? postData.reactions.length : 0}</ins>
                    </span>
                  </li>

                  {
                    !(postData.memobject || postData.postAsMemobject) && canEdit && 
                    <li>
                      <Tag postData={postData} updatePost={updatePost} />
                    </li>
                  }

                </ul>
                {
                  !(postData.memobject || postData.postAsMemobject) &&
                  <Fragment>
                    <img className="fbshare" src={fbshare} title="Share to Facebook" alt="Share to facebook" onClick={dofbshare} />
                    <img className="fbshare" src={tweet} title="Share to Twitter" alt="Share to twitter" onClick={doTweet} />
                    <img className="fbshare" src={lnshare} title="Share to LinkedIn" alt="Share to Linkedin" onClick={doLnShare} />
                  </Fragment>
                }
              </div>
            }

          </div>
        </div>

        {
          displayCommentArea &&
          <div className="coment-area">
            <ul className="we-comet">
              {
                visibleComments && !!visibleComments.length &&
                  visibleComments.map((commentsdata, index) => {
                    return(
                      <div key={index}>
                         <li>
                            <div className="comet-avatar">
                              <div className="usericon user-avatar-image small-icon" style={{backgroundImage: `url("${getAvatar(commentsdata.postedBy.ProfileImage || null)}")`}} />
                            </div>
                            <div className="we-comment">
                              <div className="coment-head">
                                <Link to={`/userprofile/${commentsdata.postedBy._id}`} >{getName(commentsdata.postedBy)}</Link>
                                <span>{formatDateTime(commentsdata.date)}</span>
                                {
                                  canEdit &&
                                  <div className="pull-right">
                                    {/*<i className="fa fa-pencil-square-o" title= {t('postbox.comment-edit')}></i>*/}
                                    <i className="fa fa-trash" title={<Translate>Delete comment</Translate>} onClick={() => deleteComment(commentsdata._id)}></i>
                                  </div>
                                }
                                <span className="like" data-toggle="tooltip" title={t('postbox.like')} data-tip={getReactionList(commentsdata)} >
                                  <LoadingButton noClass noTitle iconCls={`fa ${userReacted(commentsdata.reactions) ? 'fa-heart color-red' : 'fa-heart-o'}`} onClick={() => addCommentReaction(commentsdata._id)} />
                                  <ins>{commentsdata.reactions ? commentsdata.reactions.length : 0}</ins>
                                </span>
                              </div>
                              <p>{commentsdata.text}</p>
                            </div>
                          </li>
                      </div>)
                    }
                  )
              }
  
              {
                postData.comments && postData.comments.length > 2 && !showAllComments &&
                <li>
                  <div onClick={() => setShowAllComments(true)} className="showmore"><Translate>More comments</Translate></div>
                </li>
              }
  
              <li className="post-comment">
                <div className="comet-avatar">
                  <div className="usericon user-avatar-image small-icon" style={{backgroundImage: `url("${getAvatar()}")`}} />
                </div>
                <div className="post-comt-box">
                  <form onSubmit={e => addComment(e, postData)}>
                    <div className="lead emoji-picker-container">
                      <input type="text" className="form-control" placeholder={t('postbox.comment-add') === 'postbox.comment-add' ? 'Add Comment' : t('postbox.comment-add')} data-emojiable="true" required value={comment} onChange={(e)=>setComment(e.target.value)}/>
                    </div>
                    <LoadingButton title={t('postbox.comment-add')} noClass noTitle iconCls="fa fa-paper-plane" className="post-button" loading={loading} />
                  </form>
                </div>
              </li>
            </ul>
  
          </div>
        }

      </div>

      <ReactTooltip effect="solid" multiline />
    </div>
  );
}
