import React from 'react';
import { Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import TextArea from '../../Components/Form/TextArea';
import { required } from '../../Util/form';
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import moment from 'moment';
import csc from 'country-state-city';
import { useTranslation } from "react-i18next";
import AddForm from '../../Components/Form/AddForm';
import CityStateCountry from '../../Components/Form/CityStateCountry';

export default function Education({user, update, readOnly=false}){
  const { handleError } = usePopupContext();
  const {t} = useTranslation('common');
  const onSubmit = (data, form) => {
    return request('honoree.update', {Educations: [...user.Educations, data], _id: user._id})
      .then(res => {
        const usr = {...user, ...res};
        update(usr);
      })
      .catch(handleError);
  };

  const remove = index => {
    return request('honoree.update', {Educations: [...user.Educations.slice(0, index), ...user.Educations.slice(index+1)], _id: user._id})
      .then(res => {
        const usr = {...user, ...res};
        update(usr);
      })
      .catch(handleError);
  };

  return (
    <div className="central-meta">
      <div className="editing-interest">
        <h5 className="f-title"><i className="ti-mouse-alt" /> {t('honoree.education&Work')}</h5>

        <ol>
          {
            user && user.Educations.map((obj, index) => (
              <li key={index}>
                <div>
                  {moment(obj.From).format('MMMM YYYY')} - {moment(obj.To).format('MMMM YYYY')} <br/>
                  {obj.CountryId && csc.getCountryById(obj.CountryId.toString()).name}, {obj.StateId && `${csc.getStateById(obj.StateId.toString()).name}, `} {obj.City} <br/>
                  {obj.SchoolName}: {obj.Course} <br/>
                  {obj.About}
                  {
                    !readOnly &&
                    <span className="remove" title={t('honoree.remove')} onClick={() => remove(index)}>
                      <i className="fa fa-close" />
                    </span>
                  }
                </div>
              </li>
            ))
          }
        </ol>

        <AddForm readOnly={readOnly} onSubmit={onSubmit}>

          <div className="form-row">
            <div className="col">
              <Field component={TextField}
                     name="SchoolName"
                     label={t('honoree.schoolEmployerName')}
                     validate={required}
                     required
              />
            </div>
            <div className="col">
              <Field component={TextField}
                     name="Course"
                     label={t('honoree.coursePositionName')}
                     validate={required}
                     required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="col">
              <Field component={TextField}
                     name="From"
                     label={t('honoree.from')}
                     type="date"
                     validate={required}
                     required
              />
            </div>
            <div className="col">
              <Field component={TextField}
                     name="To"
                     label={t('honoree.to')}
                     type="date"
                     validate={required}
                     required
              />
            </div>
          </div>

          <CityStateCountry />

          <Field component={TextArea}
                 name="About"
                 label={t('honoree.about')}
          />

        </AddForm>

      </div>
    </div>
  );
}
