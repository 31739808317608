import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import DateField from '../../Components/Form/DateField';
import TextArea from '../../Components/Form/TextArea';
import { required } from '../../Util/form';
import { useTranslation } from "react-i18next";
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import LoadingButton from '../../Components/LoadingButton';
import CityStateCountry from '../../Components/Form/CityStateCountry';
import moment from 'moment';

export default function PersonalInfo({ user, update, readOnly = false }) {
  const { handleError } = usePopupContext();
  const { t } = useTranslation('common');
  const history = useHistory();

  const editMode = !!user && !readOnly;

  const onSubmit = data => {
    return request(editMode ? 'honoree.update' : 'honoree.create', data)
      .then(res => editMode ? update(res) : history.push('/honorees'))
      .catch(handleError);
  };

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-paw" /> {t('honoree.petInformation')}</h5>

        <Form
          onSubmit={onSubmit}
          initialValues={{
            HonoreeType: 'Pet', ...user,
            DOB: user ? moment(user.DOB).format('MM/DD/YYYY') : '',
            DOD: user ? moment(user.DOD).format('MM/DD/YYYY') : ''
          }}
          render={({ handleSubmit, submitting, values, pristine }) => {
            return (
              <form onSubmit={handleSubmit}>

                <Field component={TextField}
                  name="FirstName"
                  label={t('honoree.name')}
                  validate={required}
                  required
                  readOnly={readOnly}
                />

                <Field component={TextField}
                  name="LastName"
                  label={t('honoree.breed')}
                  validate={required}
                  required
                  readOnly={readOnly}
                />

                <div className="form-row">
                  <div className="col">
                    <Field component={DateField}
                      name="DOB"
                      label={t('fields.dob')}
                      type="text"
                      validate={required}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={DateField}
                      name="DOD"
                      label={t('honoree.dateOfDeath')}
                      type="text"
                      validate={required}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <CityStateCountry
                  values={values}
                  readOnly={readOnly}
                  cityProp="POD"
                  stateProp="SOD"
                  countryProp="COD"
                />

                <Field component={TextArea}
                  name="About"
                  label={t('honoree.aboutThePet')}
                  readOnly={readOnly}
                />

                {
                  !readOnly &&
                  <div className="submit-btns text-center">
                    <LoadingButton iconCls="fa fa-save" loading={submitting} disabled={pristine} title={t('honoree.save')} />
                  </div>
                }
              </form>
            )
          }}
        />
      </div>
    </div>
  );
}