import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BlogThumnbail from '../../assets/images/blogThumbnail.jpeg';
import { request } from '../../Services/networkService';
import { formatDate, getImageUrl } from '../../Util/common';

const Cards = () => {
    const [blogs, setBlogs] = useState([]);

    const listBlogs = () => {
        request('public.listBlogs')
        .then(result => setBlogs(result))
    }

    useEffect(() => {
        listBlogs();
    }, [])

    return (
        <div className='cardsContainer'>
            {
                blogs && blogs.length ?
                    blogs.map(blog => 
                        <div className='card' key={blog.id}>
                            <Link to={`/blog/${blog.URL}`}>
                                <div className='thumbnail'>
                                    {
                                        blog.uploads?.length ? 
                                        <img src={getImageUrl(blog.uploads[0].url)} loading='lazy' alt='Blog__Card'/>
                                        :
                                        <img src={BlogThumnbail} loading='lazy' alt='Blog__Card'/>
                                    }
                                </div>
                            </Link>
                            <div className='cardBody'>
                                <div className='title'>
                                    <h5>{blog.title}</h5>
                                </div>
                                {/* <div className='details'>
                                    <span><i className='ti-user'></i>&nbsp;<b>MK - Administration</b></span>
                                    <span><i className='ti-calendar'></i>&nbsp;<b>{formatDate(blog.createdAt)}</b></span>
                                </div> */}
                                <div className='redirect'>
                                    <Link to={`/blog/${blog.URL}`} >Read More</Link>
                                </div>
                            </div>
                        </div>
                    )
                :
                <div className='content-container'>
                    <h2>Currently, there are no blogs to be shown {`:(`}</h2>
                </div>
            }
        </div>
    );
}

export default Cards;
