import React, { Fragment, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { usePopupContext } from '../../Contexts/popupContext';
import { useUserContext } from '../../Contexts/userContext';
import { findLanguage, getImageUrl, getName, userIsAdmin } from '../../Util/common';
import { useGalleryContext } from '../../Contexts/galleryContext';
import Memobjects from '../Memobjects';
import { request } from '../../Services/networkService';
import { useTranslation } from "react-i18next";
import LoadingButton from '../../Components/LoadingButton';
import { Translate, Translator } from 'react-auto-translate/lib/commonjs';
import { CONFIG } from '../../Config';
import Fundraising from '../Fundraising';

export default function Header({asUser, userPage, memobjects=false, refreshPosts, refresh, setRefreshMemobjects}) {
  const { galleryOpen } = useGalleryContext();
  const { handleError, popupCropperOpen, popupMessageOpen } = usePopupContext();
  const { user, setUser, setTargetUser, getObjectAvatar } = useUserContext();
  const {t} = useTranslation('common');
  const [loading, setLoading] = useState(false);

  const currentUser = asUser || user;
  const isOwner = asUser && (asUser.Owner === user._id || asUser.groupRelation === 'Moderator');
  const readOnly = !isOwner && !!asUser;
  const backgroundImageUrl = userPage ? userPage.BackgroundImage : currentUser.BackgroundImage;
  const profileImageUrl = userPage ? getObjectAvatar(userPage) : getObjectAvatar(currentUser);
  const backgroundImage = backgroundImageUrl ? getImageUrl(backgroundImageUrl) : `${process.env.PUBLIC_URL}/images/background.jpg`;
  const [lng, setLng] = useState('en');


  const uploader = type => {

    const options = type === 'Profile' ? 
      {
        viewport: {width: 150, height: 150},
        size: {width: 800, height: 800}
      } : {
        viewport: {width: 400, height: 132},
        size: {width: 1200, height: 400}
      };

    popupCropperOpen(options)
      .then(file => {
        const api = userPage ? 'page' : (isOwner ? 'honoree' : 'user');
        const id = userPage ? userPage._id : currentUser._id;

        let formData = new FormData();
        formData.append('id', id);
        formData.append('file', file);

        return request(`${api}.upload${type}Pic`, formData)
          .then(res => userPage ? refresh() : (asUser ? setTargetUser({ ...asUser, ...res }) : setUser({ ...user, ...res })))
          .catch(handleError);
      }).catch(() => {});
  };

  const sendGroupRequest = () => {
    setLoading(true);
    request('group.request', {groupId: asUser.OwnGroup})
      .then(() => {
        refresh && refresh();
        refreshPosts && refreshPosts();
      })
      .catch(handleError)
      .finally(() => setLoading(false))
  };

  const checkSecondNames = (cUser) => {
    if(cUser._FirstName && cUser._LastName) return true;
    else return false;
  }

  useEffect(() => {
    const TwoFAReminderClicked = localStorage.getItem('TwoFAReminderClicked');
    if(!TwoFAReminderClicked) {
      currentUser.TwoFA?.GoogleAuthenticator === false && currentUser.TwoFA?.Email === false && 
      popupMessageOpen('', <Translate>Please, set up Two-Factor Authentication!</Translate>)

      localStorage.setItem('TwoFAReminderClicked', true)
    }
  }, []);

  useEffect(() => {
    let language = user.Language;
    if(language === 'enus') setLng('en');
    else setLng(findLanguage(language))
  }, [user.Language])

  return(
    <section>
      <Translator
        from='en'
        to={lng}
        googleApiKey={CONFIG.GOOGLE_TRANSLATE_KEY}
      >
      <div className="feature-photo">
        <figure>
          <div className="feature-photo-image" style={{backgroundImage: `url("${backgroundImage}")`}} onClick={() => galleryOpen(backgroundImage)}/>
        </figure>

        { memobjects && <Memobjects honoree={asUser} refreshPosts={refreshPosts} setRefreshMemobjects={setRefreshMemobjects} /> }
        {/*<div className="add-btn">
          <span>0 followers</span>
        </div>*/}

        {
          !readOnly && <form className="edit-phto" onClick={() => uploader('Background')}>
            <i className="fa fa-camera-retro" />
            <label className="fileContainer" >
              {/* {t('header.background-photo')} */}
              <Translate>Edit background photo</Translate>
              {/*<input type="file" accept="image/*" name="Image" onChange={uploader(userPage ? 'page' : (isOwner ? 'honoree' : 'user'), 'Background', userPage ? userPage._id : currentUser._id)} />*/}
            </label>
          </form>
        }

        <div className="container-fluid">
          <div className="row merged">

            <div className="col-lg-2">
              <div className="user-avatar">
              <figure>
                <div className="user-avatar-image" style={{backgroundImage: `url("${profileImageUrl}")`}} onClick={() => galleryOpen(profileImageUrl)}/>

                {
                  !readOnly && <form className="edit-phto" title={ userPage ? t('header.edit-logo') : t('header.edit-profile-photo')} onClick={() => uploader('Profile')}>
                    <i className="fa fa-camera-retro" />
                    <label className="fileContainer">
                      {/*<input type="file" accept="image/*" name="Image" onChange={uploader(userPage ? 'page' : (isOwner ? 'honoree' : 'user'), 'Profile', userPage ? userPage._id : currentUser._id)} />*/}
                    </label>
                  </form>
                }

              </figure>
              </div>
            </div>

            {!userPage && <div className="col-lg-10">
              <div className="timeline-info" data-tut="reactour__nameandlinks">
                <ul>
                  <li className="admin-name" >
                    <h5>{getName(currentUser)}</h5>
                    <span>{asUser && asUser._type === "honoree" ? checkSecondNames(currentUser) ? `${currentUser._FirstName} ${currentUser._LastName} ${currentUser.DOB} - ${currentUser.DOD}` : `${currentUser.DOB} - ${currentUser.DOD}` : <Translate>Group Admin</Translate>}</span>
                  </li>

                  {
                    asUser && asUser._type === "honoree" &&
                    <Fragment>
                      <li>
                        <NavLink activeClassName="active" exact to={`/honoree/${asUser.Alias ? asUser.Alias: asUser._id}`} title="Here you provide information about yourself"><Translate>Profile</Translate></NavLink>
                        <NavLink activeClassName="active" exact to={`/honoreetimeline/${asUser.Alias ? asUser.Alias: asUser._id}`} title="Here you view all posts from group members for the honoree/pet. Posts in timeline are divided into two tabs - Honoree Timeline & Pets Timeline"><Translate>Timeline</Translate></NavLink>
                        <NavLink activeClassName="active" exact to={`/honoreephotos/${asUser.Alias ? asUser.Alias: asUser._id}`} title="Here you view all photos from group members for the honoree/pet"><Translate>Photos</Translate></NavLink>
                        <NavLink activeClassName="active" exact to={`/honoreetimeline/${asUser.Alias ? asUser.Alias: asUser._id}/members`} title="Here you view & invite family members and friends to join the honoree/pets groups"><Translate>Group Members</Translate></NavLink>
                        <NavLink activeClassName="active" to={`/honoreetimeline/${asUser.Alias ? asUser.Alias: asUser._id}/calendar`} title="Calendar"><Translate>Calendar</Translate></NavLink>
                      </li>
                      {
                        !asUser.groupRelation &&
                        <LoadingButton 
                          className="header-join-btn" 
                          title={t('posts.group-join')} 
                          onClick={sendGroupRequest} 
                          loading={loading}
                        />
                      }
                    </Fragment>
                  }

                  {
                    asUser && asUser._type === "user" &&
                    <Fragment>
                      <li>
                        <NavLink activeClassName="active" to={`/userprofile/${asUser._id}`} title="Here you provide information about yourself"><Translate>Profile</Translate></NavLink>
                        <NavLink activeClassName="active" to={`/usertimeline/${asUser._id}`} title="Here you view all posts - public & from groups you are a member of. Posts in timeline are divided into two tabs - Honoree Timeline & Pets Timeline"><Translate>Timeline</Translate></NavLink>
                        <NavLink activeClassName="active" to={`/usergroups/${asUser._id}`} title="Groups"><Translate>Groups</Translate></NavLink>
                      </li>
                      {
                        !asUser.groupRelation && asUser.Access && 
                        <LoadingButton 
                          className="header-join-btn" 
                          title={t('header.become-friends')} 
                          onClick={sendGroupRequest} 
                          loading={loading}
                        />
                      }
                    </Fragment>
                  }

                  {
                    !(isOwner || asUser) &&
                    <li>
                      <NavLink className="tour-timeline" activeClassName="active" to="/timeline" title="Here you view all posts - public & from groups you are a member of. Posts in timeline are divided into two tabs - Honoree Timeline & Pets Timeline"><Translate>Timeline</Translate></NavLink>
                      <NavLink className="tour-honorees" activeClassName="active" to="/honorees" title="Here you create an honoree profile for deceased loved ones or pets which you wish to commemorate"><Translate>Honorees</Translate></NavLink>
                      <NavLink className="tour-friends" exact activeClassName="active" to="/profile/friends" title="Here you view & invite your friends to join your groups & pages"><Translate>Friends</Translate></NavLink>
                      <NavLink className="tour-pages" activeClassName="active" to="/pages" title="Here you create business & celebrity pages to showcase products or services"><Translate>Pages</Translate></NavLink>
                      <NavLink className="tour-groups" activeClassName="active" to="/groups" title="Here you can view all groups you have created or are a member of"><Translate>Groups</Translate></NavLink>
                      <NavLink className="tour-requests" activeClassName="active" to="/requests" title="Here you can view the members of groups you have created/administer. From here you can accept/reject requests for group membership & assign users as Moderators to your groups. When you hover the mouse over a symbol a popup will show the corresponding function"><Translate>Requests</Translate></NavLink>
                      <NavLink className="tour-calendar" activeClassName="active" to="/timeline/calendar" title="Here you view & schedule events from all groups you are a member of"><Translate>Calendar</Translate></NavLink>
                      <NavLink className="tour-advertise" activeClassName="active" to="/advertise" title="Here you can post advertisements showcasing products or services. Advertisements can be posted either in the timeline or into advertisement fields on the right sidebar"><Translate>Advertise</Translate></NavLink>
                    </li>
                  }

                </ul>
              </div>
            </div>}

            {
              userPage && <div className="col-lg-10">
                <div className="timeline-info" data-tut="reactour__nameandlinks">
                  <ul>
                    <li className="admin-name" >
                      <h5>{userPage.Name}</h5>
                      <span>{userPage.Description}</span>
                    </li>

                    <li>
                      <NavLink activeClassName="active" exact to={`/userpage/${userPage.Alias ? userPage.Alias: userPage._id}`} title="Here you view all posts - public & from groups you are a member of. Posts in timeline are divided into two tabs - Honoree Timeline & Pets Timeline"><Translate>Timeline</Translate></NavLink>
                      <NavLink activeClassName="active" exact to={`/userpage/${userPage.Alias ? userPage.Alias: userPage._id}/members`} title="Group Members"><Translate>Group Members</Translate></NavLink>
                      <NavLink activeClassName="active" exact to={`/userpage/${userPage.Alias ? userPage.Alias: userPage._id}/calendar`} title="Calendar"><Translate>Calendar</Translate></NavLink>
                      <NavLink activeClassName="active" exact to={`/pageadvertise/${userPage.Alias ? userPage.Alias: userPage._id}`} title="Advertise"><Translate>Advertise</Translate></NavLink>
                      {
                        userIsAdmin(user) &&
                        <NavLink activeClassName="active" exact to={`/pages/info/${userPage.Alias ? userPage.Alias: userPage._id}`} title="Edit Page"><Translate>Edit Page</Translate></NavLink>
                      }
                    </li>

                  </ul>
                </div>
              </div>
            }

          </div>
        </div>
      </div>
    </Translator>
    </section>
  );
}
