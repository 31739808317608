import React, { useRef } from 'react';
import ReactTable from '../../Components/ReactTablePagination';
import { usePopupContext } from '../../Contexts/popupContext';
import { formatDateTime, formatCurrency, getImageUrl } from '../../Util/common';
import { useGalleryContext } from '../../Contexts/galleryContext';
import { request } from '../../Services/networkService';
import { useTranslation } from "react-i18next";

export default function Purchases(){
  const {t} = useTranslation('common');
  const { handleError, popupConfirmOpen } = usePopupContext();
  const { galleryOpen } = useGalleryContext();
  const tableRef = useRef();

  const columns = [{
    Header: '',
    accessor: 'memobjectUrl',
    width: 60,
    Filter: () => {},
    Cell: ({value}) => {
      if (value) {
        const imageUrl = getImageUrl(value);
        return (<div onClick={() => galleryOpen(imageUrl)} className="grid-row-img" style={{backgroundImage: `url("${imageUrl}")`}}></div>);
      } else {
        return (<div style={{ textAlign: 'center' }}><i className='fa fa-diamond' style={{ color: '#04A5C0' }}/></div>);
      }
    }
  },{
    Header: t('purchases.date'),
    accessor: 'date',
    Cell: ({value}) => formatDateTime(value),
    width: 160
  },{
    Header: t('purchases.expiry'),
    accessor: 'expiry',
    Cell: ({value}) => formatDateTime(value),
    width: 160
  },{
    Header: t('purchases.type'),
    accessor: 'type'
  },{
    Header: t('purchases.posted'),
    accessor: 'honoreeNames'
  },{
    Header: t('purchases.autoRenew'),
    accessor: 'autoRenew',
    Cell: ({value}) => value === true ? 'Yes' : 'No',
    width: 120
  },{
    Header: t('purchases.price'),
    accessor: 'price',
    Cell: ({value}) => formatCurrency(value),
    width: 70
  }];

  const actions = [
    {
      iconcls: 'fa fa-stop-circle-o',
      onClick: (row, {viewIndex}) => popupConfirmOpen(t('purchases.confirm'), t('purchases.cancelSubs'))
        .then(
          () => request('payment.stopRenew', {saleId: row._id})
          .then(res => tableRef.current.updateSingleRow(viewIndex, res))
        ).catch(()=>{}),
      title: "Stop auto renew",
      condition: row => row.autoRenew
    }
  ];

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-object-group" /> {t('purchases.history')}</h5>

        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'payment.purchases'}
          disableFilters
          onError={handleError}
          defaultSorted={[{
            id: 'date',
            desc: true
          }]}
        />

      </div>
    </div>
  );
}
