import React from 'react';
import Editor from '../../Components/ArrayEditor';
import { useTranslation } from "react-i18next";

export default function Membership({user, update, readOnly=false}){
  const {t} = useTranslation('common');

  return (
    <Editor
      api="user.update"
      iconCls="ti-package"
      prop="Membership"
      placeholder={t('profile.membershipExample')}
      user={user}
      update={update}
      readOnly={readOnly}
      title={t("profile.membershipsSidebar")}
    />
  );
}
