import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { 
        composeValidators, 
        required, 
        requiredTrue, 
        minLength, 
        validatePassword, 
        validateEmail, 
        samePassword, 
        validateNumber } from '../../Util/form';
import TextField from '../../Components/Form/TextField';
import CheckBox from '../../Components/Form/CheckBox';
import PasswordStrength from '../../Components/PasswordStrength';
import { register } from '../../Services/userService';
import { request, saveTrackingData } from '../../Services/networkService';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { usePopupContext } from '../../Contexts/popupContext';
import { getRememberMe, setRememberMe, getTwoFAReminder } from '../../Util/storage';
import { useUserContext } from '../../Contexts/userContext';
import LoadingButton from '../../Components/LoadingButton';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import RadioGroup from '../../Components/Form/RadioGroup';
import { isAppAuthAvailable, isEmailAuthAvailable, isTwoFAFull } from './TwoFAActions';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function AuthModal({ authRef, authVariant = 0 }) {
  const history = useHistory();
  const { handleError, popupMessageOpen } = usePopupContext();
  const {t} = useTranslation('common');
  const { user, setUser } = useUserContext();
  const [userInfo, setUserInfo] = useState();
  const [userTkn, setUserTkn] = useState('');
  // Two-Factor Authentication
  const [TwoFA, setTwoFA] = useState(false);
  const [authType, setAuthType] = useState('');
  const [eToken, setEToken] = useState('');
  const [chooseTwoFAOpt, setChooseTwoFA] = useState(false);
  const [processingData, setProcessingData] = useState(null);
  const [email2FaBody, setEmail2FaBody] = useState({});
  // Resend 2FA code | Timer
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  // END  
  const hasError = new URLSearchParams(useLocation().search).get('error');

  function closeModal() {
    return authRef.current?.close();
  }

  function authErrorHandling(err) {
    closeModal();
    return handleError(err);
  }

  useEffect(() => {
    if(hasError) {
        closeModal();
        popupMessageOpen('Error', 'Error registering with this account, probably already registered with the same username?');
    }
  }, [hasError]);

  useEffect(() => {
    let timerId;

    if (isTimerRunning && timeLeft > 0) {
      timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsTimerRunning(false);
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [isTimerRunning, timeLeft]);

  const resendMail = data => {
    return request('public.resendActivationMail', {Email: data.Email})
      .then(() => closeModal())
      .then(() => popupMessageOpen(t('popupcontext.msg'), t('statusCodes.12')))
      .catch((e) => authErrorHandling(e))
  };

  const googleAuth = (data) => {
    setAuthType('Google');
    setTwoFA(true);
    setUserInfo({ ...data.user });
    setUserTkn(data.token);
    saveTrackingData(data.user, '/login');
  }

  const emailAuth = (data) => {
    const selectedUser = data.user;
    const body = {
      id: selectedUser._id,
      fName: selectedUser.First_Name,
      lName: selectedUser.Last_Name,
      email: selectedUser.Email,
      secret: selectedUser.TwoFA.eToken,
      firstTime: false
    };

    request('user.generateTOTP', { id: body.id })
    .then(generated => {
      setEToken(generated.eToken);
      body.secret = generated.eToken;
      request('user.sendTOTP', body)
      .then(res => {
        setAuthType('Email');
        setTwoFA(true);
        setUserInfo({ ...data.user });
        setUserTkn(data.token);
        saveTrackingData(selectedUser, '/login');
      })
      .catch((err) => authErrorHandling(err));
      setEmail2FaBody(body);
    })
    .catch((err) => authErrorHandling(err));
  }

  const resendEmail2FA = () => {
    if(Object.keys(email2FaBody).length) {
      request('user.sendTOTP', email2FaBody)
      .catch((err) => authErrorHandling(err));
      setTimeLeft(60);
      setIsTimerRunning(true);
    }
  }

  function onSelectTwoFA(data) {
    const selectedOption = data?.authType;
    setChooseTwoFA(false);
    if(processingData !== null) {
        if(selectedOption === 'Google') googleAuth(processingData);
        else if(selectedOption === 'Email') emailAuth(processingData);
    }
  }

  const onSubmit = data => {
    setRememberMe(data.remember);
    getTwoFAReminder();
    return request('user.login', data).then((data) => {
      if(data.user && data.user.Blocked) {
        closeModal();
        popupMessageOpen(t('popupcontext.msg'), "Account Temporarily Suspended. For more details, please contact support.");
      } else {
        if(isTwoFAFull(data.user.TwoFA)) {
          // popupChooseTwoFAOpen('Auth app | Email', chooseTwoFA())
          // .then(aType => {
          //   if(aType.authType === 'Google') googleAuth(data);
          //   else if(aType.authType === 'Email') emailAuth(data);
          // })
          // .catch((e) => authErrorHandling(e));
          setChooseTwoFA(true);
          setProcessingData(data);
        } else if(isAppAuthAvailable(data.user.TwoFA)) {
          googleAuth(data);
        } else if(isEmailAuthAvailable(data.user.TwoFA)) {
          setTimeLeft(60);
          setIsTimerRunning(true);
          emailAuth(data);
        } else {
          setUser({ ...data.user, token: data.token });
          saveTrackingData(data.user, '/login');
          if(!getTwoFAReminder()) history.push({pathname: '/timeline'});
          else history.push({pathname: '/profile/twoFA'});
        }
      }
    }).catch(e => {
        if(e && e.response && e.response.data && e.response.data.statusCode === 11) {
            closeModal();
            popupMessageOpen(t('popupcontext.msg'), t('statusCodes.11'), (<Button onClick={() => resendMail(data)}>{t('popupcontext.resend')}</Button>));
        } else authErrorHandling(e)
    });
  };

  const onSubmitTwoFA = data => {
    const eData = { eToken: eToken, otp: data.token };

    const gData = { gToken: userInfo.TwoFA.gToken, token: data.token };

    setRememberMe(data.remember);

    if(authType === 'Google') {
      return request('user.verifyGAuth', gData)
      .then(async (res) => {
        if(res) {
          await setUser({ ...userInfo, token: userTkn });
          history.push({pathname: '/timeline'}) 
        } else {
            closeModal();
            popupMessageOpen(t('popupcontext.msg'), t('statusCodes.20')) 
        }
      }).catch(e => {
        if(e && e.response && e.response.data && e.response.data.statusCode === 11) {
            closeModal();
            popupMessageOpen(t('popupcontext.msg'), t('statusCodes.11'), (<Button onClick={() => resendMail(data)}>{t('popupcontext.resend')}</Button>));
        } else authErrorHandling(e)
      });
    } else if(authType === 'Email') {
      return request('user.verifyTOTP', eData)
      .then(async (res) => {
        if(res) {
          await setUser({ ...userInfo, token: userTkn });
          history.push({pathname: '/timeline'}) 
        } else {
          closeModal();
          popupMessageOpen(t('popupcontext.msg'), t('statusCodes.20')) 
        }
      }).catch(e => {
        if(e && e.response && e.response.data && e.response.data.statusCode === 11) {
            closeModal();
            popupMessageOpen(t('popupcontext.msg'), t('statusCodes.11'), (<Button onClick={() => resendMail(data)}>{t('popupcontext.resend')}</Button>));
        } else authErrorHandling(e)
      });
    }
  };

  const onSubmitRegister = data => {
    return register(data)
        .then(() => authRef.current?.close())
        .then(() => popupMessageOpen(t('register.success'), t('register.confirm')))
        .finally(() => history.push({pathname: '/'}))
        .catch((e) => authErrorHandling(e));
  };

  return (
    <dialog ref={authRef} className="authModal">
        <div className="text-right closeButton">
            <i className='ti-close' onClick={() => authRef.current?.close()}></i>
        </div>
        {
            authVariant === 0 ?
            <div className="log-reg-area sign submit-btns">
                <h2 className="log-title">{t('login.Login')}</h2>
                {
                TwoFA?
                    <Form
                    onSubmit={onSubmitTwoFA}
                    initialValues={{remember: getRememberMe()}}
                    render={({ handleSubmit, submitting}) => {
                        return (
                        <form onSubmit={handleSubmit}>
                            <Field component={TextField}
                            name="token"
                            label='2FA token'
                            required={required}
                            autoComplete="off"
                            maxLength="6"
                            onKeyPress={(event) => {
                                if(validateNumber(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            />
                            <Link to="/forgot" className="forgot-pwd">{t('login.forgot')}? </Link>
                            <Field component={CheckBox}
                            name="remember"
                            label={t('fields.remember')}
                            type="checkbox"
                            onChange={e => setRememberMe(e.target.checked)}
                            />
                            <div className="submit-btns text-center">
                              <LoadingButton title='BACK' onClick={() => setTwoFA(false)} />
                              <LoadingButton className='logBtn' title={t('login.LOGIN')} loading={submitting} tooltip="Click here to login to your account" />
                            </div>
                            <div className='submit-btns text-center'>
                              <LoadingButton className='logBtn' disabled={isTimerRunning} onClick={() => resendEmail2FA()} title={isTimerRunning ? <Translate>Resend after: {timeLeft}</Translate> : <Translate>Resend email</Translate>} />
                            </div>
                        </form>
                    )}}
                    />
                : chooseTwoFAOpt ?
                <Form
                onSubmit={onSelectTwoFA}
                initialValues={{remember: getRememberMe()}}
                render={({ handleSubmit, submitting}) => {
                    return (
                    <form onSubmit={handleSubmit}>
                        <h6>{t('profile.selectSetting')}</h6>
                        <Field component={RadioGroup}
                            name="authType"
                            options={[{ name: 'Auth app', value: 'Google' }, { name: 'Email', value: 'Email' }]}
                            validate={required}
                            required
                        />
                        <div className="submit-btns text-center">
                            <LoadingButton title='SELECT' loading={submitting} tooltip="Click here to login to your account" />
                        </div>
                    </form>
                )}}
                /> 
                :
                <Form
                    onSubmit={onSubmit}
                    initialValues={{remember: getRememberMe(), twoFAReminder: getTwoFAReminder()}}
                    render={({ handleSubmit, submitting}) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Field component={TextField}
                                name="Email"
                                label={t('fields.Username')}
                                type="email"
                                validate={required}
                                required
                            />
                            <Field component={TextField}
                                name="Password"
                                label={t('fields.Password')}
                                type="password"
                                validate={required}
                                required
                            />
                            <Link to="/forgot" className="forgot-pwd">{t('login.forgot')}? </Link>
                            <Field component={CheckBox}
                                style={{ marginBottom: "0", marginTop: "0" }}
                                name="remember"
                                label={t('fields.remember')}
                                type="checkbox"
                                onChange={e => setRememberMe(e.target.checked)}
                            />
                            <div className="submit-btns text-center">
                                <LoadingButton className='logBtn' title={t('login.LOGIN')} loading={submitting} tooltip="Click here to login to your account" />
                            </div>
                        </form>
                    )}}
                />
                }
            </div>
            :
            <div className="log-reg-area sign">
                <h2 className="log-title">{t('register.joinNow')}</h2>
                <p>
                {t('register.foreverFree')}
                </p>
                <Form
                onSubmit={onSubmitRegister}
                initialValues={{Gender: 'Male'}}
                render={({ handleSubmit, submitError, submitErrors, submitting, values}) => {
                    return (
                    <form onSubmit={handleSubmit}>

                        <Field component={RadioGroup}
                            name="Gender"
                            options={[{name: t('honoree.mr'), value: 'Male'}, {name: t('honoree.mrs'), value: 'Female'}]}
                            validate={required}
                            required
                        />

                        <div className="form-row">
                        <div className="col">
                            <Field component={TextField}
                                name="First_Name"
                                label={t('fields.firstName')}
                                validate={composeValidators(required, minLength(2))}
                                required
                            />
                        </div>
                        <div className="col">
                            <Field component={TextField}
                                name="Last_Name"
                                label={t('fields.lastName')}
                                validate={required}
                                required
                            />
                        </div>
                        </div>

                        <Field component={TextField}
                            name="Email"
                            label={t('fields.emailAddress')}
                            type="email"
                            validate={composeValidators(required, validateEmail)}
                            required
                        />

                        <div className="form-row">
                        <div className="col">
                            <Field component={TextField}
                                name="Mobile_No"
                                label={t('fields.mobile')}
                                type="number"
                                validate={required}
                                required
                            />
                        </div>
                        <div className="col">
                            <Field component={TextField}
                                name="DOB"
                                label={t('fields.dob')}
                                type="date"
                                validate={required}
                                required
                            />
                        </div>
                        </div>

                        <div className="form-row">
                        <div className="col">
                            <Field component={TextField}
                                name="Password"
                                label={t('fields.Password')}
                                type="password"
                                validate={composeValidators(required, minLength(8), validatePassword)}
                                children={<PasswordStrength password={values.Password} />}
                                required
                            />
                        </div>
                        <div className="col">
                            <Field component={TextField}
                                name="Confirm_Password"
                                label={t('fields.confirm')}
                                type="password"
                                validate={(value, values) => samePassword(value, values.Password) }
                                required
                            />
                        </div>
                        </div>

                        <Field component={CheckBox}
                            name="remember"
                            type="checkbox"
                            required
                            validate={requiredTrue}
                            label={ 
                                <Fragment> 
                                    <span>{t('register.agree')} </span> 
                                    <a href="/policies/terms" target="new">{t('register.terms')}</a>
                                </Fragment>
                                }
                        />

                        <div className="submit-btns text-center">
                        <LoadingButton title={t('login.join')} loading={submitting} />
                        </div>
                    </form>
                )}}
                />
            </div>
        }
    </dialog>
  );
}