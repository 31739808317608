import React from 'react';
import { useUserContext } from '../../Contexts/userContext';
import { Form, Field } from 'react-final-form';
import ToggleField from '../../Components/Form/Toggle';
import { usePopupContext } from '../../Contexts/popupContext';
import { request } from '../../Services/networkService';
import { useTranslation } from "react-i18next";

export default function AccountSettings(){
  const {t} = useTranslation('common');
  const { user, setUser } = useUserContext();
  const { handleError } = usePopupContext();

  const onSubmit = data => {
    return request('user.update', data)
      .then(r => setUser({...user, ...r}))
      .catch(handleError);
  };

  return (
    <div className="central-meta">
      <div className="onoff-options">
        <h5 className="f-title"><i className="ti-settings" /> {t('profile.edit')}</h5>
        {t('profile.selectSetting')}

        <p>&nbsp;</p>

          <Form
            onSubmit={onSubmit}
            initialValues={{...user}}
            render={({ handleSubmit, submitError, submitErrors, submitting, values, pristine}) => {
              return (
                <form onSubmit={handleSubmit}>

                  <Field component={ToggleField}
                    id="AccountSetting.DefaultPostPublicState"
                    name="AccountSetting.DefaultPostPublicState"
                    label={t('profile.default')}
                    text={t('profile.defaultState')}
                    type="checkbox"
                  />

                  <div className="submit-btns text-center">
                    <button disabled={pristine || submitting} type="submit" className="mtr-btn"><span>{t('profile.updateBtn')}</span></button>
                  </div>
                </form>
            )}}
          />
      </div>
    </div>
  );
}
