import React, { Fragment } from 'react';
import PdfViewer from '../../Components/PdfViewer';
import { useGalleryContext } from '../../Contexts/galleryContext';

export default function Image({ad, borderRadius}){
  const g = useGalleryContext();

  return (
    <Fragment>
      {ad.uploadType === 'pdf' && <PdfViewer className={ad.text ? '' : 'sbr-image-notext'} url={ad.url} onClick={() => g && g.galleryOpen(ad.url, 'pdf')} />}
      {ad.uploadType === 'image' && <div className={`sbr-image ${ad.text ? '' : 'sbr-image-notext'}`} style={{backgroundImage: `url("${ad.url}")`, borderRadius: `${borderRadius}`}} onClick={() => g && g.galleryOpen(ad.url)} />}        
    </Fragment>
  );

}