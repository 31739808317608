import React, { Fragment, useEffect, useState } from 'react';
import Select from '../../Components/Form/Select';
import { Field } from 'react-final-form';
import { reportOptions } from '../../Util/constants';
import { Translate, Translator } from 'react-auto-translate/lib/commonjs';
import { CONFIG } from '../../Config';
import { useUserContext } from '../../Contexts/userContext';
import { findLanguage } from '../../Util/common';

export default function ReportDialog({ ad }) {
  const { user } = useUserContext();
  const [sel, setSel] = useState(0);
  const [lng, setLng] = useState('en');

  useEffect(() => {
    let language = user.Language;
    if(language === 'enus') setLng('en');
    else setLng(findLanguage(language))
  }, [user.Language])

  return (
    <Fragment>
      <Translator
        from='en'
        to={lng}
        googleApiKey={CONFIG.GOOGLE_TRANSLATE_KEY}
      >
        <div className="form-radio">

          <div key={0} className="radio">
            <label>
              <input
                type="radio"
                name="radio"
                value={0}
                checked={sel === 0}
                onChange={() => setSel(0)}
              />
              <i className="check-box" />
              <Translate>Just hide Ad</Translate>
            </label>
          </div>

          <div key={1} className="radio">
            <label>
              <input
                type="radio"
                name="radio"
                value={1}
                checked={sel === 1}
                onChange={() => setSel(1)}
              />
              <i className="check-box" />
              <Translate>Hide ad and report it</Translate>
            </label>
          </div>

        </div>

        {
          sel === 1 &&
          <div className="form-row">
            <Field
              component={Select}
              name="reportId"
              label={<Translate>Report Ad as</Translate>}
              options={reportOptions}
            />
          </div>
        }
      </Translator>
    </Fragment>
  );

};