import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import TextArea from '../../Components/Form/TextArea';
import LoadingButton from '../../Components/LoadingButton';
import { required } from '../../Util/form';
import { sendContactForm } from '../../Services/userService';
import { usePopupContext } from '../../Contexts/popupContext';
import Layout from '../../Components/Layout';
import envelop from '../../assets/images/envelop.png';
import { useTranslation } from "react-i18next";
import { request } from '../../Services/networkService';
import { Helmet } from 'react-helmet';
import PostalAddress from '../../Components/PostalAddress';

export default function HowItWorks() {
  const {t} = useTranslation('quicklinks');
  const { popupMessageOpen, handleError } = usePopupContext();
  const [data, setData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    request('public.listContactUs')
    .then(result => {
        if(result && result.length) setData(result[0]);
    })
    .catch((e) => handleError(e));
  }, []);

  const onSubmit = (data, form) => {
    return sendContactForm(data)
      .then(() => popupMessageOpen(t('contactUs.success'), t('contactUs.msgSent')))
      .then(() => {
        setTimeout(() => form.reset(), 0)
        window.location.href = '/thank-you'
      })
      .catch(handleError);
  };

  return (
    <Layout showHeader={false} infoPage>
      <Helmet>
          <meta name="description" content='Do you have questions about Memkeepers and how it works? Contact us and we will get in touch with you shortly.' />
          <title>Contact us | Memkeepers</title>
      </Helmet>
      <div className="container">
        <div className="contct-info">

          <div className="contact-form">
            <div className="cnt-title">
              <i><img src={envelop} alt="Envelop" /></i>
              <div>{t('contactUs.sendUsMsg')}</div>
            </div>

            <Form
              onSubmit={onSubmit}
              initialValues={{remember: true}}
              render={({ handleSubmit, submitting, pristine, form}) => {
                return (
                  <form onSubmit={e => handleSubmit(e, form)}>
                    <Field component={TextField}
                           name="Name"
                           label={t('contactUs.name')}
                           validate={required}
                           required
                    />
                    <Field component={TextField}
                           name="Email"
                           label={t('contactUs.email')}
                           type="email"
                           validate={required}
                           required
                    />
                    <Field component={TextField}
                           name="Phone_No"
                           label={t('contactUs.phone')}
                           validate={required}
                           required
                    />
                    <Field component={TextField}
                           name="Company"
                           label={t('contactUs.company')}
                    />
                    <Field component={TextArea}
                           name="Message"
                           label={t('contactUs.msg')}
                           validate={required}
                           required
                    />
                    <div className="submit-btns">
                      <LoadingButton iconCls="fa fa-paper-plane" title={t('contactUs.sendBtn')} loading={submitting} disabled={pristine} />
                    </div>
                  </form>
              )}}
            />
          </div>
          <div className="cntct-adres">
            <h3>{t('contactUs.contactInfo')}</h3>
            {
              Object.keys(data).length ?
              <>
                <ul>
                  <li>
                    <i className="fa fa-mobile-phone" />
                    <span><a href="tel:800-807-0085" target="new">{data.phone}</a></span>
                  </li>
                  <li>
                    <i className="fa fa-envelope-o" />
                    <span><a href="mailto:info@memkeepers.me" target="new">{data.email}</a></span>
                  </li>
                  <li>
                    <i className="fa fa-globe" />
                    <span><a href={data.url} target="new">{data.url}</a></span>
                  </li>
                </ul>
                <ul className="social-media">
                  <li>
                    <a href={data.fUrl} target="new"><i className="ti-facebook" /></a>
                  </li>
                  <li>
                    <a href={data.iUrl} target="new"><i className="ti-instagram" /></a>
                  </li>
                  <li>
                    <a href={data.tUrl} target="new"><i className="ti-twitter-alt" /></a>
                  </li>
                  <li>
                    <a href={data.lUrl} target="new"><i className="ti-linkedin" /></a>
                  </li>
                </ul>
              </>
              :
              <>
                <ul>
                  <li>
                    <i className="fa fa-mobile-phone" />
                    <span><a href="tel:800-807-0085" target="new">800-807-0085</a></span>
                  </li>
                  <li>
                    <i className="fa fa-envelope-o" />
                    <span><a href="mailto:info@memkeepers.me" target="new">info@memkeepers.me</a></span>
                  </li>
                  <li>
                    <i className="fa fa-globe" />
                    <span><a href="https://www.memkeepers.me" target="new">https://www.memkeepers.me</a></span>
                  </li>
                </ul>
              </>
            }
            <PostalAddress colorChanged={true}/>
          </div>

        </div>
      </div>
    </Layout>

  );
}
