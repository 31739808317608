import axios from 'axios';
import { CONFIG } from '../Config';

export function register(data) {
  return axios.post(`${CONFIG.BACKEND_URL}/register`, data);
}

export function passwordReset({Email}) {
  return axios.post(`${CONFIG.BACKEND_URL}/forgot`, { Email });
}

export function passwordResetToken(token, password) {
  return axios.post(`${CONFIG.BACKEND_URL}/reset`, {id: token, password});
}

export function sendContactForm(data) {
  return axios.post(`${CONFIG.BACKEND_URL}/contactUs`, data);
}

export function changePass(data) {
  return axios.post(`${CONFIG.BACKEND_URL}/user/changePass`, data);
}