import React from "react";

const PostalAddress = (colorChanged = false) => {
    return (
      colorChanged ?
      <address>
        <p style={{ color: '#b5f767' }}>3670 Lakeland Ln</p>
        <p style={{ color: '#b5f767' }}>Jackson, MS 39216</p>
        <p style={{ color: '#b5f767' }}>United States</p>
      </address>
      :
      <address>
        <p>3670 Lakeland Ln</p>
        <p>Jackson, MS 39216</p>
        <p>United States</p>
      </address>
    );
};

export default PostalAddress;