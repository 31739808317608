import React from 'react';
import Editor from '../../Components/ArrayEditor';
import { useTranslation } from "react-i18next";

export default function Membership({user, readOnly, update}){
  const {t} = useTranslation('common');
  return (
    <Editor
      api="honoree.update"
      iconCls="ti-package"
      prop="Membership"
      placeholder={t('honoree.clubChurch')}
      user={user}
      update={update}
      readOnly={readOnly}
    />
  );
}
