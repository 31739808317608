import React from 'react';
import { Form, Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import { required } from '../../Util/form';
import { usePopupContext } from '../../Contexts/popupContext';
import { request } from '../../Services/networkService';
import { useUserContext } from '../../Contexts/userContext';
import { useTranslation } from "react-i18next";

export default function ArrayEditor({iconCls, prop, title, placeholder, api, user, update, readOnly, children}){
  const { handleError } = usePopupContext();
  const { user:currentUser } = useUserContext();
  const text = title || prop;
  const {t} = useTranslation('common');

  const onSubmit = (data, form) => {
    return request(api, {[prop]: [...user[prop], data.info], _id: user._id})
      .then(res => {
        const usr = {...user, ...res};
        update(usr);
        setTimeout(() => form.reset(), 0);
      })
      .catch(handleError);
  };

  const remove = index => {
    return request(api, {[prop]: [...user[prop].slice(0, index), ...user[prop].slice(index+1)], _id: user._id})
      .then(res => {
        const usr = {...user, ...res};
        update(usr);
      })
      .catch(handleError);    
  };

  return (
    <div className="central-meta">
      <div className="editing-interest">
        <h5 className="f-title"><i className={iconCls} /> {text}</h5>  

        <ol>
        {
          user && user[prop].map((item, index) => (
            <li key={index}>
              <div>
                {item}
                {
                  !readOnly &&
                  <span className="remove" title="Remove" onClick={() => remove(index)}>
                    <i className="fa fa-close" />
                  </span>
                }
              </div>
            </li>
          ))
        }
        </ol>  

        {
          (!user || !user[prop].length) && <center>{user && currentUser._id === user._id ? "You" : "The user"} have not added any {prop} information</center>
        }

        {
          !readOnly &&
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, pristine, form, values}) => {
              return (
                <form onSubmit={e => handleSubmit(e, form)}>
                  <Field component={TextField}
                         name="info"
                         label={`${t("reacttable.add")} ${text}`}
                         validate={required}
                         required
                         placeholder={placeholder}
                         btnProps={{
                          title: t("reacttable.add"),
                          loading: submitting
                         }}
                  />
                </form>
            )}}
          />
        }     

        {children}  

      </div>
    </div>
  );
}