import React, { Fragment } from 'react';
import { withRouter, Route } from 'react-router-dom';
import FeedLeft from '../FeedLeft';

export default withRouter(function SideBarLeft({ match, history, location: { pathname }, config, title, extraPanels }) {
  const routePathArr = match.path.split('/');
  const currentPath = pathname
    .split('/')
    .reduce((acc, curr, idx) => 
      (curr === routePathArr[idx] || (routePathArr[idx] && routePathArr[idx][0] === ':')) ? 
        acc : [...acc, curr], [])
    .join('/')
    .replace(/(^\/)|(\/$)/g, "");
  const activeIdx = config.findIndex(o => o.path === currentPath);
  const active = activeIdx > -1 ? activeIdx : 0;

  return (
    <Fragment>
      <div className="col-lg-3 feed-left-container">
        <FeedLeft config={config} title={title}/>
      </div>

      <div className={`${ config[active].showAds ? "col-lg-9" : "col-lg-12"} central-meta-container`}>
        {
          config && config.length && config.map((item, index) => (
            <Fragment key={index}>
              <Route path={`${match.path}/${item.path}`} exact render={(props) => !!item.contents && <item.contents {...props} {...item.params}/> } />
              <Route path={`${match.path}/${item.path}/:id`} exact render={(props) => !!item.contents && <item.contents {...props} {...item.params}/> } />
            </Fragment>
          ))
        }
      </div>

    </Fragment>
  );
});