import React, { useEffect, useState, useRef } from 'react';
import LoadingButton from '../../LoadingButton';
import { Modal } from 'react-bootstrap';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import { BsFillCalendarDateFill } from "react-icons/bs";
import moment from 'moment';

export default function DateField({ input, meta, label, required, children, btnProps, prefixText, ...others }) {
  const style = ((meta.error || meta.submitError) && meta.touched && !others.readOnly) ? { 'color': 'red' } : {};
  const fieldRef = useRef(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const splits = input.value.split('/');

  const defaultTo = splits.length >= 3 ? {
    year: parseInt(splits[2]),
    month: parseInt(splits[0]),
    day: parseInt(splits[1])
  } : null;

  const [selectedDay, setSelectedDay] = useState(defaultTo);

  useEffect(() => {
    fieldRef.current && fieldRef.current.setCustomValidity((meta.error && meta.touched) ? meta.error : '');
  });

  useEffect(() => {
    if (selectedDay != null) {
      //fieldRef.current.value = selectedDay.day + '/' + selectedDay.month + '/' + selectedDay.year;
      input.onChange(moment(selectedDay.year + '-' + selectedDay.month + '-' + selectedDay.day).format('MM/DD/YYYY'));
      handleClose();
    }
  }, [selectedDay]);

  // useEffect(() => {
  //   if(input.value && input.value.toString().includes('/')) {
  //     fieldRef.current.value = moment(moment(input.value, 'DD/MM/YYYY')).format('MM/DD/YYYY');
  //   }
  // }, [input.value])

  return (
    <div className={`form-group ${others.className ? others.className : ''}`} style={{ opacity: others.disabled ? 0.5 : 1 }}>
      <label htmlFor={others.id} style={style}>{label} {required && !others.readOnly && <span className="is-required">*</span>}</label>

      {
        prefixText && <span>{prefixText}</span>
      }

      {
        others.readOnly ?
          <div>{input.value}</div>
          :
          <>
            <div className='datefield-wrap'>
              <input ref={fieldRef} {...input} type={input.type || 'text'} {...others} className={`${btnProps ? 'has-button' : ''} ${prefixText ? 'has-prefix' : ''}`}
                onChange={e => {
                  if(e.target.value.match(/^[0-9/]*$/)) {
                    others.onChange && others.onChange(e);
                    input.onChange(e);
                  }
                }}
                onClick={e => handleShow()}
              />
              <BsFillCalendarDateFill className='mt-10' onClick={e => {
                handleShow();
              }} />
            </div>
            <Modal show={show} onHide={handleClose} size="sm" centered className='transparent_background'>
              <div className='centering'>
                <Calendar
                  value={selectedDay}
                  onChange={setSelectedDay}
                  shouldHighlightWeekends
                />
              </div>
            </Modal>
          </>
      }

      {
        btnProps && <LoadingButton {...btnProps} />
      }
      <i className="mtrl-select" />
      {children}
    </div>
  );
}