const pattern = {
	email: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
	phone: /^[0-9+]+$/,
	password: /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/,
	alphaNumeric: /^[a-zA-Z0-9]+$/i,
	onlyNumbers: /[0-9]/,
};

export function composeValidators (...validators) {
	return value => validators.reduce((error, validator) => error || validator(value), undefined);
}

export function required(value) {
	return (value != null && value !== '') ? undefined : 'This field is required';
}

export function requiredTrue(value) {
	return value === true ? undefined : 'This field is required';
}

export function requiredMulti(value) {
	return value && value.length ? undefined : 'Please, select at least one value';
}

export function minLength(min) {
  return value => value.length >= min ? undefined : `Please, add at least ${min} symbols`;
}

export function maxLength(max) {
  return value => value.length <= max ? undefined : `Please, don't add more than ${max} symbols`;
}

export function validatePhone(value) {
	return value && pattern.phone.test(value) ? undefined : 'Wrong phone number format';
}

export function validateEmail(value) {
	return value && pattern.email.test(value) ? undefined : 'Invalid Email address';
}

export function validatePassword(value) {
	return value && pattern.password.test(value) ? undefined : 'Password must include at least one lowercase letter, one capital letter, one number and one special character - "!@#$%^&*"';
}

export function validateAlphanumeric(value) {
	return !value || pattern.alphaNumeric.test(value) ? undefined : 'String must contain only letters and numbers';
}

export function validateNumber(value) {
	return !value || pattern.onlyNumbers.test(value) ? undefined : 'This field must contain only numbers';
}

export function samePassword(val1, val2) {
	return val1 === val2 ? undefined : "Passwords don't match";
}