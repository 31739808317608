import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function Pages({pages}){
  const history = useHistory();
  const {t} = useTranslation('common');
  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-users" /> {t('groups.pages')}</h5>

            <div className="photos-container small avatars">
              {
                (!pages || !pages.length) &&
                <center>
                  <div className="photos-empty"> {t('groups.noPagesDisplay')} </div>
                </center>
              }

              {
                pages && pages.map((p,i) => {
                  return (
                    <div className="photos-holder" key={i}>
                      <div
                        className="photos-item"
                        style={{backgroundImage: `url("${p.image}")`}}
                        onClick={()=> p.link && history.push({pathname: p.link})}
                        title={p.text}
                      />
                      <div>{p.text}</div>
                    </div>
                  )
                })
              }
            </div>

      </div>
    </div>
  );
}
