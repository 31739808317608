import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Welcome from '../../Components/Welcome';
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";

export default function VerifyRelation() {
  const { handleError } = usePopupContext();
  const params = new URLSearchParams(useLocation().search);
  const randomToken = params.get('id');
  const [msg, setMsg] = useState(randomToken ? 'Verifying ...' : 'Missing token!');
  const {t} = useTranslation('common');

  useEffect(() => {
    if (randomToken) {
      request('honoree.verifyRelation', {id: randomToken})
      .then(() => setMsg(t("honoree.verifySuccess")))
      .catch(e => {
        handleError(e);
        setMsg(t('header.error'));
      });
    }
  }, [randomToken]);

  return(
    <Welcome>
      <div className="log-reg-area sign">
        <h2 className="log-title">{msg}</h2>
      </div>
    </Welcome>
  );
}
