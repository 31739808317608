import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { USER_KEY } from '../../Util/constants';
import { CONFIG } from '../../Config';
import * as StorageUtil from '../../Util/storage';

export default withRouter(class ErrorBoundary extends Component {

  getUser() {
    return JSON.parse(StorageUtil.getItem(USER_KEY) || '{}');
  }

  clearUser(){
    StorageUtil.setItem(USER_KEY, JSON.stringify({}));
  }

  state = { 
    hasError: false,
  };

  componentDidUpdate(){
    
  }

  componentDidMount(){
    axios.interceptors.request.use((config) => {
      const userData = this.getUser();
      if (!config.params) {
        config.params = {};
      }
      if (userData.token) {
        config.headers['Authorization'] = `Bearer ${userData.token}`;
      }
      if (userData.Language) {
        config.headers['Accept-Language'] = userData.Language || 'en';
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      if (error.response && error.response.status === 401) {
        //TODO re-enable when finish testing old code
        //this.clearUser();
        //this.props.history.push(`/`);
      } 
      return Promise.reject(error);
    });
    
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (CONFIG.LOG_ERRORS) {
      // const userData = getUser();
      // const data = {
      //   text: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      //   userId: userData.id ? userData.id : null
      // }
      // axios.post(`${BASE_URL}/front-end-error`, data);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return (
      this.props.children
    ) 
  }

})
