import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { 
        composeValidators, 
        required, 
        validateEmail } from '../../Util/form';
import TextField from '../../Components/Form/TextField';
import { useTranslation } from "react-i18next";
import { usePopupContext } from '../../Contexts/popupContext';
import LoadingButton from '../../Components/LoadingButton';
import { useLocation } from 'react-router-dom';
import { request } from '../../Services/networkService';

export default function ChangeEmail({ user, update, changeEmailRef, initialValues }) {
  const { handleError, popupMessageOpen } = usePopupContext();
  const {t} = useTranslation('common');

  const hasError = new URLSearchParams(useLocation().search).get('error');

  function closeModal() {
    return changeEmailRef.current?.close();
  }

  function authErrorHandling(err) {
    closeModal();
    return handleError(err);
  }

  useEffect(() => {
    if(hasError) {
        closeModal();
        popupMessageOpen('Error', 'Error registering with this account, probably already registered with the same username?');
    }
  }, [hasError]);

  const compareEmails = (email, retypedEmail) => {
    if(email === retypedEmail) return true;
    else return false;
  }

  const onSubmit = data => {
    if(compareEmails(data.newEmail, data.retypedEmail)) {
      request('user.updateEmail', data)
      .then(result => {
        if(Object.keys(result).length) {
          closeModal();
          update({ ...user, ...result })
        }
      })
      .catch(err => authErrorHandling(err));
    } else authErrorHandling({message: 'Invalid email address'})
  };

  return (
    <dialog ref={changeEmailRef} className="authModal">
        <div className="text-right closeButton">
            <i className='ti-close' onClick={() => changeEmailRef.current?.close()}></i>
        </div>
        <div className="log-reg-area sign submit-btns">
          <h3 className="log-title">Change Email</h3>
          <Form
            onSubmit={onSubmit}
            initialValues={{ ...initialValues }}
            render={({ handleSubmit, submitting, values, pristine }) => {
              return (
                <form onSubmit={handleSubmit}>

                  <div className="form-row">
                    <div className="col">
                      <Field component={TextField}
                        name="currentEmail"
                        label={'Current ' + t('fields.emailAddress')}
                        type="email"
                        validate={required}
                        required
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <Field component={TextField}
                        name="newEmail"
                        label={'New ' + t('fields.emailAddress')}
                        type="email"
                        validate={composeValidators(required, validateEmail)}
                        required
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <Field component={TextField}
                        name="retypedEmail"
                        label={'Retype the ' + t('fields.emailAddress')}
                        type="email"
                        validate={composeValidators(required, validateEmail)}
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="submit-btns text-center">
                    <LoadingButton iconCls="ti-save" loading={submitting} disabled={pristine} title={t('profile.updateBtn')} />
                  </div>

                </form>
              )
            }}
          />
        </div>
    </dialog>
  );
}