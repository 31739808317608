import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import * as React from "react";
import { CONFIG } from '../../Config';

export default class PayPalBtn extends React.Component                     {

  constructor(props) {
    super(props);
    this.createOrder = this.createOrder.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.onError = this.onError.bind(this);
  }

  createOrder(data, actions) {
    return actions.order.create({purchase_units: [{amount: {value: this.props.product.price}}]})
      .then(orderID => {
        return orderID;
      });
  }

  onApprove(data, actions) {
    return actions.order.capture().then(details => {
      this.props.onComplete(details);
    });
  }

  onError(err) {
    console.log(err);
  }

  render() {
    return (
        <PayPalScriptProvider options={{ "client-id": CONFIG.PAYPAL_CLIENT_ID }}>
          <PayPalButtons
            style={{
              color: "blue",
              shape: "pill",
              label: "pay",
              tagline: false,
              layout: "horizontal"
            }}
            disabled={!this.props.product}
            createOrder={this.createOrder}
            onApprove={this.onApprove}
            onError={this.onError}
          />
        </PayPalScriptProvider>
    );
  }
}