import React, { useState, useEffect, useRef } from 'react';
import { usePopupContext } from '../../Contexts/popupContext';
import ReactTable from '../../Components/ReactTablePagination';
import { formatDate } from '../../Util/common';
import { request } from '../../Services/networkService';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../Contexts/userContext';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function HiddenAds({pageId, userPage}){
  const tableRef = useRef();
  const { handleError } = usePopupContext();
  const [pages, setPages] = useState();
  const [selectedPage, setSelectedPage] = useState('');
  const history = useHistory();
  const { setUser } = useUserContext();

  const id = userPage && userPage._id;

  useEffect(() => {
    id && setSelectedPage(id);
  }, [id])

  useEffect(() => {
    request('page.list')
      .then(setPages)
      .catch(handleError)
  }, []);

  const columns = [{
    Header: <Translate>Text</Translate>,
    accessor: 'text',
    Cell: ({value}) => value.length > 11 ? <div dangerouslySetInnerHTML={{__html: value}}></div> : <div>---</div>
  },{
    Header: <Translate>Posted As</Translate>,
    accessor: 'postedAs.Name'
  },{
    Header: <Translate>Type</Translate>,
    accessor: 'sale.adType',
    width: 80
  },{
    Header: <Translate>Posted On</Translate>,
    accessor: 'date',
    Cell: ({value}) => formatDate(value),
    width: 100
  },{
    Header: <Translate>Expires On</Translate>,
    accessor: 'sale.expiry',
    Cell: ({value}) => formatDate(value),
    width: 110
  },{
    Header: <Translate>Auto Renew</Translate>,
    accessor: 'sale.autoRenew',
    Cell: ({value}) => value ? 'Yes' : 'No',
    width: 100
  }];

  const unhideAd = async post => {
    let uData = await JSON.parse(localStorage.getItem('userData'));
    uData.HiddenPosts = uData.HiddenPosts.filter(item => item.toString() !== post._id.toString());
    return request('user.update', uData)
    .then((updatedUser) => {
        tableRef.current.refresh();
        setUser({ ...updatedUser, token: uData.token })
    })
    .catch(() => handleError)
  };

  const actions = [{
    iconcls: 'fa fa-eye-slash',
    onClick: (row) => unhideAd(row),
    title: "Unhide ad"
  }];

  const hasPages = pages && pages.length ? true : false;

  const page = selectedPage && pages && pages.find(p => p._id === selectedPage);

  const getLink = post => `/userpage/${post.postedAs.pageId}`;

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-users" /><Translate>Hidden Ads</Translate></h5>
        
        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'group.post.hidden'}
          disableFilters
          onError={handleError}
          defaultSorted={[{
            id: 'date',
            desc: true
          }]}
          onRowClick={data => history.push({pathname: getLink(data)})}
        />

      </div>
    </div>
  );
}
