import React from 'react';

export default function ToggleField ({ input, meta, label, text, required, children, ...others }) {
  return (
    <div className="setting-row" style={{ opacity: others.disabled ? 0.5 : 1}}>
      <span>{label}</span>
      <p>{text}</p>
      <input type="checkbox"  {...input} {...others} /> 
      <label htmlFor={others.id} data-on-label="ON" data-off-label="OFF" />
    </div>
  );
}