import React, { useEffect, useRef } from 'react';

export default function CheckBox ({ input, meta, label, required, ...others }) {
  const showInvalid = (meta.error || meta.submitError) && meta.touched;
  const ref = useRef(null);

  useEffect(()=>{
    ref.current && ref.current.setCustomValidity((meta.error && meta.touched) ? meta.error : '')
  });

  return (
    <div className="checkbox" style={{ opacity: others.disabled ? 0.5 : 1, marginTop: others.style?.marginTop ? others.style.marginTop : '1rem', marginBottom: others.style?.marginBottom ? others.style.marginBottom : '1rem'}}>
      <label>
        <input ref={ref} {...input} {...others}
          onChange={e => {
            others.onChange && others.onChange(e);
            input.onChange(e);
          }} 
        />
        <i className="check-box" />
        <span style={showInvalid ? { 'color': 'red' } : {}}>{label} {required && <span className="is-required">*</span>}</span>
      </label>
    </div>
  );
}