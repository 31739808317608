import React, { useEffect, useState } from 'react';
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function CookiePolicy() {
  const [docData, setDoc] = useState('');
  const { handleError, popupMessageOpen } = usePopupContext();

  const getDocument = () => {
    request('public.listPolicy', {type: 'Refund Policy'})
    .then(res => {
      if(Object.keys(res).length) {
        setDoc(res.text)
      } else {
        popupMessageOpen('Message', 'Missing Document!');
      }
    })
    .catch(err => {
      handleError(err)
    })
  }

  const modules = {
    toolbar: false
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getDocument();
  }, []);

  return(
    <div className="contct-info policies documents">
      {
        docData && 
        <ReactQuill 
          theme="snow"
          value={docData}
          readOnly
          modules={modules}
        />
      }
    </div>
  );
}
