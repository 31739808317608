const isAppAuthAvailable = (userTwoFA) => {
    if(userTwoFA.gToken && userTwoFA.GoogleAuthenticator) return true;
    else return false;
}

const isEmailAuthAvailable = (userTwoFA) => {
    if(userTwoFA.eToken && userTwoFA.Email) return true;
    else return false;
}

const isTwoFAFull = (userTwoFA) => {
    if(isAppAuthAvailable(userTwoFA) && isEmailAuthAvailable(userTwoFA)) return true;
    else return false;
}

const userHasTwoFA = (user) => {
    if(isEmailAuthAvailable(user.TwoFA) === true || isAppAuthAvailable(user.TwoFA) === true) return true;
    else return false;
}

module.exports = {
    isAppAuthAvailable,
    isEmailAuthAvailable,
    userHasTwoFA,
    isTwoFAFull,
}