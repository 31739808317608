import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MasterProvider from './Contexts';
import PrivateRoute from './Components/PrivateRoute';
import ErrorBoundary from './Components/ErrorBoundary';
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css'

import Login from './Pages/Login';
import Forgotpassword from './Pages/PasswordForgot';
import ResetPassword from './Pages/PasswordReset';
import HowItWorks from './Pages/QuickLinks/HowItWorks';
import MemkeepersAbout from './Pages/QuickLinks/AboutUs';
import Contact from './Pages/QuickLinks/ContactUs';
import Profile from './Pages/Profile';
import Timeline from './Pages/Timeline';
import ViewUserProfile from './Pages/UserProfile';
import ViewUserTimeline from './Pages/UserTimeline';
import ViewUserGroups from './Pages/UserGroups';
import ViewHonoreeProfile from './Pages/HonoreeProfile';
import ViewHonoreeTimeline from './Pages/HonoreeTimeline';
import ViewHonoreePhotos from './Pages/HonoreePhotos';
import Honorees from './Pages/Honorees';
import PageNotFound from './ProfileComponent/PageNotFound.component';
import Requests from './Pages/Requests';
import Purchases from './Pages/Purchases';
import Groups from './Pages/Groups';
import Pages from './Pages/Pages';
import PageTimeline from './Pages/PageTimeline';
import Policies from './Pages/Policies';
import VerifyUser from './Pages/Login/verify';
import VerifyRelation from './Pages/Login/verifyRelation';
import AuthGoogle from './Pages/Login/authGoogle';
import Advertise from './Pages/Advertise';
import Promotions from './Pages/Promotions';
import Feature from './Pages/Features';
import Blog from './Pages/Blog';
import BlogContent from './Pages/Blog/blog';
import SEO_BlogContent from './Pages/Blog/SEO/blog';
import { Helmet } from 'react-helmet';
import { CONFIG } from './Config';
import ThankYou from './Pages/ThankYou';

export default class App extends Component {
  render() {
    const canonicalUrl = `https://www.${CONFIG.DOMAIN_CANNONICAL}${window.location.pathname}`;
    return (
      <div className="App">
        <Router>
          {
            CONFIG.ENV === 'PROD' ?
            <Helmet>
              <link rel='canonical' href={canonicalUrl}></link>
            </Helmet>
            :null
          }
          <ErrorBoundary>
            <MasterProvider> 
              <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/forgot" exact component={Forgotpassword} />
                <Route path="/reset/:id" exact component={ResetPassword} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/howitworks" exact component={HowItWorks} />
                <Route path="/about" exact component={MemkeepersAbout} />
                <Route path="/policies" component={Policies} />
                <Route path="/features/:URL" exact component={Feature} />
                <Route path="/blog" exact component={Blog} />
                <Route path="/blog/:URL" exact component={BlogContent} />
                <Route path="/s/:REGION/:URL" exact component={SEO_BlogContent} />
                <Route path="/auth/google" component={AuthGoogle} />
                <Route path="/verify" component={VerifyUser} />
                <Route path="/verifyRelation" component={VerifyRelation} />
                <Route path="/thank-you" component={ThankYou} />
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/promotions" component={Promotions} />
                <PrivateRoute path="/timeline" component={Timeline} />
                <PrivateRoute path="/honorees" component={Honorees} />
                <PrivateRoute path="/honoree/:id" component={ViewHonoreeProfile} />
                <PrivateRoute path="/usertimeline/:id" component={ViewUserTimeline} />
                <PrivateRoute path="/usergroups/:id" component={ViewUserGroups} />
                <PrivateRoute path="/honoreetimeline/:id" component={ViewHonoreeTimeline} />
                <PrivateRoute path="/honoreephotos/:id" component={ViewHonoreePhotos} />
                <PrivateRoute path="/userprofile/:id" component={ViewUserProfile} />
                <PrivateRoute path="/requests" component={Requests} />
                <PrivateRoute path="/purchases" component={Purchases} />
                <PrivateRoute path="/groups" component={Groups} />
                <PrivateRoute path="/pages" component={Pages} />
                <PrivateRoute path="/userpage/:id" component={PageTimeline} />
                <PrivateRoute path="/advertise" component={Advertise} />
                <PrivateRoute path="/pageadvertise/:id" component={Advertise} />
                <Route component={PageNotFound} />
              </Switch>
            </MasterProvider>
          </ErrorBoundary>
        </Router>
      </div>
    );
  }
}
