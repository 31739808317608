import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import DateField from '../../Components/Form/DateField';
import TextArea from '../../Components/Form/TextArea';
import RadioGroup from '../../Components/Form/RadioGroup';
import { required } from '../../Util/form';
import { useTranslation } from "react-i18next";
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import LoadingButton from '../../Components/LoadingButton';
import { validateAlphanumeric } from '../../Util/form';
import CityStateCountry from '../../Components/Form/CityStateCountry';
import Gallery from '../../Components/Gallery';
import { formatDate } from '../../Util/common';
import { Translate } from 'react-auto-translate/lib/commonjs';
import FamTree from '../../Components/FamilyTree';
import SelectField from '../../Components/Form/Select';

export default function PersonalInfo({ user, update, readOnly = false }) {
  const { handleError } = usePopupContext();
  const { t } = useTranslation('common');
  const history = useHistory();
  const [inputFields, setInputFields] = useState([]);
  const infoRef = useRef();

  const addInputField = (numOfChildrens = 1, multiple = false) => {
    let updatedData = [...inputFields];
    if(Object.keys(inputFields).length <= 5) {
      for (let index = 0; index < numOfChildrens; index++) {
        // updatedData.push({ Children: '' })
        updatedData.push({ Name: '', Gender: '' })
      }
      setInputFields(updatedData)
    } 
  }

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    const childrens = typeof user !== 'undefined' ? [...user.FamilyMembers.Childrens] : [];

    if(typeof user !== 'undefined') {
      rows.splice(index, 1);
      childrens.splice(index, 1);
      setInputFields(rows);
      user.Childrens = childrens;
    } else {
      rows.splice(index, 1);
      // childrens.splice(index, 1);
      setInputFields(rows);
      // user.Childrens = childrens;
    }
  }

  const editMode = !!user && !readOnly;

  const onSubmit = data => {
    if(!editMode) {
      createHonoree(data)
    } else if(editMode) {
      updateHonoree(data)
    }
  };

  const createHonoree = data => {
    let body = data;
    return request('honoree.create', body)
    .then(res => {
      // TODO FamTree functionality 
      if(res && res.FamilyMembers) {
        if(res.FamilyMembers.GrandMother) res.FamilyMembers.GrandMother.Gender = 'female';
        if(res.FamilyMembers.GrandFather) res.FamilyMembers.GrandFather.Gender = 'male';
        if(res.FamilyMembers.Uncle) res.FamilyMembers.Uncle.Gender = 'male';
        if(res.FamilyMembers.Aunt) res.FamilyMembers.Aunt.Gender = 'female';
        if(res.FamilyMembers.Mother) res.FamilyMembers.Mother.Gender = 'female';
        if(res.FamilyMembers.Father) res.FamilyMembers.Father.Gender = 'male';
        if(res.FamilyMembers.Spouse) res.FamilyMembers.Spouse.Gender = res.Gender === "Male" ? 'female' : 'male';

        updateHonoree(res)
      }

      history.push(`/honoree/${res.Alias || res._id}`)
    })
    .catch(handleError);
  }

  const updateHonoree = data => {
    return request('honoree.update', data)
    .then(res => update(res))
    .catch(handleError);
  }

  const fulfilledMembers = data => {
    if(data && data.GrandMother.Name && data.GrandFather.Name && data.Uncle.Name && data.Aunt.Name && data.Mother.Name && data.Father.Name && data.Spouse.Name) return true;
    else return false;
  }

  useEffect(() => {
    if(user && user.Childrens && user.Childrens.length) {
      if(inputFields.length < user.Childrens.length) addInputField(user.Childrens.length, true);
    }
  }, [user]);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-user" /> {t('honoree.personalInformation')}</h5>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            Gender: 'Male', ...user,
            DOB: user ? formatDate(user.DOB) : '',
            DOD: user ? formatDate(user.DOD) : '',
            Childrens: user?.Childrens
          }}
          render={({ handleSubmit, submitting, values, pristine }) => {
            return (
              <form onSubmit={handleSubmit} ref={infoRef}>

                <Field component={RadioGroup}
                  name="Gender"
                  options={[{ name: t('honoree.mr'), value: 'Male' }, { name: t('honoree.mrs'), value: 'Female' }]}
                  validate={required}
                  required
                  readOnly={readOnly}
                />

                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name="FirstName"
                      label={t('fields.firstName')}
                      validate={required}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="MiddleName"
                      label={t('honoree.middleName')}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="LastName"
                      label={t('fields.lastName')}
                      validate={required}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <h5 className="f-title"><Translate> Honoree names in second language</Translate></h5>
                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name="_FirstName"
                      label={t('fields.firstName')}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="_MiddleName"
                      label={t('honoree.middleName')}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="_LastName"
                      label={t('fields.lastName')}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
                
                {
                  !readOnly && <>
                    <div className="form-row">
                      <div className="col">
                        <Field component={TextField}
                          name="Nickname"
                          label={t('honoree.nickname')}
                          readOnly={readOnly}
                        />
                      </div>
                      <div className='col'>
                        <Field component={TextField}
                          name="Alias"
                          label={t('honoree.customizeURL')}
                          readOnly={readOnly}
                          validate={validateAlphanumeric}
                          prefixText={`${window.location.origin}/honoree/`}
                        />
                      </div>
                    </div>
                  </>
                }

                {
                  readOnly && <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name="Nickname"
                      label={t('honoree.nickname')}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
                }

                <div className="form-row">
                  <div className="col">
                    <Field component={DateField}
                      name="DOB"
                      label={t('fields.dob')}
                      type="text"
                      validate={required}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={DateField}
                      name="DOD"
                      label={t('honoree.dateOfDeath')}
                      type="text"
                      validate={required}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <CityStateCountry
                  values={values}
                  readOnly={readOnly}
                  cityProp="POB"
                  stateProp="SOB"
                  countryProp="COB"
                  placeTitle={t('honoree.placeOfBirth')}
                />

                <CityStateCountry
                  values={values}
                  readOnly={readOnly}
                  cityProp="POD"
                  stateProp="SOD"
                  countryProp="COD"
                  placeTitle={t('honoree.placeOfDeath')}
                />

                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name="Religion"
                      label={t('fields.religion')}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="BurialPlace"
                      label={t('honoree.placeOfBurial')}
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <Field component={TextField}
                  name="FuneralHomeSite"
                  label={t('honoree.funeralHomeWebsite')}
                  readOnly={readOnly}
                />

                <Field component={TextField}
                  name="CemeteryAddress"
                  label={t('honoree.cemeteryAddress')}
                  readOnly={readOnly}
                />

                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name="Linkedin"
                      label="Linkedin"
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="Facebook"
                      label="Facebook"
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name="Twitter"
                      label="Twitter"
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="Instagram"
                      label="Instagram"
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <Field component={TextArea}
                  name="About"
                  label={t('honoree.aboutHonoree')}
                  readOnly={readOnly}
                />

                {/* <h5 className="f-title"><i className="fa fa-user" /><Translate> Family Members</Translate></h5>

                <p className="f-title"><Translate> Grandparents</Translate></p>
                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name="FamilyMembers.GrandMother.Name"
                      label={<Translate>Grandmother</Translate>}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="FamilyMembers.GrandFather.Name"
                      label={<Translate>Grandfather</Translate>}
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <p className="f-title"><Translate> Uncles, Aunts and Cousins</Translate></p>

                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name="FamilyMembers.Uncle.Name"
                      label={<Translate>Uncle</Translate>}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="FamilyMembers.Aunt.Name"
                      label={<Translate>Aunt</Translate>}
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <p className="f-title"><Translate> Parents and Siblings</Translate></p>

                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      // name="Mother"
                      name="FamilyMembers.Mother.Name"
                      label={<Translate>Mother</Translate>}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      // name="Father"
                      name="FamilyMembers.Father.Name"
                      label={<Translate>Father</Translate>}
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <p className="f-title"><Translate> Spouse and Children</Translate></p>
                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name="FamilyMembers.Partner.Name"
                      label={values.Gender === 'Male' ? <Translate>Wife</Translate> : <Translate>Husband</Translate>}
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <div className='form-row'>
                  {
                    !readOnly &&
                    <button onClick={() => addInputField()} type='button'>
                      <i className='ti-plus'></i>&nbsp;<Translate>Add Children</Translate>
                    </button>
                  }
                </div>
                  {
                    inputFields.map((data, index) => {
                      return (
                        <div className='form-row' style={{ display: 'flex', alignItems: 'center' }} key={index}>
                          <div className='col'>
                            <Field component={TextField}
                              name={`FamilyMembers.Childrens[${index}].Name`}
                              label={<Translate>Child {(index + 1)}</Translate>}
                              readOnly={readOnly}
                            />
                          </div>
                          <div className='col'>
                          <Field component={SelectField}
                              name={`FamilyMembers.Childrens[${index}].Gender`}
                              label="Gender"
                              options={[{ id: 'male', name: 'Male' }, { id: 'female', name: 'Female' }]}
                              readOnly={readOnly}
                            />
                          </div>
                          {
                            !readOnly &&
                            <button type='button' style={{ borderRadius: '15px', paddingBottom: '6px' }} onClick={() => removeInputFields((index))}><i style={{ verticalAlign: 'middle', fontSize: '16px' }} className='ti-close'></i></button>
                          }
                        </div> 
                      )
                    })
                  } */}
                <br/>
                {/* {
                  !readOnly &&
                  <div className='form-row text-center'>
                    <div className="submit-btns text-center" style={{ width: '100%' }}>
                      <LoadingButton loading={submitting} title={<Translate>Save All</Translate>} />
                      { values && values.FamilyMembers && <FamTree honoree={values} update={update} editMode={editMode} readOnly={readOnly}/> }
                    </div>
                  </div>
                } */}
                <div className='form-row text-center'>
                  <div className="submit-btns text-center" style={{ width: '100%' }}>
                    { !readOnly && <LoadingButton loading={submitting} title={<Translate>Save All</Translate>} /> }
                    {/* { values && values.FamilyMembers && <FamTree honoree={values} update={update} editMode={editMode} readOnly={readOnly}/> } */}
                  </div>
                </div>
              </form>
            )
          }}
        />

        <br />

        <Gallery galleryType="BasicInfo" user={user} readOnly={readOnly} />

      </div>
    </div>
  );
}