import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout';
import { request } from '../../Services/networkService';
import { useUserContext } from '../../Contexts/userContext';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";

import All from './all';
import Friends from './friends';
import Honorees from './honorees';
import Pages from './pages';
import Invite from './invite';

export default function Groups() {
  const { user, getAvatar, getObjectAvatar } = useUserContext();
  const [friends, setFriends] = useState([]);
  const [honorees, setHonorees] = useState([]);
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const { handleError } = usePopupContext();
  const {t} = useTranslation('common');
  
  useEffect(() => {
    setLoading(true);
    user._id && request('user.groups')
    .then(data => {
      setFriends(data.filter(o => o.GroupType === 'Register' && o.RelatedTo && o.RelatedTo._id !== user._id).map(o => ({
        text: o.Name,
        image: getAvatar(o.RelatedTo.ProfileImage || null),
        link: '/usertimeline/' + o.RelatedTo._id
      })));

      setHonorees(data.filter(o => o.GroupType === 'Honoree' && o.RelatedTo).map(o => ({
        text: o.Name,
        image: getObjectAvatar(o.RelatedTo),
        link: '/honoreetimeline/' + o.RelatedTo._id
      })));

      setPages(data.filter(o => o.GroupType === 'Page' && o.RelatedTo).map(o => ({
        text: o.Name,
        image: getAvatar(o.RelatedTo.ProfileImage || null, true),
        link: '/userpage/' + o.RelatedTo._id
      })));
    })
    .catch(handleError)
    .finally(() => setLoading(false));
  }, [user._id]);


  const menuConfig = [
    {
      title: t('groups.allGroups'),
      contents: All,
      iconCls: 'fa fa-users',
      path: '',
      params: {friends, honorees, pages, loading}
    },{
      title: t('groups.friends'),
      contents: Friends,
      iconCls: 'fa fa-users',
      path: 'friends',
      params: {friends}
    },{
      title: t('groups.honorees'),
      contents: Honorees,
      iconCls: 'fa fa-users',
      path: 'honorees',
      params: {honorees}
    },{
      title: t('groups.pages'),
      contents: Pages,
      iconCls: 'fa fa-users',
      path: 'pages',
      params: {pages}
    },{
      title: t('groups.inviteFriends'),
      contents: Invite,
      iconCls: 'fa fa-users',
      path: 'invite-friends',
      params: {}
    }
  ];

  return(
    <Layout config={menuConfig} title={t('groups.groupsTitle')} />
  );
};
