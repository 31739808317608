import React, { useRef } from 'react';
import ReactTable from '../../Components/ReactTablePagination';
import { usePopupContext } from '../../Contexts/popupContext';
import { Field } from 'react-final-form';
import { required } from '../../Util/form';
import Select from '../../Components/Form/Select';
import { request } from '../../Services/networkService';
import debounce from 'debounce';
import { generateTextDropdown } from '../../Components/ReactTable/Filters';
import { useUserContext } from '../../Contexts/userContext';
import TextField from '../../Components/Form/TextField';
import { useTranslation } from "react-i18next";

export default function Groups(){
  const { handleError, popupFormOpen, popupConfirmOpen, popupMessageOpen, popupPaymentOpen } = usePopupContext();
  const tableRef = useRef();
  const { products, user } = useUserContext();
  const {t} = useTranslation('common');
  const issuedFromId = user.id;
  const issuedFromData = user;
  const options = products ? products.filter(p => p.name !== 'Memento') : [];

  const columns = [
  // {
  //   Header: 'Token',
  //   accessor: 'token',
  //   width: 330
  // },
  {
    Header: 'Sent To',
    accessor: 'ownerName'
  },{
    Header: 'Token Type',
    accessor: 'type',
    Cell: ({value}) => products ? products.find(t => t.id === value)?.name : '',
    Filter: generateTextDropdown(products, true),
    width: 150
  },{
    Header: 'Used',
    accessor: 'used',
    Cell: ({value}) => value === true ? 'Yes' : 'No',
    Filter: generateTextDropdown([{name: 'Yes', id: true}], true),
    width: 60
  }];

  const actions = [{
    iconcls: 'fa fa-trash-o',
    onClick: row => popupConfirmOpen('Confirm', 'Are you sure you want to delete this token?')
      .then(() => {
        request('admin.tokens.delete', {id: row._id})
        .then(tableRef.current.refresh)
      }),
    title: 'Delete token'
  }];

  const getProductById = (product) => {
    const availableProducts = options;
    const selectedProductId = product.toString();
    let findedProduct = [];

    findedProduct = availableProducts ? availableProducts.filter(p => p.id === selectedProductId) : null;

    return findedProduct;
  }

  const save = async ({user, ...values}) => {
    const product = getProductById(values.type)[0];
    const findedUser = await loadUsers(user);

    if(Object.keys(findedUser).length && issuedFromId) {
      // Prepare the token data
      const data = await {...values, issuedFrom: issuedFromId, issuedFromName: (issuedFromData.First_Name + ' ' + issuedFromData.Last_Name + ' - ' + issuedFromData.Email), issuedFromEmail: issuedFromData.Email, owner: findedUser.id, ownerName: findedUser.name, ownerEmail: findedUser.email};
      const editMode = data._id;
      // Open payment form
      popupPaymentOpen({memobject: product, itemName: product.name, showMaintanence: true})
      .then(paymentData => {
        request(`group.post.createSale`, {id: findedUser.id, paypalId: paymentData.paypalId, paymentIntentId: paymentData.paymentIntentId, autoRenew: paymentData.autoRenew, productId: paymentData.productId, saleType: `Token - ${product.name}`})
        .then((saleData) => {
          console.log(saleData);
          request(`admin.tokens.${editMode ? 'update' : 'create'}`, data)
          .then(tableRef.current.refresh);
        })
      })
      .catch(handleError);
    } else {
      popupMessageOpen(t('popupcontext.msg'), "User with that email doesn't exist!")
    }
  };

  const tokenFormPopup = editMode => (
    <div className="form-row">

      <Field component={Select}
       name="type"
       label="Token Type"
       validate={required}
       required
       options={options}
      />

      <Field component={TextField}
       name="user"
       label="Issued To"
       validate={required}
       required
       type="email"
      />

    </div>
  );

  const loadUsers = async (value, cb) => {
    let userData = {}
    await request('common.search', {search: value, self: true, byText: true})
    .then(data => {
      userData = {
        name: `${data.users[0].First_Name} ${data.users[0].Last_Name} - ${data.users[0].Email}`,
        id: data.users[0].id,
        email: data.users[0].Email,
      }
    })
    .catch(handleError);

    return userData;
  };

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-object-group" />Send Tokens</h5>

        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'admin.tokens.list'}
          apiParam={issuedFromId}
          onError={handleError}
          openAddPopup={() => popupFormOpen('Create token', tokenFormPopup(false))
            .then(save)
            .then(tableRef.current.refresh)
          }
          columnRefreshTrigger={products}
        />

      </div>
    </div>
  );
}
