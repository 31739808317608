import React from 'react';
import Skeleton from 'react-loading-skeleton'

const SkeletonFeature = () => {

    return (
        <div className='content-container'>
            <div className='title'><Skeleton count={0.9} /></div>
            <div className='content'>
                <Skeleton count={2} />
                <br/>
                <Skeleton count={2.8} />
                <br/>
                <Skeleton count={1.5} />
            </div>
        </div>
    );
}

export default SkeletonFeature;
