import React, { useEffect, useState } from 'react';
import { usePopupContext } from '../../Contexts/popupContext';
import { request } from '../../Services/networkService';
import SekeletonMemorials from './skeletonMemorials';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { Translate } from 'react-auto-translate/lib/commonjs';


const WelcomeMemorials = () => {
    const [data, setData] = useState();
    const { handleError } = usePopupContext();

    function listMemorials() {
        request('public.listMemorials')
        .then(res => setData(res[0]))
        .catch(handleError)
    }

    const modules = {
        toolbar: false
    }

    useEffect(() => {
        listMemorials();
    }, [])

    return (
        <>
        {
            typeof(data) !== 'undefined' && Object.keys(data).length ?
                <div className='memorials content-container'>
                    {/* <div className='title'>Virtuals Memorials</div> */}
                    {/* <div className='content' dangerouslySetInnerHTML={{__html: data.text}}></div> */}
                    <ReactQuill 
                    theme="snow"
                    value={data.text}
                    readOnly
                    modules={modules}
                    />
                </div>
            
            :
            <SekeletonMemorials />
        }
        </>
    );
}

export default WelcomeMemorials;
