import React, { useEffect, useState, useRef, useCallback } from 'react';
import pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

export default function PdfViewer({url, ...others}){
  const canvasRef = useRef();
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const [pdfRef, setPdfRef] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const renderPage = useCallback((pageNum, pdf=pdfRef) => {
    pdf && pdf.getPage(pageNum).then(function(page) {
      const viewport = page.getViewport({scale: 1.5});
      const canvas = canvasRef.current;
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext = {
        canvasContext: canvas.getContext('2d'),
        viewport: viewport
      };
      page.render(renderContext);
    });   
  }, [pdfRef]);

  useEffect(() => {
    renderPage(currentPage, pdfRef);
  }, [pdfRef, currentPage, renderPage]);

  useEffect(() => {
    const loadingTask = pdfjsLib.getDocument({ url: url, disableStream: true, disableAutoFetch: true });
    loadingTask.promise.then(loadedPdf => {
      setPdfRef(loadedPdf);
    }, function (reason) {
      console.error(reason);
    });
  }, [url]);

  const hasPrevPage = currentPage > 1;
  const hasNextPage = pdfRef && currentPage < pdfRef.numPages;
  const nextPage = () => hasNextPage && setCurrentPage(currentPage + 1);
  const prevPage = () => hasPrevPage && setCurrentPage(currentPage - 1);

  return (
    <div {...others} className={`pdf-container ${others.className || ''}`}>
      <canvas ref={canvasRef} />
      {
        hasPrevPage && <div className="nav nav-prev" onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          prevPage();
        }} >
          <i className="fa fa-chevron-left" />
        </div>
      }
      {
        hasNextPage && <div className="nav nav-next" onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          nextPage();
        }} >
          <i className="fa fa-chevron-right" />
        </div>
      }
    </div>
  );
}