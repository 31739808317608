import React from 'react';
import Editor from '../../Components/ArrayEditor';
import { useTranslation } from "react-i18next";
import Gallery from '../../Components/Gallery';

export default function Achievements({user, readOnly, update}){
  const {t} = useTranslation('common');
  return (
    <Editor
      api="honoree.update"
      iconCls="ti-star"
      prop='Achievements'
      user={user}
      update={update}
      readOnly={readOnly}
      title={t("honoree.achievements")}
    >
      <br/><br/>
      <Gallery galleryType="Achievements" user={user} readOnly={readOnly} />
    </Editor>
  );
}