import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../Contexts/userContext';
import { usePopupContext } from '../../Contexts/popupContext';
import { request } from '../../Services/networkService';
import LoadingButton from '../../Components/LoadingButton';
import TabPanel from '../../Components/TabPanel';
import { useTranslation } from "react-i18next";
import csc from 'country-state-city';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function List(){
  const history = useHistory();
  const { user, getObjectAvatar } = useUserContext();
  const { popupConfirmOpen, handleError } = usePopupContext();
  const [list, setList] = useState([]);
  const [petList, setPetList] = useState([]);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation('common');

  const [othersList, setOthersList] = useState([]);
  const [othersPetList, setOthersPetList] = useState([]);

  const refreshList = () => {
    setLoading(true);
    Promise.all([request('honoree.list'), request('honoree.list', {type: 'Pet'})])
      .then((res) => {
        setList(res[0]);
        setPetList(res[1]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(()=>{
    refreshList();
    user._id && request('user.groups')
    .then(data => {
      setOthersList(data.filter(o => o.GroupType === 'Honoree' && o.RelatedTo && o.RelatedTo.HonoreeType !== 'Pet' && o.Owner && o.Owner._id !== user._id).map(obj => obj.RelatedTo));
      setOthersPetList(data.filter(o => o.GroupType === 'Honoree' && o.RelatedTo && o.RelatedTo.HonoreeType === 'Pet' && o.Owner && o.Owner._id !== user._id).map(obj => obj.RelatedTo));
    })
    .catch(handleError)


  }, []);

  const deleteHonoree = (e, index, obj) => {
    e.preventDefault();
    e.stopPropagation();
    return popupConfirmOpen(t('honoree.confirm'), t('honoree.deleteProfileMesseg'))
    .then(
      () => request('honoree.delete', obj)
      .then(() => refreshList())
      .catch(handleError)
    ).catch(()=>{})
  };

  const honoreeTable = lst => (
    !!lst.length && <table className="users-table honorees">
      <thead>
        <tr>
          <td></td>
          <td><Translate>Names</Translate></td>
          <td><Translate>Born</Translate></td>
          <td><Translate>Deceased</Translate></td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {lst.map((o,index)=>(
          <tr key={index} onClick={() => history.push(`/honoreetimeline/${o.Alias ? o.Alias : o._id}`)}>
            <td>
              <div className="results-avatar" style={{backgroundImage: `url('${getObjectAvatar(o)}')`}} />
            </td>
            <td>
              {o.FirstName} {!!o.Nickname && `(${o.Nickname})`} {!!o.MiddleName && o.MiddleName} {o.LastName}
            </td>
            <td>
              {o.DOB} in {o.COB && csc.getCountryById(o.COB.toString()).name}, {o.SOB && csc.getStateById(o.SOB.toString()).name} {o.POB}
            </td>
            <td>
              {o.DOD} in {o.COD && csc.getCountryById(o.COD.toString()).name}, {o.SOD && csc.getStateById(o.SOD.toString()).name} {o.POD}
            </td>
            <td>
              <LoadingButton className="info-table-icon" noClass noTitle iconCls="fa fa-close" onClick={(e) => deleteHonoree(e, index, o)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const humansTable = (
    <Fragment>
      {honoreeTable(list)}
      {honoreeTable(othersList)} 
      {
        !list.length && !loading &&
          <div className="no-content">
            <Translate>You haven't created any honoree profiles yet</Translate>
          </div>
      }
      <div className="submit-btns text-center">
        <LoadingButton link="/honorees/create" title={<Translate>Create honoree profile</Translate>} />
      </div>
    </Fragment>
  );

  const petsTable = (
    <Fragment>
      {honoreeTable(petList)}
      {honoreeTable(othersPetList)} 
      {
        !petList.length && !loading &&
          <div className="no-content">
            <Translate>You haven't created any pet profiles yet</Translate>
          </div>
      }
      <div className="submit-btns text-center">
        <LoadingButton link="/honorees/create-pet" title={<Translate>Create pet profile</Translate>} />
      </div>
    </Fragment>
  );

  const tabConfig = [{
    text: <Translate>My Honoree profiles</Translate>,
    iconCls: 'fa fa-user',
    content: humansTable,
    title: 'Here you create an honoree profile for a deceased loved one which you wish to commemorate'
  },{
    text: <Translate>Pets Profiles</Translate>,
    iconCls: 'fa fa-paw',
    content: petsTable,
    title: 'Here you create an honoree profile for a deceased pet which you wish to commemorate'
  }];

  return (
    <div className="central-meta">
      <div className="editing-info">
        <TabPanel tabs={tabConfig} loading={loading} />
      </div>
    </div>
  );
}
