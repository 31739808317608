import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import ToggleField from '../../Components/Form/Toggle';
import RadioGroup from '../../Components/Form/RadioGroup';
import TextArea from '../../Components/Form/TextArea';
import { required } from '../../Util/form';
import { request, useRequest } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import LoadingButton from '../../Components/LoadingButton';
import { useParams } from 'react-router-dom';
import { validateAlphanumeric } from '../../Util/form';
import { useTranslation } from "react-i18next";

export default function PersonalInfo({update, readOnly=false}){
  const { id } = useParams();
  const { popupMessageOpen, handleError } = usePopupContext();
  const history = useHistory();
  const {data, handleRequest} = useRequest();
const {t} = useTranslation('common');
  useEffect(() => {
    id && handleRequest('page.get', {id: id})
  }, [id])

  const editMode = !!data && !readOnly;

  const onSubmit = data => {
    return request(editMode ? 'page.update' : 'page.create', data)
      .then(res => editMode ? popupMessageOpen('Success', 'Changes saved successfully') : history.push('/pages'))
      .catch(handleError);
  };

  return (
    <div className="central-meta">
      <div className="editing-info onoff-options">
        <h5 className="f-title"><i className="ti-info-alt" /> {editMode ? "Edit page" : "Create new page"}</h5>

          <Form
            onSubmit={onSubmit}
            initialValues={{ isBusinessPage: false, ...data}}
            render={({ handleSubmit, submitting, values, pristine}) => {
              return (
                <form onSubmit={handleSubmit}>

                  <Field component={RadioGroup}
                         name="isBusinessPage"
                         options={[{name: t('pages.personal'), value: false}, {name: t('pages.business'), value: true}]}
                         validate={required}
                         required
                         readOnly={readOnly}
                  />

                  <Field component={TextField}
                         name="Name"
                         label={t('pages.name')}
                         readOnly={readOnly}
                         validate={required}
                         required
                  />

                  <Field component={TextField}
                         name="Description"
                         label={t('pages.description')}
                         readOnly={readOnly}
                         validate={required}
                         required
                  />

                  <Field component={TextField}
                         name="Alias"
                         label={t('pages.customizeURL')}
                         readOnly={readOnly}
                         validate={validateAlphanumeric}
                         prefixText={`${window.location.origin}/userpage/`}
                  />

                  <div className="form-row">
                    <div className="col">
                      <Field component={TextField}
                             name="Email"
                             label={t('pages.email')}
                             readOnly={readOnly}
                      />
                    </div>
                    <div className="col">
                      <Field component={TextField}
                             name="Address"
                             label={t('pages.address')}
                             readOnly={readOnly}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <Field component={TextField}
                             name="Phone"
                             label={t('pages.phone')}
                             readOnly={readOnly}
                      />
                    </div>
                    <div className="col">
                      <Field component={TextField}
                             name="Website"
                             label={t('pages.website')}
                             readOnly={readOnly}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <Field component={TextField}
                             name="OpenFrom"
                             label={t('pages.openFrom')}
                             type="time"
                             readOnly={readOnly}
                      />
                    </div>
                    <div className="col">
                      <Field component={TextField}
                             name="OpenTo"
                             label={t('pages.openTo')}
                             type="time"
                             readOnly={readOnly}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <Field component={TextField}
                         name="Linkedin"
                         label={t('pages.linkedIn')}
                         readOnly={readOnly}
                      />
                    </div>
                    <div className="col">
                      <Field component={TextField}
                         name="Facebook"
                         label={t('pages.facebook')}
                         readOnly={readOnly}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <Field component={TextField}
                         name="Twitter"
                         label={t('pages.twitter')}
                         readOnly={readOnly}
                      />
                    </div>
                    <div className="col">
                      <Field component={TextField}
                         name="Instagram"
                         label={t('pages.instagram')}
                         readOnly={readOnly}
                      />
                    </div>
                  </div>

                  <Field component={TextArea}
                         name="Services"
                         label={t('pages.services')}
                  />

                  <Field component={ToggleField}
                    id="Settings.DisableUserPosting"
                    name="Settings.DisableUserPosting"
                    label={t('pages.disablePosting')}
                    text={t('pages.warningText')}
                    type="checkbox"
                  />

                  {
                    !readOnly &&
                    <div className="submit-btns text-center">
                      <LoadingButton iconCls="fa fa-save" loading={submitting} disabled={pristine} title={t('pages.save')} />
                    </div>
                  }
                </form>
            )}}
          />
      </div>
    </div>
  );
}
