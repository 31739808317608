import React from 'react';
import Editor from '../../Components/ArrayEditor';
import { useTranslation } from "react-i18next";

export default function Interests({user, update, readOnly=false}){
  const {t} = useTranslation('common');

  return (
    <Editor
      api="user.update"
      iconCls="ti-heart"
      prop="Interests"
      placeholder={t('profile.interestsExample')}
      user={user}
      update={update}
      readOnly={readOnly}
      title={t("profile.intHobbySidebar")}
    />
  );
}
