import React, { useState, Fragment } from 'react';
import LoadingButton from '../../LoadingButton';
import { useTranslation } from "react-i18next";
import { Form } from 'react-final-form';

export default function AddForm({readOnly, children, onSubmit}){
  const {t} = useTranslation('common');
  const [showFields, setShowFields] = useState(false);

  const submit = (...args) => {
    return onSubmit(...args).then(() => setShowFields(false));
  };

  return (
    <Fragment>
      {
        !readOnly && !showFields &&
        <div className="submit-btns text-center">
          <LoadingButton title={t('honoree.addMore')} onClick={() => setShowFields(true)}/>
        </div>
      }

      { 
        !readOnly && showFields && 
        <Form
          onSubmit={submit}
          render={({ handleSubmit, submitting, values, pristine}) => {
            return (
              <form onSubmit={handleSubmit}>

                {
                  React.Children.map(children, child => 
                    (React.cloneElement(child, {values, readOnly})) 
                  )
                }

                <div className="submit-btns text-center">
                  <LoadingButton title={t('honoree.add')} loading={submitting} disabled={pristine}/>
                </div>

              </form>
          )}}
        />
      }
    </Fragment>
  );
}