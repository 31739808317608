import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout';
import Posts from '../../Components/Posts';
import { useUserContext } from '../../Contexts/userContext';
import TabPanel from '../../Components/TabPanel';
import { useTranslation } from "react-i18next";
import { request, saveTrackingData } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import UsersList from '../../Components/Widgets/usersList';
import { useTour } from '@reactour/tour'

import Calendar from '../../Components/Calendar';
import MyMementos from '../PostedMementos';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Timeline() {
  const {t} = useTranslation('common');
  const { user, setUser } = useUserContext();
  const [friends, setFriends] = useState([]);
  const { handleError } = usePopupContext();
  const { setIsOpen } = useTour();

  useEffect(() => {
    if(user) {  
      saveTrackingData(user, '/timeline');
    }
  }, [])

  useEffect(() => {
    if (!user.TourCompleted) {
      setIsOpen(true);
    }
    const onTourClose = () => {
      return request('user.update', {TourCompleted: true})
      .then(r => setUser({...user, ...r}))
      .catch(handleError);
    };
    document.addEventListener('tourClose', onTourClose);
    return () => document.removeEventListener('tourClose', onTourClose);
  }, [user.TourCompleted]);

  const targetGroup = user && user.OwnGroup;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    targetGroup && request('user.topFriends', {id: targetGroup})
      .then(setFriends)
      .catch(handleError)
  }, [targetGroup]);

  const tabConfig = byUser => ([{
    text: <Translate>People Timeline</Translate>,
    iconCls: 'fa fa-user',
    content: <Posts key={0} showSecondName groupId={user.OwnGroup} byUser={byUser} />
  },{
    text: <Translate>Pets Timeline</Translate>,
    iconCls: 'fa fa-paw',
    content: <Posts key={1} showSecondName groupId={user.OwnGroup} byUser={byUser} postType="Pet" />
  }]);

  const Timelines = ({byUser}) => (
    <TabPanel tabs={tabConfig(byUser)} />
  );

  const mementosConfig = () => ([{
    text: <Translate>Posted Mementos</Translate>,
    iconCls: 'ti-files',
    content: <MyMementos key={0} />
  },{
    text: <Translate>Posted Premium Mementos</Translate>,
    iconCls: 'ti-files',
    content: <MyMementos key={1} mementoType="Premium" />
  }]);

  const Mementos = () => (
    <TabPanel tabs={mementosConfig()} />
  );

  const menuConfig = [
    {
      title: <Translate>All posts</Translate>,
      contents: Timelines,
      iconCls: 'fa fa-connectdevelop',
      path: ''
    },{
      title: <Translate>My posts</Translate>,
      contents: Timelines,
      iconCls: 'fa fa-list-alt',
      path: 'myposts',
      params: {byUser: user._id}
    },{
      title: <Translate>My mementos</Translate>,
      contents: Mementos,
      iconCls: 'ti-files',
      path: 'mymementos',
      params: {byUser: user._id}
    },{
      title: <Translate>Calendar</Translate>,
      contents: Calendar,
      iconCls: 'ti-calendar',
      path: 'calendar',
      params: {groupId: user.OwnGroup}
    }
  ];

  const extraPanelsConfig = friends ? [...(friends[0]?.length ? [{
    title: <Translate>Top friends</Translate>,
    contents: (<UsersList list={friends[0]} />)
  }]: []),
  ...(friends[1]?.length ? [{
    title: <Translate>Top groups</Translate>,
    contents: (<UsersList list={friends[1]} />)
  }]: [])] : null;

  return(
    <Layout config={menuConfig} tooltip="Here you can choose to see All Posts, My Posts, or Calendar. All Posts include all public posts across MEMKEEPERS." title={<Translate>TIMELINE</Translate>} extraPanels={extraPanelsConfig} />
  );

};
