import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Welcome from '../../Components/Welcome';
import { request } from '../../Services/networkService';
import { useHistory, Redirect } from 'react-router-dom';
import { usePopupContext } from '../../Contexts/popupContext';
import { useUserContext } from '../../Contexts/userContext';

export default function AuthGoogle() {
  const history = useHistory();
  const { handleError } = usePopupContext();
  const { user, setUser } = useUserContext();
  const params = new URLSearchParams(useLocation().search);
  const userToken = params.get('t');
  const userId = params.get('id');

  useEffect(() => {
    setUser({ _id: userId, token: userToken });
  }, [userId, userToken]);

  useEffect(() => {
    if (user._id) {
      request('user.get', {id: user._id})
      .then(data => {
        setUser({ ...data, token: user.token });
        history.push({pathname: '/timeline'});
      })
      .catch(handleError);
    }
  }, [user._id]);

  return(
    user.token ?
    <Redirect to={'/timeline'} />  :
    <Welcome>
      <div className="log-reg-area sign">
        <h2 className="log-title">Logging in with Google ...</h2>
      </div>
    </Welcome>
  );
}
