import React from 'react';
import Editor from '../../Components/ArrayEditor';
import { useTranslation } from "react-i18next";
import Gallery from '../../Components/Gallery';

export default function Interests({user, readOnly, update}){
  const {t} = useTranslation('common');
  return (
    <Editor
      api="honoree.update"
      iconCls="ti-heart"
      prop="Interests"
      placeholder={t('honoree.photography')}
      user={user}
      update={update}
      readOnly={readOnly}
    >
      <br/><br/>
      <Gallery galleryType="Interests" user={user} readOnly={readOnly} />
    </Editor>
  );
}
