import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../Contexts/userContext';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";
import { request } from '../../Services/networkService';
import LoadingButton from '../../Components/LoadingButton';
import { Form } from 'react-final-form';
import { getName } from '../../Util/common';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import { useLocation } from "react-router-dom"
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Members({user, page}){
  const history = useHistory();
  const {t} = useTranslation('common');
  const { user:currentUser, getAvatar } = useUserContext();
  const { handleError, popupMessageOpen } = usePopupContext();
  const [data, setData] = useState([]);
  const group = user ? user.OwnGroup : page?.OwnGroup;
  const alias = user ? (user.Alias || user._id) : (page?.Alias || page?._id);
  const name = user ? getName(user) : page?.Name;
  const admin = (user?.Owner || page?.Owner) === currentUser._id;
  const [ emails, setEmails ] = useState([])
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const getMembers = () => {
    group && request('honoree.members', {id: group})
      .then(arr => setData(arr.filter(o => o.User).map(o => ({
          text: getName(o.User),
          image: getAvatar(o.User.ProfileImage || null),
          link: '/usertimeline/' + o.User._id,
          status: o.Status,
          id: o.User._id,
          email: o.User.Email
      })).sort((a,b) => a.Status === "Owner" ? -1 : (b.Status === "Owner" ? 1 : 0) )))
      .catch(handleError);
  };

  useEffect(() => {
    getMembers();
    if(params && params.get("invited") && params.get("group")) {
      if(typeof group !== 'undefined') changeModStatus(params.get("invited"), false, true)
      else changeModStatus(params.get("invited"), false, true, params.get('group'))
    }
  }, [group]);

  const changeModStatus = (id, status, invited = false, groupId = 0) => {
    if(groupId === 0) {
      request('group.setUserStatus', {userId: id, groupId: group, status: status ? 'Moderator' : 'Accepted', fromInvitation: invited})
      .then(getMembers)
      .catch(handleError)
    } else {
      request('group.setUserStatus', {userId: id, groupId: groupId, status: status ? 'Moderator' : 'Accepted', fromInvitation: invited})
      .then(getMembers)
      .catch(handleError)
    }
  };

  const removeUser = (id) => {
    request('group.setUserStatus', {userId: id, groupId: group, status: 'Removed'})
    .then(() => {
      getMembers();
      popupMessageOpen('', 'The user is successfully removed');
    })
    .catch(handleError)
  };

  const blockUser = (id) => {
    request('group.setUserStatus', {userId: id, groupId: group, status: 'Blacklisted'})
    .then(() => {
      getMembers();
      popupMessageOpen('', 'The user is successfully blocked from this group!');
    })
    .catch(handleError)
  };

  const resendInvitation = (user) => {
    request('group.inviteFriend', {Email: user.email, groupId: group, alias, type: page ? 'page' : 'honoree', name})
    .then(() => {
      getMembers();
      popupMessageOpen('', 'The invitation is successfully resent!');
    })
    .catch(handleError)
  };

  const onSubmit = () => {
    if(emails && Object.keys(emails).length > 0) {
      if(Object.keys(emails).length > 1) {
        emails.forEach(async (email) => {
          await request('group.inviteFriend', {Email: email, groupId: group, alias, type: page ? 'page' : 'honoree', name})
          .then(async () => {
            getMembers();
            setEmails([]);
            await popupMessageOpen(t('groups.success'), t('groups.sendMesseg'))
          })
          .catch(handleError)
        })
      } else {
        return request('group.inviteFriend', {Email: emails[0], groupId: group, alias, type: page ? 'page' : 'honoree', name})
        .then(() => {
          getMembers();
          setEmails([]);
          popupMessageOpen(t('groups.success'), t('groups.sendMesseg'))
        })
        .catch(handleError)
      }
    }
  };

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-users" /> <Translate>Group Members</Translate></h5>

            {/* <div className="photos-container small avatars"> */}
            <div className="photos-container">
              {
                (!data || !data.length) &&
                <center>
                  <div className="photos-empty"> {t('groups.noFriendsDisplay')} </div>
                </center>
              }

              {
                data && data.map((p,i) => {
                  return (
                    p.status === "Invited" && admin ?
                    <div className="photos-holder" key={i}>
                      <div
                        className="photos-item"
                        style={{backgroundImage: `url("${p.image}")`}}
                        onClick={()=> !admin && p.link && history.push({pathname: p.link})}
                        title={p.text}
                      >
                      {
                        p.status === 'Owner' && <div className="fa fa-diamond owner-icon" />
                      }
                      </div>
                      <div className="photo-title">
                        <b>{p.text}</b> 
                        <br/> 
                        {p.status === 'Invited' && admin && <Translate>Waiting for invitation approval</Translate>}
                        {p.status === 'Owner' && <Translate>(Group Admin)</Translate>}
                        {p.status === 'Moderator' && !admin && <Translate>Moderator</Translate>}

                        {
                          p.status !== 'Owner' && admin &&
                          <div className="submit-btns text-center">
                            <LoadingButton title={<Translate>Resent Invitation</Translate>} onClick={() => resendInvitation(p)}/>
                          </div>
                        }

                      </div>
                    </div>
                    : p.status !== "Invited" ?
                    <div className="photos-holder" key={i}>
                      <div
                        className="photos-item"
                        style={{backgroundImage: `url("${p.image}")`}}
                        onClick={()=> admin ? popupMessageOpen('User Email', p.email) : p.link && history.push({pathname: p.link})}
                        title={p.text}
                      >
                      {
                        p.status === 'Owner' && <div className="fa fa-diamond owner-icon" />
                      }
                      </div>
                      <div className="photo-title">
                        <b>{p.text}</b>
                        <br/> 
                        {p.status === 'Owner' && <Translate>(Group Admin)</Translate>}
                        {p.status === 'Moderator' && !admin && <Translate>Moderator</Translate>}

                        {
                          p.status !== 'Owner' && admin &&
                          <>
                          <div
                            className="checkbox"
                            style={{margin: 0}}
                            title={t('admin.moderator')}
                          >
                            <label>
                              <input type="checkbox" checked={p.status === 'Moderator'} onChange={e => changeModStatus(p.id, e.target.checked)} />
                              <i className="check-box" />
                              <span><Translate>Moderator</Translate></span>
                            </label>
                          </div>
                          <div className="submit-btns text-center">
                            <LoadingButton title={<Translate>Block User</Translate>} onClick={() => blockUser(p.id)}/>
                            <br/>
                            <LoadingButton title={<Translate>Remove from Group</Translate>} onClick={() => removeUser(p.id)}/>
                          </div>
                          </>
                        }

                      </div>
                    </div>
                    :null
                  )
                })
              }
            </div>

            {
              admin && 
              <div className='invite-container'>
                <p>&nbsp;</p>
                <h6><Translate>Here you can send Email invitations to your friends to join this group</Translate></h6>
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, submitting, pristine, form, values}) => {
                    return (
                      <form onSubmit={e => handleSubmit(e, form)}>
                        <ReactMultiEmail
                          placeholder={<Translate>Invite one or more users by email</Translate>}
                          emails={emails}
                          onChange={(_emails) => {
                            setEmails(_emails);
                          }}
                          validateEmail={email => {
                            return isEmail(email); // return boolean
                          }}
                          getLabel={(
                            email,
                            index,
                            removeEmail,
                          ) => {
                            return (
                              <div data-tag key={index}>
                                {email}
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                  ×
                                </span>
                              </div>
                            );
                          }}
                        />
                        <br/>
                        <div className="submit-btns text-center">
                          <LoadingButton iconCls="fa fa-save" loading={submitting} disabled={emails && !emails.length} title={t('groups.sendInvitation')} />
                        </div>
                      </form>
                  )}}
                />
              </div>
            }
            
      </div>
    </div>
  );
}
