import React, { useEffect, useState, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import { Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import CheckBox from '../../Components/Form/CheckBox';
import Select from '../../Components/Form/Select';
import { required } from '../../Util/form';
import { formatDate } from '../../Util/common';
import { useTranslation } from "react-i18next";
import { usePopupContext } from '../../Contexts/popupContext';
import { request } from '../../Services/networkService';
import { useUserContext } from '../../Contexts/userContext';
import moment from 'moment';
import { recurringDailyEvents } from '../../Util/constants';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Calendar({ groupId }){
  const {t} = useTranslation('common');
  const { handleError, popupFormOpen } = usePopupContext();
  const [events, setEvents] = useState([]);
  const { user } = useUserContext();

  const getEvents = useCallback(() => 
    request('event.list', {id: groupId})
      .then(setEvents)
      .catch(handleError)
  , [groupId]);

  useEffect(()=>{
    groupId && getEvents();
  }, [groupId, getEvents]);

  const eventFormPopup = (date, readOnly) => (
    <div className="form-row">
      <Field component={TextField}
       name="title"
       label={t('admin.name')}
       validate={required}
       required
       readOnly={readOnly}
      />
      <Field component={TextField}
       name="description"
       label={t('admin.description')}
       readOnly={readOnly}
      />
      <Field component={Select}
       name="recurringOption"
       label={<Translate>Repeat this event: </Translate>}
       options={recurringDailyEvents}
       readOnly={readOnly}
      />
      <Field component={TextField}
       name="time"
       label={<Translate>Event Start Time</Translate>}
       type="time"
       readOnly={readOnly}
      />
      <Field component={TextField}
       name="enddate"
       label={<Translate>Event End Date</Translate>}
       type="date"
       readOnly={readOnly}
      />
      <Field component={TextField}
       name="endtime"
       label={<Translate>Event End Time</Translate>}
       type="time"
       readOnly={readOnly}
      />
      <Field component={CheckBox}
        name="neverExpire"  
        type="checkbox"
        label={<Translate>Never expire</Translate>}
        readOnly={readOnly}
      />
    </div>
  );

  const combineDateTime = (date, time) => {
    const timeArr = time ? time.split(':') : null;
    const dateTime = timeArr ? moment(date).set({"hour": timeArr[0], "minute": timeArr[1]}) : moment(date);
    return dateTime.toDate();
  };

  const handleDateClick = ({date}) => {
    popupFormOpen(`Create event on ${formatDate(date)}`, eventFormPopup(date, false))
      .then(data => {
        return request('event.create', {...data, 
            start: combineDateTime(date, data.time), 
            end: combineDateTime(data.enddate || date, data.endtime), 
            targetGroup: groupId}
          )
          .then(getEvents)
          .catch(handleError)
        }
      )
      .catch(() => {})
  };

  const handleEventClick = e => {
    const id = e.event._def.publicId;
    const event = events.find(o => o._id === id);
    const readOnly = (event.owner !== user._id) || event.autoGenerated;
    const editable = readOnly ? false : true;

    const initialValues = {
      ...event, 
      time: moment(event.start).format('HH:mm'),
      ...(event.end && {endtime: moment(event.end).format('HH:mm'), enddate: event.end})
    }

    popupFormOpen(`Edit event on ${formatDate(event.start)}`, eventFormPopup(event.start, readOnly), {initialValues}, null, editable)
      .then(data => {
          if (!readOnly && !data.delete) {
            return request('event.update', {...data, 
              start: combineDateTime(data.start, data.time), 
              end: combineDateTime(data.enddate || data.start, data.endtime)}
            )
            .then(getEvents)
            .catch(handleError);
          } else if (!readOnly && data.delete && data.delete === true) {
            return request('event.delete', {_id: data._id, recurId: data.recurringId ? data.recurringId : null})
            .then(getEvents)
            .catch(handleError);
          }
        }
      )
      .catch(() => {})
  };

  return (
    <div>
      <div><Translate>Calendar events are visible to all group members only</Translate></div>
      <FullCalendar
        plugins={[ dayGridPlugin, interactionPlugin ]}
        initialView="dayGridMonth"
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        events={events}
      />
    </div>
  );
}