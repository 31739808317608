import React from 'react';
import Layout from '../../Components/Layout';
import { useTranslation } from "react-i18next";

import All from './all';

export default function Requests() {
  const {t} = useTranslation('common');

  const menuConfig = [
    {
      title: t('requests.all'),
      contents: All,
      iconCls: 'fa fa-users',
      path: '',
      params: {}
    }
  ];

  return(
    <Layout config={menuConfig} title={t('requests.reqSidebar')} />
  );
};
