import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { useUserContext } from '../../Contexts/userContext';
import { usePopupContext } from '../../Contexts/popupContext';
import { languages } from '../../Util/common';

export default function Languages() {
  const { handleError } = usePopupContext();
  const { updateUser } = useUserContext();
  const {i18n} = useTranslation('common');
  const [columns, setColumns] = useState([]);

  const setLanguage = lng => updateUser({Language: lng}).catch(handleError);

  const getLanguageKey = (value) => {
    return Object.keys(languages).find(key => languages[key].title.toString().toLowerCase().includes(value.toLowerCase()));
  }

  const doSearch = (searched) => {
    let languagesCopy = languages;

    if(searched && searched.length) {
      let result = Object.values(languagesCopy).filter(item => item.title.toLowerCase().includes(searched.toLowerCase()));

      if (result && result.length) {
        let lKey = getLanguageKey(searched);
        sortLanguages({[lKey]: result[0]});
      }
      else sortLanguages({});
    } else sortLanguages({});
  }

  const searchLanguage = () => {
    return (
      <form key="searchForm" >
        <input
          key="searchInput"
          type="text" 
          placeholder="Search language"
          onChange={e => {
            doSearch(e.target.value);
          }} 
        />
        {/* <i className="fa fa-search" /> */}
      </form>
    )
  }

  const sortLanguages = (finded) => {
    let fromSearch = finded && Object.keys(finded).length ? true : false;

    if(fromSearch) {
      setColumns(Object.keys(finded).reduce((acc, v, i) => 
        i%2 === 0 ? [...acc, [v]] : [...acc.slice(0, acc.length-1), [...acc[acc.length-1], v]]
      ,[]))
    } else {
      setColumns(Object.keys(languages).reduce((acc, v, i) => 
        i%2 === 0 ? [...acc, [v]] : [...acc.slice(0, acc.length-1), [...acc[acc.length-1], v]]
      ,[]))
    }
  }

  useEffect(() => {
    sortLanguages();
  }, [])

  return (
    <Fragment>
      <Link to="/timeline" title="Click here to select language" data-ripple><img alt="Country flag" className='headerLanguage' src={languages[i18n.language]?.image} width={50} height={25}/></Link>
      <ul className="languages-dropdown">
        { searchLanguage() }
        {
          columns.map((l, idx) => (
            <li key={idx}>
              {
                columns.length % 2 === 0 ? 
                <>
                  <span title={languages[l[0]].title} onClick={() => setLanguage(l[0])}>
                    <img alt="Country flag" src={languages[l[0]].image} width={40} height={20}/> 
                    {languages[l[0]].title}
                  </span>
                  <span title={languages[l[1]].title} onClick={() => setLanguage(l[1])}>
                    <img alt="Country flag" src={languages[l[1]].image} width={40} height={20}/> 
                    {languages[l[1]].title}
                  </span>
                </>
                :
                <span title={languages[l[0]].title} onClick={() => setLanguage(l[0])}>
                  <img alt="Country flag" src={languages[l[0]].image} width={40} height={20}/> 
                  {languages[l[0]].title}
                </span>
              }
            </li>
          ))
        }
      </ul>
    </Fragment>
  )
};