import React, { useState, useRef } from 'react';
import { usePopupContext } from '../../Contexts/popupContext';
import { useUserContext } from '../../Contexts/userContext';
import { request } from '../../Services/networkService';
import LoadingButton from '../../Components/LoadingButton';
import { useTranslation } from "react-i18next";
import { getName, loadImagePreview } from '../../Util/common';
import PostPreview from './postPreview';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function NewPost({postData, apiId, refresh, refreshMemobjects, page, postType, defaultAd}){
  const { handleError, popupMessageOpen, popupPaymentOpen, popupConfirmOpen} = usePopupContext();
  const { user, targetUser, getAvatar } = useUserContext();
  const defaultPublic = user.AccountSetting.DefaultPostPublicState ? true : false;
  const [postText, setPostText] = useState(postData ? postData.text : '');
  const [loading, setLoading] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [progress, setProgress] = useState(0);
  const [postPublic, setPostPublic] = useState(defaultPublic);
  const [postAd, setPostAd] = useState(false);
  const [extraLink, setExtraLink] = useState('');
  const fileFld = useRef();

  const isBusinessPage = page && page.isBusinessPage && (user._id === page.Owner || page.groupRelation === 'Moderator');
  const {t} = useTranslation('common');
  const honoreePost = targetUser && apiId && targetUser.OwnGroup === apiId && targetUser._type === "honoree";
  const [postAsMemobject, setPostAsMemobject] = useState(false);

  const removeAttachment = () => {
    setUploads([]);
    fileFld.current && (fileFld.current.value = '');
  };

  const doPost = (paymentData) => {
    setLoading(true);
    let data = new FormData();
    data.append('text', postText);
    data.append('id', apiId);
    postType && data.append('postType', postType);
    data.append('public', isBusinessPage ? true : postPublic);
    (defaultAd || isBusinessPage) && data.append('businessPost', true);
    (defaultAd || isBusinessPage) && data.append('ad', defaultAd ? true : postAd);
    postData && postData._id && data.append('_id', postData._id);
    uploads.length && data.append('file', uploads[0]);
    extraLink && data.append('link', extraLink);

    postAsMemobject && data.append('postAsMemobject', true);

    paymentData && data.append('autoRenew', paymentData.autoRenew);
    paymentData && data.append('paymentIntentId', paymentData.paymentIntentId);
    paymentData && data.append('productId', paymentData.productId);
    paymentData && paymentData.token && data.append('token', paymentData.token);
    paymentData && paymentData.paypalId && data.append('paypalId', paymentData.paypalId);

    // ad tracking body
    const adTrackingBody = {
      ad: '',
      times: {
        sidebar: 0,
        timeline: 0
      },
      clicks: {
          sidebar: [],
          timeline: []
      }
    }

    return request('group.post.create', data, setProgress)
      .then(res => {
        adTrackingBody.ad = res._id;
        request('ad.tracking.create', adTrackingBody)
        .then(() => {
          setPostText('');
          setExtraLink('');
          refresh && refresh();
          refreshMemobjects && refreshMemobjects();
        })
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
        setProgress(0);
        setPostPublic(defaultPublic);
        removeAttachment();
      });
  };

  const addPost = e => {
    e.preventDefault();
    if (postAd || defaultAd) {
      return loadImagePreview(uploads[0])
        .then(imgSrc => popupConfirmOpen(<Translate>Preview</Translate>, null, null, <PostPreview imgSrc={imgSrc} postText={postText} postType={postType} imgType={uploads.length ? uploads[0].type : null} />))
        .then(() => popupPaymentOpen({itemName: ['Timeline Ad', 'Sidebar Ad'], showMaintanence: true}))
        .then(doPost)
        .catch(() => {});
    } else if (postAsMemobject) {
      return popupPaymentOpen({itemName: 'Premium Memento', showMaintanence: true})
        .then(doPost)
    }
    return doPost();
  };

  const onFileSelect = e => {
    const maxSize = 51200000;
    if (e.target.files && e.target.files.length) {
      if(e.target.files[0] !== null && e.target.files[0].size < maxSize){
        setUploads([e.target.files[0]]);
      } else {
        popupMessageOpen(t('newpost.error'), t('newpost.too-big'));
      }
    }
  };

  const modules = {
    toolbar: [
      [{ 'font': [] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

      [{ 'align': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ],
  }

  return (
    <div className="central-meta item">
      <div className="new-postbox">
        <figure>
          <div className="usericon user-avatar-image" style={{backgroundImage: `url("${getAvatar()}")`}} />
        </figure>
        <div className="newpst-input">
          <form onSubmit={addPost}>

            <div>
              {getName(user)}

              {honoreePost && <div
                className="checkbox pull-right"
                style={{margin: "0 0 0 20px"}}
                title="Post as Premium Memento"
              >
                <label>
                  <input type="checkbox" checked={postAsMemobject} onChange={e => setPostAsMemobject(e.target.checked)} />
                  <i className="check-box" />
                  <span>Post as Premium Memento</span>
                </label>
              </div>}

              {!isBusinessPage && !defaultAd && <div
                className="checkbox pull-right"
                style={{margin: 0}}
                title={t('newpost.all-visible')}
              >
                <label>
                  <input type="checkbox" checked={postPublic} onChange={e => setPostPublic(e.target.checked)} />
                  <i className="check-box" />
                  <span><Translate>Public</Translate></span>
                </label>
              </div>}

              {isBusinessPage && !defaultAd && <div
                className="checkbox pull-right"
                style={{margin: 0}}
                title={t('newpost.ad-post')}
              >
                <label>
                  <input type="checkbox" checked={postAd} onChange={e => setPostAd(e.target.checked)} />
                  <i className="check-box" />
                  <span>{t('newpost.advert')}</span>
                </label>
              </div>}

            </div>

            {
              (postAd || defaultAd) ?
              <ReactQuill 
                theme="snow"
                required={!uploads.length}
                placeholder="Here is a great place to showcase and advertise your business or personal page. Timeline Ads cost $25/mo and will display randomly with other timeline Ads as every fifth post. Sidebar ads cost $45/mo and display randomly with other sidebar ads. You can also select to auto-renew your Ad and then it will continue to run until cancelled. To post an Ad use the Advertise menu on your business/personal page. For best results, use only text and/or .jpg files on sidebar Ads."
                value={postText}
                onChange={e=> setPostText(e)}
                disabled={loading}
                modules={modules}
              />
              :
              <textarea
                rows={2}
                required={!uploads.length}
                placeholder={t('newpost.on-your-mind') === 'newpost.on-your-mind' ? "What's On Your Mind?" : t('newpost.on-your-mind')}
                value={postText}
                onChange={e=>setPostText(e.target.value)}
                disabled={loading}
              />
            }
            {/* <textarea
              rows={2}
              required={!uploads.length}
              placeholder={defaultAd || postAd ? 'Here is a great place to showcase and advertise your business or personal page. Timeline Ads cost $25/mo and will display randomly with other timeline Ads as every fifth post. Sidebar ads cost $45/mo and display randomly with other sidebar ads. You can also select to auto-renew your Ad and then it will continue to run until cancelled. To post an Ad use the Advertise menu on your business/personal page. For best results, use only text and/or .jpg files on sidebar Ads.' : t('newpost.on-your-mind')}
              value={postText}
              onChange={e=>setPostText(e.target.value)}
              disabled={loading}
            /> */}

            <div className={(postAd || defaultAd) ? "attachments ad" : "attachments"}>
              <ul>
              {
                !!uploads.length &&
                <li className="selected-file">
                  <div>{uploads[0].name}</div>
                  <div className="remove-selected" title="Remove attachment" onClick={removeAttachment}>
                    <i className="fa fa-times" />
                  </div>
                  <div className="progress" style={{width: `${progress}%`}}/>
                </li>
              }
                
                <li title={t('newpost.file-attach')}>
                  <i className="ti-clip" />
                  <label className="fileContainer">
                    <input ref={fileFld} type="file" accept="image/*,audio/*,video/*,.pdf" onChange={onFileSelect}/>
                  </label>
                </li>
                <li>
                  <LoadingButton noClass noTitle iconCls="fa fa-paper-plane" className="post-button" title={t('newpost.publish')} loading={loading} />
                </li>
              </ul>
            </div>

            {
              (postAd || defaultAd) &&
              <div className="new-post-link">
                <span><Translate>Advertisement link: </Translate></span>
                <input value={extraLink} onChange={e => setExtraLink(e.target.value)} />
              </div>
            }
            
          </form>
      </div>
      </div>
    </div>
  );
}
