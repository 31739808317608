import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRequest, request } from '../../Services/networkService';
import Loading from '../../Components/Loading';
import LoadingButton from '../../Components/LoadingButton';
import { useUserContext } from '../../Contexts/userContext';
import { useTranslation } from "react-i18next";
import { usePopupContext } from '../../Contexts/popupContext';

export default function AllGroups(){
  const history = useHistory();
  const {data, isLoading, handleRequest} = useRequest();
  const { getAvatar } = useUserContext();
  const {t} = useTranslation('common');
  const { popupConfirmOpen, handleError } = usePopupContext();

  const refreshPages = () => handleRequest('page.list');

  useEffect(() => {
    refreshPages();
  }, []);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title">
          <i className="fa fa-users" />{t("pages.myPagesHead")}
          <div className="submit-btns text-center">
            <LoadingButton link="/pages/info" title={t('pages.createPageButton')} />
          </div>
        </h5>
        
        {
            data && !!data.length &&
            <table className="users-table">
              <thead>
                <tr>
                  <td></td>
                  <td>{t('pages.pageName')}</td>
                  <td>{t('pages.type')}</td>
                  <td>{t('pages.description')}</td>
                  <td>{t('pages.actions')}</td>
                </tr>
              </thead>
              <tbody>
                {data.map((g,index)=>(
                  <tr key={index} onClick={() => history.push({pathname: '/userpage/' + (g.Alias ? g.Alias : g._id)})}>
                    <td>
                      <div className="results-avatar" style={{backgroundImage: `url('${getAvatar(g.ProfileImage || null, true)}')`}} />
                    </td>
                    <td>
                      {g.Name}
                    </td>
                    <td>
                      {g.isBusinessPage ? 'Business' : 'Personal'}
                    </td>
                    <td>
                      {g.Description}
                    </td>
                    <td>
                      <div style={{width: '70px'}}>
                        <LoadingButton
                          title={t('pages.edit')}
                          className="user-list-icon"
                          noClass
                          noTitle
                          iconCls="fa fa-edit"
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push({pathname: '/pages/info/' + g._id});
                          }}
                        />
                        <LoadingButton
                          title="Delete Page"
                          className="user-list-icon"
                          noClass
                          noTitle
                          iconCls="fa fa-trash"
                          onClick={(e) => {
                            e.stopPropagation();
                            popupConfirmOpen('Confirm', 'Are you sure you want to delete this Page?')
                              .then(() => {
                                request('page.delete', {id: g._id})
                                  .then(() => refreshPages())
                                  .catch(handleError)
                              })
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }

          <Loading data={data} loading={isLoading} message={t('pages.noCreateMesseg')} />

      </div>
    </div>
  );
}
