import React from 'react';
import Layout from '../../Components/Layout';
import { useTranslation } from "react-i18next";

import All from './all';
import Create from './create';

export default function Requests() {
  const {t} = useTranslation('common');
  const menuConfig = [
    {
      title: t('pages.myPagesLink'),
      contents: All,
      iconCls: 'fa fa-users',
      path: '',
      params: {}
    },{
      title: t('pages.createPageLink'),
      contents: Create,
      iconCls: 'fa fa-users',
      path: 'info',
      params: {}
    }
  ];

  return(
    <Layout config={menuConfig} title={t('pages.pagesHead')} />
  );
};
