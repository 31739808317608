import React from 'react';
import Skeleton from 'react-loading-skeleton'

const SkeletonPost = () => {

    return (
        <div className='post skeleton'>
            <div className='skeletonHead'>
                <Skeleton circle className='profileImg'/>
            </div>
            <br/>
            <div>
            <Skeleton count={0.5} />
            <Skeleton count={0.5} />
            </div>
            <br/>
            <div>
                <Skeleton count={5.5} />
            </div>
        </div>
    );
}

export default SkeletonPost;
