import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';

export default withRouter(function FeedLeft({ match, history, location: { pathname }, config, tooltip, title, extraPanels }) {
  
  const routePathArr = match.path.split('/');
  const currentPath = pathname
    .split('/')
    .reduce((acc, curr, idx) => 
      (curr === routePathArr[idx] || (routePathArr[idx] && routePathArr[idx][0] === ':')) ? 
        acc : [...acc, curr], [])
    .join('/')
    .replace(/(^\/)|(\/$)/g, "");
  const activeIdx = config.findIndex(o => o.path === currentPath);
  const active = activeIdx > -1 ? activeIdx : 0;

  const changeTab = (index, obj) => {
    const path = pathname
      .split('/')
      .reduce((acc, curr, idx) => 
        (curr === routePathArr[idx] || (routePathArr[idx] && routePathArr[idx][0] === ':')) ? 
          [...acc, curr] : acc, [])
      .join('/');
    const newPath = `${path}/${obj.path}`.replace(/(\/$)/g, "");
    history.push(newPath);
  };

  return (
    <Fragment>
      
      <aside className="sidebar static">
        <div className="widget stick-widget">
          <h4 className="widget-title" title={tooltip || title}>{title}</h4>
          <ul className="naves">
            {
              config && config.length && config.map((item, index) => (
                <li key={index} className={index === active ? "active" : ''} onClick={() => changeTab(index, item)}>
                  <i className={item.iconCls} />
                  <span>{item.title}</span>
                </li>
              ))
            }
          </ul>
        </div>                
      </aside>

      {
        extraPanels && !!extraPanels.length && extraPanels.map((panel, idx) => (
          <aside className="sidebar static" key={idx}>
            <div className="widget stick-widget">
              <h4 className="widget-title" title={panel.tooltip || panel.title}>{panel.title}</h4>
              <ul className="naves">
                {panel.contents}
              </ul>
            </div>                
          </aside>
        ))
      }

    </Fragment>
  );
});