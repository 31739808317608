import React, { Fragment } from 'react';
import Footer from '../../Components/Footer';
import TopBar from '../../Components/TopBar';
import Cards from './cards';

const Blog = () => {
    return (
        <Fragment>
        <TopBar />
            <div className="theme-layout marble">
                <div className="welcome container">
                    <div className='row'>
                        <div className='features col-lg-12'>
                            <Cards />
                        </div>
                    </div>
                </div>
            </div>
        <Footer />
        </Fragment>
    );
}

export default Blog;
