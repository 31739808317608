import React from 'react';
import { Translate } from 'react-auto-translate/lib/commonjs';
import {useTranslation} from "react-i18next";


const BulletPhrases = ({ mobileView = false }) => {
    const {t} = useTranslation('common');

    return (
        <div className={mobileView ? 'bulletPhrases-cont mobile' : 'bulletPhrases-cont'}>
        {
            !mobileView ?
            <div>
                <h3 style={{ textAlign: 'center' }}><b>{t('welcome.summary')}</b></h3>
                <br/>
                <ul>
                    <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Share your stories, post photos, videos, drawings, and more about your loved ones.</Translate></h2></li>
                    <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Preserve memories, share a poem, a story, or anything that is on your heart.</Translate></h2></li>
                    <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Unite in remembrance, place memory tokens on their virtual wall - like flowers, candles, or anything that helps you show your respect and love.</Translate></h2></li>
                    <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Create pages</Translate></h2></li>
                    <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Join a compassionate community</Translate></h2></li>
                    <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Save important dates, get notifications about family & friends’ gatherings and memorial events.</Translate></h2></li>
                    <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Testimonials</Translate></h2></li>
                    <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Recent online memorials</Translate></h2></li>
                    <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Create an account section</Translate></h2></li>
                    <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>FAQ</Translate></h2></li>
                </ul>
            </div>
            :
            <div>
                <div>
                    {/* <h3 style={{ textAlign: 'center' }}><b>{t('welcome.summary')}</b></h3>
                    <br/> */}
                    <ul>
                        <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Share your stories, post photos, videos, drawings, and more about your loved ones.</Translate></h2></li>
                        <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Preserve memories, share a poem, a story, or anything that is on your heart.</Translate></h2></li>
                        <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Unite in remembrance, place memory tokens on their virtual wall - like flowers, candles, or anything that helps you show your respect and love.</Translate></h2></li>
                        <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Create pages</Translate></h2></li>
                        <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Join a compassionate community</Translate></h2></li>
                        <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Save important dates, get notifications about family & friends’ gatherings and memorial events.</Translate></h2></li>
                        <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Testimonials</Translate></h2></li>
                        <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Recent online memorials</Translate></h2></li>
                        <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>Create an account section</Translate></h2></li>
                        <li><h2><i className='ti-check-box'></i>&nbsp;<Translate>FAQ</Translate></h2></li>
                    </ul>
                </div>
            </div>
        }
        </div>
    );
}

export default BulletPhrases;
