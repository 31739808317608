import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout';
// import { useTranslation } from "react-i18next";
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Helmet } from 'react-helmet';

export default function AboutUs() {
  // const {t} = useTranslation('quicklinks');
  const [docData, setDoc] = useState('');
  const { handleError, popupMessageOpen } = usePopupContext();

  const getDocument = () => {
    request('public.listAboutUs')
    .then(res => {
      if(Object.keys(res).length) {
        setDoc(res[0].text)
      } else {
        popupMessageOpen('Message', 'Missing Document!');
      }
    })
    .catch(err => {
      handleError(err)
    })
  }

  const modules = {
    toolbar: false
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getDocument();
  }, []);

  return(
    <Layout showHeader={false} infoPage>
      <Helmet>
          <meta name="description" content='Memkeepers is a virtual memorials website that helps you celebrate the life of your loved ones by preserving their memories, sharing their stories, and find solace.' />
          <title>About us | Memkeepers</title>
      </Helmet>
      <div className="container documents">
        <div className="contct-info policies">

            {/* <h1 align="center"><b>{t('aboutUs.title')}</b></h1>

            <div style={{textAlign: 'right'}}><i>{t('aboutUs.lastRevisit')}</i></div>

            <p>&nbsp;</p>
              {t('aboutUs.firstP')}
            <p>
            </p>

            <p>{t('aboutUs.secondP')}
            </p>

            <p>{t('aboutUs.office')}</p>

            <p>
              {t('aboutUs.contact')} <br/>
              {t('aboutUs.email')} <a href="mailto:info@memkeepers.me" target="new">info@memkeepers.me</a> <br/>
              {t('aboutUs.helpImprove')}
            </p> */}
            {
              docData && 
              <ReactQuill 
                theme="snow"
                value={docData}
                readOnly
                modules={modules}
              />
            }
        </div>
      </div>
    </Layout>
  );
}