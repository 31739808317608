import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../Components/Layout';
import { useUserContext } from '../../Contexts/userContext';
import { request } from '../../Services/networkService';
import { useTranslation } from "react-i18next";

import PersonalInfo from '../Profile/BasicInfo';
import Education from '../Profile/Education';
import Interests from '../Profile/Interests';
import Membership from '../Profile/Membership';

export default function About() {
  const {t} = useTranslation('common');
  const { id } = useParams();
  const { targetUser, setTargetUser } = useUserContext();
  const [refreshPosts, setRefreshPosts] = useState();

  const refreshUser = () => id && request('user.get', {id: id}).then(setTargetUser);

  useEffect(()=>{
    refreshUser();
  }, [id]);

  const pageParams = {
    setRefreshPosts: setRefreshPosts,
    refreshUser,
    user: targetUser,
    readOnly: true
  };

  const menuConfig = [
    {
      title: t('userProfile.basicInfo'),
      contents: PersonalInfo,
      iconCls: 'ti-info-alt',
      path: '',
      params: pageParams
    },{
      title: t('userProfile.eduWork'),
      contents: Education,
      iconCls: 'ti-mouse-alt',
      path: 'education',
      params: pageParams
    },{
      title: t('userProfile.hobbies'),
      contents: Interests,
      iconCls: 'ti-heart',
      path: 'interests',
      params: pageParams
    },{
      title: t('userProfile.membership'),
      contents: Membership,
      iconCls: 'ti-package',
      path: 'membership',
      params: pageParams
    }
  ];

  return(
    <Layout asUser={targetUser} refreshPosts={refreshPosts} refresh={refreshUser} config={menuConfig} title={t('userProfile.userInfo')} />
  );
};
