import React from 'react';
import Layout from '../../Components/Layout';
import { useUserContext } from '../../Contexts/userContext';

import Promotions from './promotions';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function PromotionsMenu() {
  const { user, setUser } = useUserContext();

  const pageParams = {
    user: user,
    readOnly: false,
    update: setUser
  };

  const menuConfig = [
    {
      title: <Translate>Promotions</Translate>,
      contents: Promotions,
      iconCls: 'ti-ticket',
      path: '',
      params: {pageParams}
    }
  ];

  return(
    <Layout config={menuConfig} title={<Translate>Promotions</Translate>} />
  );
};
