import React from 'react';
import { Form, Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import { required } from '../../Util/form';
import LoadingButton from '../../Components/LoadingButton';
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import { useUserContext } from '../../Contexts/userContext';
import { useTranslation } from "react-i18next";
import { getName } from '../../Util/common';

export default function Invite(){
  const { popupMessageOpen, handleError } = usePopupContext();
  const {t} = useTranslation('common');
  const { user } = useUserContext();

  // const onSubmit = data => {
  //   return request('user.inviteFriend', data)
  //     .then(() => popupMessageOpen(t('groups.success'), t('groups.sendMesseg')))
  //     .catch(handleError)
  // };

  const onSubmit = data => {
    return request('group.inviteFriend', {...data, groupId: user.OwnGroup, alias: user._id, type: 'user', name: getName(user)})
      .then(() => popupMessageOpen(t('groups.success'), t('groups.sendMesseg')))
      .catch(handleError)
  };

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="fa fa-users" /> {t('groups.inviteFriends')}</h5>
        <h6>{t('groups.sendEmailMesseg')}</h6>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, pristine, form, values}) => {
            return (
              <form onSubmit={e => handleSubmit(e, form)}>
                    <Field component={TextField}
                           name="Email"
                           label={t('groups.email')}
                           type="email"
                           validate={required}
                           required
                    />
                <div className="submit-btns text-center">
                  <LoadingButton iconCls="fa fa-save" loading={submitting} disabled={pristine} title={t('groups.sendInvitation')} />
                </div>
              </form>
          )}}
        />
      </div>
    </div>
  );
}