import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { usePopupContext } from '../../Contexts/popupContext';
import { Form, Field } from 'react-final-form';
import Select from '../../Components/Form/Select';
import { memobjectCategories } from '../../Util/constants';
import { request } from '../../Services/networkService';
import { getImageUrl } from '../../Util/common';
import { useTranslation } from "react-i18next";
import TabPanel from '../../Components/TabPanel';

export default function MoSelect() {
  const { popupSubmit, popupClose } = usePopupContext();
  const [mo, setMo] = useState([]);
  const [selectedMo, setSelectedMo] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(memobjectCategories[0].id);
  const [isChoosePlan, setChoosePlan] = useState(false);
  const {t} = useTranslation('common');

  const loadMemobjects = (id) => {
    id != null && request('memobjects.find', {f_Category: id}).then(({data}) => setMo(data)) // Load all momentos
  };

  useEffect(() => {
    loadMemobjects(selectedCategory);
  }, [selectedCategory]);

  const isAlreadyChecked = (id) => {
    if(selectedMo !== null && selectedMo.length) {
      const found = selectedMo.some(item => item._id === id);
      return found;
    }
    return false;
  }

  const onDeselectMomento = (id) => {
    let selectedMoCP = selectedMo;
    const result = selectedMoCP.filter(item => item._id !== id);
    setSelectedMo(result);
  }

  const contents = (
    <Modal.Body className="text-center">
      <Form
        onSubmit={()=>{}}
        render={({ handleSubmit, submitting, values}) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field component={Select}
                     name="moId"
                     label={t('memobjectselect.category')}
                     options={memobjectCategories}
                     noEmptyOption
                     onChange={e => {
                      setSelectedCategory(e.target.value);
                      setSelectedMo([]);
                     }}
              />
            </form>
        )}}
      />

      <div className="mo-container">
        {
          mo.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0))
          .map((o, i) => {
          const imageUrl = getImageUrl(o.Url);
          return (
            <div key={i} className="mo-item-holder">
              <div
                className={`mo-item ${selectedMo && isAlreadyChecked(o._id) ? 'mo-item-selected' : ''}`}
                style={{backgroundImage: `url("${imageUrl}")`}}
                id={o._id}
                onClick={(e) => {
                  if(selectedMo && selectedMo.length <= 4) {
                    if(!isAlreadyChecked(e.currentTarget.id)) {
                      setSelectedMo(current => [...current, o]);
                    } else onDeselectMomento(e.currentTarget.id);
                  } else onDeselectMomento(e.currentTarget.id);
                }}
              />
            </div>
          )
        })}
      </div>
    </Modal.Body>
  );

  const choosePlan = () => {
    setChoosePlan(true);
  }

  const tabConfig = () => ([{
    text: 'Free',
  },{
    text: 'Premium',
  }]);

  return (
    !isChoosePlan ?
    <Fragment>
      { contents }
      <Modal.Footer>
        <Button disabled={!selectedMo} onClick={() => choosePlan({...selectedMo})}>{t('memobjectselect.btn-ok')}</Button>
        <Button onClick={() => popupClose()}>{t('memobjectselect.btn-cancel')}</Button>
      </Modal.Footer>
    </Fragment>
    :
    <Fragment>

      <TabPanel tabs={tabConfig()} onTabChange={setSelectedTab} saveTab={false} hideNoContent={true}/>
      <Modal.Footer>
        <div className="info-box">
          <ul>
            <li>{t('memobjectselect.expire')}</li>
            <li>{t('memobjectselect.purchasePremium')}</li>
          </ul>
        </div>
        <Button disabled={!selectedMo} onClick={() => popupSubmit({...selectedMo, premium: selectedTab === 1})}>{t('memobjectselect.btn-ok')}</Button>
        <Button onClick={() => popupClose()}>{t('memobjectselect.btn-cancel')}</Button>
      </Modal.Footer>
    </Fragment>
  )
};
