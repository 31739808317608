import React from 'react';
import { Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import CheckBox from '../../Components/Form/CheckBox';
import TextArea from '../../Components/Form/TextArea';
import { required } from '../../Util/form';
import { usePopupContext } from '../../Contexts/popupContext';
import moment from 'moment';
import { request } from '../../Services/networkService';
import { useUserContext } from '../../Contexts/userContext';
import csc from 'country-state-city';
import { useTranslation } from "react-i18next";
import AddForm from '../../Components/Form/AddForm';
import CityStateCountry from '../../Components/Form/CityStateCountry';

const FromTo = ({values, readOnly}) => {
  const {t} = useTranslation('common');
  return (
    <div className="form-row">
      <div className="col">
        <Field component={TextField}
               name="From"
               label={t('profile.from')}
               type="date"
               validate={required}
               required
        />
      </div>
      <div className="col">
        <Field component={TextField}
               name="To"
               label={t('profile.to')}
               type="date"
               validate={!values.StillStudying && required}
               required={!values.StillStudying}
               disabled={values.StillStudying}
        />
      </div>
      <div className="col">
        <Field component={CheckBox}
               name="StillStudying"
               label={t('profile.stillStudyWork')}
               type="checkbox"
        />
      </div>
    </div>
  )
}

export default function Education({user, update, readOnly=false}){
  const { handleError } = usePopupContext();
  const { user:currentUser } = useUserContext();
  const {t} = useTranslation('common');

  const onSubmit = (data, form) => {
    return request('user.update', {Educations: [...user.Educations, data]})
      .then(r => {
        const usr = {...user, ...r};
        update(usr);
      })
      .catch(handleError);
  };

  const remove = index => {
    return request('user.update', {Educations: [...user.Educations.slice(0, index), ...user.Educations.slice(index+1)]})
      .then(r => {
        const usr = {...user, ...r};
        update(usr);
      })
      .catch(handleError);
  };

  return (
    <div className="central-meta">
      <div className="editing-interest">
        <h5 className="f-title"><i className="ti-info-alt" /> {t('profile.eduAndWork')}</h5>

          <ol>
            {
              user && user.Educations.map((obj, index) => (
                <li key={index}>
                  <div>
                    {moment(obj.From).format('MMMM YYYY')} - { obj.StillStudying ? t('profile.now') : moment(obj.To).format('MMMM YYYY')} <br/>
                    {obj.CountryId && csc.getCountryById(obj.CountryId.toString()).name}, {obj.StateId && `${csc.getStateById(obj.StateId.toString()).name}, `} {obj.City} <br/>
                    {obj.SchoolName}: {obj.Course} <br/>
                    {obj.About}
                    <span className="remove" title={t('profile.remove')} onClick={() => remove(index)}>
                      <i className="fa fa-close" />
                    </span>
                  </div>
                </li>
              ))
            }
          </ol>

          {
            (!user || !user.Educations.length) && <center>{user && currentUser._id === user._id ? t('profile.you') : t('profile.user')} {t('profile.noExp')}</center>
          }

          <AddForm readOnly={readOnly} onSubmit={onSubmit}>
            <div className="form-row">
              <div className="col">
                <Field component={TextField}
                       name="SchoolName"
                       label={t('profile.schoolEmpName')}
                       validate={required}
                       required
                />
              </div>
              <div className="col">
                <Field component={TextField}
                       name="Course"
                       label={t('profile.coursePosName')}
                       validate={required}
                       required
                />
              </div>
            </div>

            <FromTo />
            <CityStateCountry />

            <Field component={TextArea}
                   name="About"
                   label={t('profile.about')}
            />
          </AddForm>


      </div>
    </div>
  );
}
